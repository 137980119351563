import i18n from '../../../../../infrastructure/i18n';

export const ReviewType = {
  course: 'course',
  teacher: 'teacher',
};

export const ReviewTitle = {
  [ReviewType.course]: {
    subtitle: i18n.t('course_review_subtitle'),
    title: i18n.t('course_review_title'),
  },
  [ReviewType.teacher]: {
    subtitle: i18n.t('teacher_review_subtitle'),
    title: i18n.t('teacher_review_title'),
  },
};
