import gql from 'graphql-tag';

import { courseFragment } from './fragments';

export const createCourseReviewMutation = gql`
  mutation createCourseReview($input: CreateCourseReviewGqlInput!) {
    createCourseReview(input: $input) {
      review {
        id
        course {
          id
          slug
          rating
          reviews {
            content
            id
            rate
            createdAt
            author {
              id
              avatarUrl
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const createCourseMutation = gql`
  mutation createCourse($input: CreateCourseGqlInput!) {
    createCourse(input: $input) {
      course {
        ...course
      }
    }
  }
  ${courseFragment}
`;

export const enrollToCourseMutation = gql`
  mutation processEnrollment($input: GqlProcessEnrollmentInput!) {
    processEnrollment(input: $input) {
      processed
      clientSecret
      paymentId
    }
  }
`;

export const updateCourseMutation = gql`
  mutation updateCourse($input: UpdateCourseGqlInput!) {
    updateCourse(input: $input) {
      course {
        slug
        id
      }
    }
  }
`;
