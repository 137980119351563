import React, { FC } from 'react';

import { formatPrice } from '../../utils/format-price';
import styles from './index.module.less';

type Props = {
  currency: string;
  price: number;
  regularCoins?: boolean;
};

export const PriceFormat: FC<Props> = ({
  price,
  regularCoins = false,
  currency,
}) => {
  const { body, fraction } = formatPrice({ currency, price });

  return (
    <span className={styles['price-format']}>
      {body}
      {regularCoins ? (
        fraction
      ) : (
        <sup className={styles['price-format-coins']}>{fraction}</sup>
      )}
    </span>
  );
};
