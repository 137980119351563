import gql from 'graphql-tag';

export const userFragment = gql`
  fragment userFragment on GqlUser {
    avatarUrl
    email
    firstName
    id
    lastName
  }
`;

export const paymentInfoFragment = gql`
  fragment paymentsInfo on GqlUser {
    paymentsInfo {
      id
      paymentMethods {
        id
        brand
        last4
      }
    }
  }
`;

export const subscriptionFragment = gql`
  fragment subscription on GqlUser {
    subscription {
      id
      createdAt
      currency
      startDate
      amount
      currentPeriodStart
      currentPeriodEnd
      cancelledAt
      interval
      intervalCount
      status
      cancelAtPeriodEnd
    }
  }
`;

export const instructorFragment = gql`
  fragment instructorFragment on GqlInstructor {
    id
    approved
    countryOfLivingCode
    hasOnlineTeachingExperience
    hasTeachingExperience
    hearAboutClassroolFrom
    legalName
    levelOfEducation
    publicInformation
    socialAccount
    teachingAvailability
    teachingPreferencesDescription
  }
`;

export const publicUserFragment = gql`
  fragment publicUserFragment on PublicUserGql {
    avatarUrl
    firstName
    id
    lastName
  }
`;
