import { useState } from 'react';

export const useFocusedField = (initialFocusedFieldName = '') => {
  const [focusedField, setFocusedField] = useState(initialFocusedFieldName);
  const isFocusedField = (fieldName: string) =>
    !!fieldName && fieldName === focusedField;
  const onBlurField = (nextFocusedFieldName = '') => () => {
    setFocusedField(nextFocusedFieldName);
  };
  const onFocusField = (fieldName: string) => () => {
    setFocusedField(fieldName);
  };

  return {
    focusedField,
    isFocusedField,
    onBlurField,
    onFocusField,
    setFocusedField,
  };
};
