import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUser } from '../../modules/users/actions';
import { useSignOut } from '../../modules/users/hooks';
import { meQuery } from '../../modules/users/queries';
import { getUserToken } from '../../modules/users/selectors';
import { MeQuery, MeQueryVariables } from '../types/generated';

export const PrefetchUser = () => {
  const token = useSelector(getUserToken);
  const dispatch = useDispatch();
  const { processSignOut } = useSignOut();

  const [getMe, { data, error }] = useLazyQuery<MeQuery, MeQueryVariables>(
    meQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (token) {
      getMe();
    }
  }, [token]);

  useEffect(() => {
    if (data?.me.id && token) {
      dispatch(setUser({ id: data.me.id, token }));
    }
  }, [data?.me.id]);

  useEffect(() => {
    if (error) {
      processSignOut();
    }
  }, [error]);

  return null;
};
