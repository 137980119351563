import {
  AppstoreAddOutlined,
  BellOutlined,
  BookOutlined,
  CaretDownOutlined,
  CarryOutOutlined,
  DollarOutlined,
  HeartOutlined,
  MessageOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Badge, Button, Col, Divider, Dropdown, Menu, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import classnames from 'classnames';
import classNames from 'classnames';
import React, { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { useIsAuthorized, useSignOut } from '../../../modules/users/hooks';
import { headerInfoQuery } from '../../../modules/users/queries';
import ToggleMobile from '../../assets/icons/open-mobile.svg';
import {
  HeaderInfoQuery,
  HeaderInfoQueryVariables,
} from '../../types/generated';
import { getTranslator } from '../../utils/common';
import { Logo } from '../logo';
import { Text, Title } from '../typography';
import HeaderClasses from './index.module.less';

type MenuItemType = {
  divider?: boolean;
  icon: ReactNode;
  link: string;
  mb?: boolean;
  mt?: boolean;
  title: string;
};

type NavLinkType = {
  exact?: boolean;
  icon?: ReactNode;
  mobile?: boolean;
  title: string;
  to: string;
};

const HeaderLink: FC<NavLinkType> = ({ exact, icon, title, to, mobile }) => (
  <NavLink
    activeClassName={HeaderClasses['nav-link--active']}
    className={classNames(HeaderClasses['nav-link'], {
      'mr-32': !mobile,
      [HeaderClasses['nav-link-mobile']]: mobile,
    })}
    to={to}
    exact={exact}
  >
    <Title.SM className="inline-block">
      {icon && <span className="mr-8">{icon}</span>}
      {title}
    </Title.SM>
  </NavLink>
);

const MobileHeader: FC<{ onToggle: () => void; show: boolean }> = ({
  show,
  onToggle,
}) => {
  const t = getTranslator();
  const { isAuthorized } = useIsAuthorized();

  const { data: userData } = useQuery<
    HeaderInfoQuery,
    HeaderInfoQueryVariables
  >(headerInfoQuery, {
    fetchPolicy: 'cache-first',
    skip: !isAuthorized,
  });

  const { instructor } = userData?.me || {};

  const getNavLinks = (isInstructor: boolean): NavLinkType[] => [
    {
      title: t('faq'),
      to: '/faq',
    },
    ...(isInstructor
      ? []
      : [
          {
            title: t('teach_on_classrool'),
            to: '/instructor-application',
          },
        ]),
    {
      exact: true,
      icon: <SearchOutlined />,
      title: t('find_courses'),
      to: '/',
    },

    ...(isAuthorized
      ? [
          {
            icon: <CarryOutOutlined />,
            title: t('schedule'),
            to: '/schedule',
          },
        ]
      : []),
    ...(isInstructor
      ? [
          {
            icon: <AppstoreAddOutlined />,
            title: t('instructor_hub'),
            to: '/instructor-hub',
          },
        ]
      : []),
  ];
  return (
    <>
      <div
        className={classNames(HeaderClasses['header-mobile'], {
          [HeaderClasses['header-mobile-open']]: show,
        })}
      >
        <Row className="mb-8 px-24 pt-16">
          <Col className="mr-24">
            <img src={ToggleMobile} alt="toggle" onClick={() => onToggle()} />
          </Col>
          <Col>
            <Logo sm />
          </Col>
        </Row>
        <Divider className="my-8" />
        <div className={HeaderClasses['header-mobile-profile']}>
          <DropdownProfile notifyPosition="right" size={60} />
        </div>

        <Divider className="my-8" />

        <div className={HeaderClasses['header-mobile-links']}>
          {getNavLinks(!!instructor).map((props, index) => {
            return <HeaderLink {...props} key={index} mobile />;
          })}

          {!isAuthorized && (
            <div className="p-8">
              <Divider className="my-8" />
              <Link to="/login" className={HeaderClasses['header-sign-in']}>
                <Button block type="link">
                  {t('sign_in')}
                </Button>
              </Link>

              <Link to="/registration">
                <Button block type="primary">
                  {t('join_classrool')}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div
        onClick={() => onToggle()}
        className={classNames(HeaderClasses['header-mobile-bg'], {
          [HeaderClasses['header-mobile-bg-open']]: show,
        })}
      ></div>
    </>
  );
};

const getMenuComponent = (isInstructor: boolean, isAuthorized: boolean) => {
  const { processSignOut } = useSignOut();
  const t = getTranslator();

  const getMenuItems = (): MenuItemType[] => [
    {
      icon: <MessageOutlined />,
      link: '/chat',
      mt: true,
      title: t('chat'),
    },
    {
      icon: <CarryOutOutlined />,
      link: '/schedule',
      title: t('learner_schedule'),
    },
    {
      icon: <HeartOutlined />,
      link: '/favorites',
      title: t('favorites'),
    },
    {
      divider: true,
      icon: <DollarOutlined />,
      link: '/my-purchases',
      mb: true,
      title: t('my_courses'),
    },
    ...(isInstructor
      ? [
          {
            divider: true,
            icon: <BookOutlined />,
            link: '/instructor-hub',
            title: t('instructor_hub'),
          },
        ]
      : [
          {
            divider: true,
            icon: <BookOutlined />,
            link: '/instructor-application',
            title: t('teach_on_classrool'),
          },
        ]),
    {
      icon: <SettingOutlined />,
      link: '/profile',
      mt: true,
      title: t('profile_and_settings'),
    },
    {
      icon: <QuestionCircleOutlined />,
      link: '/faq',
      title: t('help'),
    },
  ];

  return (
    <Menu>
      {getMenuItems().map(({ link, title, mt, mb, icon, divider }) => {
        return (
          <React.Fragment key={title}>
            <Menu.Item
              className={classNames(HeaderClasses['menu-item'], {
                'mb-8': mb,
                'mt-8': mt,
              })}
            >
              <Link to={link}>
                {icon}
                <Text.SM>{title}</Text.SM>
              </Link>
            </Menu.Item>
            {divider && <Menu.Divider />}
          </React.Fragment>
        );
      })}

      {isAuthorized && (
        <Menu.Item
          className={classNames(HeaderClasses['menu-item'], 'mb-8')}
          danger
          key="sign-out"
          onClick={processSignOut}
        >
          <PoweroffOutlined />
          <Text.SM>{t('sign_out')}</Text.SM>
        </Menu.Item>
      )}
    </Menu>
  );
};

const DropdownProfile: FC<{
  notifyPosition?: 'left' | 'right';
  size?: number;
}> = ({ size = 24, notifyPosition = 'left' }) => {
  const { isAuthorized } = useIsAuthorized();
  const { data: userData } = useQuery<
    HeaderInfoQuery,
    HeaderInfoQueryVariables
  >(headerInfoQuery, {
    fetchPolicy: 'cache-first',
    skip: !isAuthorized,
  });

  const { avatarUrl, firstName, instructor } = userData?.me || {};

  const Notifications = () => (
    <Button size="small" type="text">
      <Badge dot>
        <BellOutlined />
      </Badge>
    </Button>
  );

  return (
    <Row align="middle">
      {notifyPosition === 'left' && (
        <Col className="mr-16">
          <Notifications />
        </Col>
      )}
      <Col>
        <Dropdown
          arrow
          placement="bottomRight"
          className={HeaderClasses['header-item']}
          overlay={getMenuComponent(!!instructor, isAuthorized)}
          trigger={['click']}
        >
          <Button
            className={HeaderClasses['header-profile']}
            size="small"
            type="text"
          >
            <Avatar
              src={avatarUrl}
              size={size}
              shape="square"
              icon={<UserOutlined />}
              className="mr-32"
            />
            <Title.SM
              className={`${HeaderClasses['header-profile-name']} mr-8 inline-block text-gray-main`}
            >
              {isAuthorized ? firstName : 'Guest'}
            </Title.SM>
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      </Col>
      {notifyPosition === 'right' && (
        <Col className="ml-16">
          <Notifications />
        </Col>
      )}
    </Row>
  );
};

export const Header = () => {
  const [isMobileOpened, setMobileOpened] = useState(false);
  const { t } = useTranslation();
  const { isAuthorized: isAuthenticated } = useIsAuthorized();

  const { data: userData } = useQuery<
    HeaderInfoQuery,
    HeaderInfoQueryVariables
  >(headerInfoQuery, {
    fetchPolicy: 'cache-first',
    skip: !isAuthenticated,
  });

  const { instructor } = userData?.me || {};

  const getNavLinks = (isInstructor: boolean): NavLinkType[] => [
    {
      title: t('faq'),
      to: '/faq',
    },
    ...(isInstructor
      ? []
      : [
          {
            title: t('teach_on_classrool'),
            to: '/instructor-application',
          },
        ]),
    {
      exact: true,
      icon: <SearchOutlined />,
      title: t('find_courses'),
      to: '/',
    },
    {
      icon: <CarryOutOutlined />,
      title: t('schedule'),
      to: '/schedule',
    },
    ...(isInstructor
      ? [
          {
            icon: <AppstoreAddOutlined />,
            title: t('instructor_hub'),
            to: '/instructor-hub',
          },
        ]
      : []),
  ];

  const getGuestNavLinks = (): NavLinkType[] => [
    {
      exact: true,
      icon: <SearchOutlined />,
      title: t('find_courses'),
      to: '/',
    },
  ];

  const toggleMobile = () => setMobileOpened(!isMobileOpened);

  return (
    <>
      <div className={classnames(HeaderClasses['header-wrap'])}>
        <div className={HeaderClasses['header']}>
          <div>
            <img
              className={HeaderClasses['header-mobile-toggle']}
              src={ToggleMobile}
              alt="toggle"
              onClick={() => toggleMobile()}
            />
            <Logo sm />
          </div>

          {isAuthenticated ? (
            <div
              className={classnames(
                HeaderClasses['header-desktop'],
                HeaderClasses['header-info'],
              )}
            >
              {getNavLinks(!!instructor).map((props, index) => {
                return <HeaderLink {...props} key={index} />;
              })}
              <Divider
                className="mr-24"
                style={{
                  borderColor: `rgba(160, 165, 186, 0.8)`,
                  height: 24,
                }}
                type="vertical"
              />
              <DropdownProfile />
            </div>
          ) : (
            <div
              className={classnames(
                HeaderClasses['header-desktop'],
                HeaderClasses['header-info'],
              )}
            >
              {getGuestNavLinks().map((props, index) => {
                return <HeaderLink {...props} key={index} />;
              })}

              <Divider type="vertical" />

              <Link to="/login" className={HeaderClasses['header-sign-in']}>
                <Button type="link">{t('sign_in')}</Button>
              </Link>

              <Link to="/registration">
                <Button type="primary">{t('join_classrool')}</Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <MobileHeader show={isMobileOpened} onToggle={() => toggleMobile()} />
    </>
  );
};
