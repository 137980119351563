import { StoredMessage } from './types';

export const makeConversationChannelName = (
  firstUserId: string,
  secondUserId: string,
) => [firstUserId, secondUserId].sort().join('_');

export const makeUserChannelGroupName = (userId: string) => `cg_${userId}`;

export const makeUserChannelName = (userId: string) => userId;

export const getDateFromTimetoken = (timetoken: string) =>
  new Date(+timetoken / 10_000);

export const sortMessages = (messages: StoredMessage[]) =>
  messages.sort((m1, m2) => Number(m2.timetoken) - Number(m1.timetoken));
