import './app.css';
import './app.less';

import { ApolloClient } from '@apollo/react-hooks';
import loadable from '@loadable/component';
import * as React from 'react';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import {
  BrowserRouter,
  BrowserRouterProps,
  Route,
  StaticRouterProps,
  Switch,
} from 'react-router-dom';

import { GraphqlProvider } from './infrastructure/graphql/provider';
import i18n from './infrastructure/i18n';
import { AppStore } from './infrastructure/redux';
import { ReduxProvider } from './infrastructure/redux/provider';
import { ChatProvider } from './modules/chat/chat-provider';
import { CourseDetailsScreen } from './screens/course/details/';
import { FindCourses } from './screens/home';
import { CookiesAgreement } from './shared/components/cookie-agreement';
import { InstructorRoute } from './shared/components/instructor-route';
import { SimpleLoader } from './shared/components/loader';
import { PrivateRoute } from './shared/components/private-route';
import { ScrollTop } from './shared/components/scrollTop';
import { PrefetchUser } from './shared/services/prefetch-user';
import { isClient } from './shared/utils/common';

interface Props {
  client: ApolloClient<any>;
  router?: React.ComponentType<BrowserRouterProps | StaticRouterProps>;
  routerProps?: BrowserRouterProps | StaticRouterProps;
  store: AppStore;
}

const Loader = () => (
  <div className="cr-layout-content">
    <SimpleLoader size="large" />
  </div>
);

const noSSRConfig = {
  fallback: <Loader />,
  ssr: false,
};

const Login = loadable(() => import('./screens/log-in'), noSSRConfig);

const ProfileScreen = loadable(() => import('./screens/profile'), noSSRConfig);

const ScheduleScreen = loadable(
  () => import('./screens/schedule'),
  noSSRConfig,
);

const InstructorProfileScreen = loadable(
  () => import('./screens/instructor/profile'),
  noSSRConfig,
);

const InstructorScheduleScreen = loadable(
  () => import('./screens/instructor-hub/schedule'),
  noSSRConfig,
);

const ChatScreen = loadable(() => import('./screens/chat'), noSSRConfig);

const AddCourseScreen = loadable(
  () => import('./screens/add-course'),
  noSSRConfig,
);

const EditCourseScreen = loadable(
  () => import('./screens/course/edit'),
  noSSRConfig,
);

const ForgotPasswordScreen = loadable(
  () => import('./screens/forgot-password'),
  noSSRConfig,
);

const ResetPasswordScreen = loadable(
  () => import('./screens/reset-password'),
  noSSRConfig,
);

const RegistrationScreen = loadable(
  () => import('./screens/registration'),
  noSSRConfig,
);

const FrequentlyAskedQuestions = loadable(
  () => import('./screens/faq'),
  noSSRConfig,
);

const FrequentlyAskedQuestionsDetails = loadable(
  () => import('./screens/faq/faq-details'),
  noSSRConfig,
);

const InstructorSettingsScreen = loadable(
  () => import('./screens/instructor-hub/settings'),
  noSSRConfig,
);

const MyCoursesInstructorScreen = loadable(
  () => import('./screens/instructor-hub/my-courses'),
  noSSRConfig,
);

const InstructorHub = loadable(
  () => import('./screens/instructor-hub'),
  noSSRConfig,
);

const InstructorWithdrawalsScreen = loadable(
  () => import('./screens/instructor-hub/withdrawals'),
  noSSRConfig,
);

const MyPurchasesScreen = loadable(
  () => import('./screens/my-purchases'),
  noSSRConfig,
);

const FavoritesScreen = loadable(
  () => import('./screens/favorites'),
  noSSRConfig,
);

const InstructorApplicationScreen = loadable(
  () => import('./screens/instructor-application'),
  noSSRConfig,
);

const DocumentScreen = loadable(
  () => import('./screens/document'),
  noSSRConfig,
);

export const App = ({
  router: Router = BrowserRouter,
  routerProps = {},
  store,
  client,
}: Props) => {
  const isWeb = isClient();
  useEffect(() => {
    if (isWeb) {
      const timer1 = setTimeout(() => {
        Login.preload();
        RegistrationScreen.preload();
        ProfileScreen.preload();
        ScheduleScreen.preload();
        InstructorProfileScreen.preload();
        InstructorScheduleScreen.preload();
        ChatScreen.preload();
        AddCourseScreen.preload();
        EditCourseScreen.preload();
        ForgotPasswordScreen.preload();
        ResetPasswordScreen.preload();
        FrequentlyAskedQuestions.preload();
        FrequentlyAskedQuestionsDetails.preload();
        InstructorSettingsScreen.preload();
        MyCoursesInstructorScreen.preload();
        InstructorHub.preload();
        InstructorWithdrawalsScreen.preload();
        MyPurchasesScreen.preload();
        FavoritesScreen.preload();
        InstructorApplicationScreen.preload();
        DocumentScreen.preload();
      }, 4000);

      return () => {
        clearTimeout(timer1);
      };
    }
    return;
  }, [isWeb]);

  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <GraphqlProvider client={client}>
          <Router {...routerProps}>
            <ChatProvider>
              <PrefetchUser />
              <ScrollTop />
              <Switch>
                <Route path="/login" component={Login} />
                <Route
                  path="/forgot-password"
                  component={ForgotPasswordScreen}
                />
                <Route
                  path="/reset-password/:token"
                  component={ResetPasswordScreen}
                />
                <Route path="/registration" component={RegistrationScreen} />
                <Route path="/faq" component={FrequentlyAskedQuestions} />
                <Route
                  path="/faq-details/:id"
                  component={FrequentlyAskedQuestionsDetails}
                />
                <InstructorRoute
                  path="/course/:slug/edit"
                  component={EditCourseScreen}
                />
                <Route path="/course/:slug" component={CourseDetailsScreen} />
                <PrivateRoute path="/profile" component={ProfileScreen} />
                <Route
                  path="/instructor/:id"
                  component={InstructorProfileScreen}
                />
                <PrivateRoute path="/schedule" component={ScheduleScreen} />
                <InstructorRoute
                  path="/instructor-hub/profile-and-settings"
                  component={InstructorSettingsScreen}
                />
                <InstructorRoute
                  path="/instructor-hub/my-courses"
                  component={MyCoursesInstructorScreen}
                />
                <InstructorRoute
                  path="/instructor-hub/withdrawals"
                  component={InstructorWithdrawalsScreen}
                />
                <InstructorRoute
                  path="/instructor-hub/schedule"
                  component={InstructorScheduleScreen}
                />
                <InstructorRoute
                  path="/instructor-hub"
                  component={InstructorHub}
                />
                <PrivateRoute
                  path="/instructor-application"
                  component={InstructorApplicationScreen}
                />
                <PrivateRoute path="/add-course" component={AddCourseScreen} />
                <PrivateRoute
                  path="/my-purchases"
                  component={MyPurchasesScreen}
                />
                <PrivateRoute path="/favorites" component={FavoritesScreen} />
                <PrivateRoute path="/chat" component={ChatScreen} />
                <Route path="/privacy-policy" component={DocumentScreen} />
                <Route
                  path="/terms-and-conditions"
                  component={DocumentScreen}
                />
                <Route path="/" component={FindCourses} />
              </Switch>
              <CookiesAgreement />
            </ChatProvider>
          </Router>
        </GraphqlProvider>
      </ReduxProvider>
    </I18nextProvider>
  );
};
