import { StarFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import { inUnits } from '../../../utils/common';
import { colors } from '../../colors';
import { Text, Title } from '../../typography';

type RateProps = {
  className?: string;
  linkTo: string;
  rate?: number;
  reviews?: number;
};

export const RateLink: FC<RateProps> = ({
  className,
  linkTo,
  rate = 0,
  reviews = 0,
}) => {
  const { t } = useTranslation();

  return (
    <Row className={className} gutter={8} align="middle">
      <Col>
        <StarFilled style={{ color: colors.yellow }} />
      </Col>
      <Col>
        <Title.SM className="inline">{rate.toFixed(1)}</Title.SM>
      </Col>
      <Col>
        <HashLink
          smooth
          to={linkTo}
          style={{ textDecoration: 'underline' }}
          className="text-gray"
        >
          <Text.SM>
            ({`${reviews} ${reviews === 1 ? t('review') : t('reviews')}`})
          </Text.SM>
        </HashLink>
      </Col>
    </Row>
  );
};
