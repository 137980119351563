import { PresenceEvent } from 'pubnub';
import * as R from 'ramda';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { apolloClient } from '../../infrastructure/graphql';
import { PublicUserGql } from '../../shared/types/generated';
import { publicUserQuery } from '../users/queries';
import { getUserId } from '../users/selectors';
import {
  saveMessage,
  setChannelMembers,
  setChannelMeta,
  setOnlineMembers,
  startFetchingLastMessages,
  stopFetchingLastMessages,
} from './actions';
import { pubnub } from './config';
import {
  getMembers,
  getMessages,
  getMeta,
  getOnlineMembers,
} from './selectors';
import { Message, PubnubMessage } from './types';
import { makeUserChannelGroupName, makeUserChannelName } from './utils';

type ThnkAction = ThunkAction<any, any, any, Action>;

export const fetchChannelMeta = (channel: string): ThnkAction => (
  dispatch,
  getState,
) => {
  const meta = getMeta(channel)(getState());
  const members = getMembers(channel)(getState());
  const currentUserId = getUserId(getState());
  const otherMember = members.find((m) => m !== currentUserId);

  if (otherMember) {
    apolloClient
      .query({
        query: publicUserQuery,
        variables: { input: { id: otherMember } },
      })
      .then(({ data }) => {
        const user = data?.publicUser as PublicUserGql;

        if (user) {
          dispatch(
            setChannelMeta({
              ...meta,
              avatarUrl: user.avatarUrl || null,
              id: channel,
              name: `${user.firstName || ''} ${user.lastName || ''}`,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.message === 'User is not found') {
          pubnub.objects.removeChannelMembers({
            channel,
            uuids: [otherMember],
          });
        }
      });
  }
};

export const fetchChannelsMembers = (channels: string[]): ThnkAction => (
  dispatch,
) => {
  channels.forEach((channel) => {
    pubnub.objects.getChannelMembers({ channel }).then(({ data }) => {
      const members = data.map((d) => d.uuid.id);

      dispatch(setChannelMembers({ channel, members }));
      dispatch(fetchChannelMeta(channel));
    });
  });
};

export const fetchLastMessages = (): ThnkAction => (dispatch, getState) => {
  const userId = getUserId(getState());

  if (!userId) {
    return;
  }

  const channelGroup = makeUserChannelGroupName(userId);
  const userChannel = makeUserChannelName(userId);

  dispatch(startFetchingLastMessages());

  pubnub.channelGroups
    .listChannels({ channelGroup })
    .then(({ channels }) => {
      return (channels || []).filter((ch) => ch !== userChannel);
    })
    .then((channels) => {
      dispatch(fetchChannelsMembers(channels));

      return channels;
    })
    .then((channels) => pubnub.fetchMessages({ channels, count: 1 }))
    .then(({ channels }) => {
      if (!channels) {
        return;
      }

      Object.values(channels).map((messages) => {
        const message = (messages || [])[0];

        if (!message) {
          return;
        }

        dispatch(
          saveMessage({
            ...message.message,
            channel: message.channel,
            timetoken: `${message.timetoken}`,
          }),
        );
      });
    })
    .finally(() => {
      dispatch(stopFetchingLastMessages());
    });
};

export const fetchChannelMessages = (channel: string): ThnkAction => (
  dispatch,
  getState,
) => {
  const { allMessagesStored, ...channelMeta } = getMeta(channel)(getState());

  if (allMessagesStored) {
    return;
  }

  const storedMsgs = getMessages(channel)(getState());

  pubnub
    .fetchMessages({
      channels: [channel],
      count: 10,
      start: R.last(storedMsgs)?.timetoken,
    })
    .then(({ channels }) => {
      const msgs = R.pathOr([], [channel], channels) as PubnubMessage[];

      if (msgs.length === 0) {
        dispatch(setChannelMeta({ ...channelMeta, allMessagesStored: true }));
      } else {
        msgs.forEach((m) => {
          dispatch(
            saveMessage({
              ...m.message,
              channel: m.channel,
              timetoken: m.timetoken,
            }),
          );
        });
      }
    });
};

export const sendMessage = (
  channel: string,
  message: Message,
): ThnkAction => () => {
  pubnub
    .publish({ channel, message })
    .then((res) => console.log('res', res))
    .catch((err) => console.log('err', err));
};

export const handlePresence = (presence: PresenceEvent): ThnkAction => (
  dispatch,
  getState,
) => {
  const onlineMembers = getOnlineMembers(getState());
  const member = presence.uuid;
  const isOnline = presence.action === 'join';

  const updatedOnlineMembers = R.uniq(
    isOnline ? [member, ...onlineMembers] : R.without([member], onlineMembers),
  );

  dispatch(setOnlineMembers({ onlineMembers: updatedOnlineMembers }));
};
