import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  HasActiveSubscriptionQuery,
  HasActiveSubscriptionQueryVariables,
  MeQuery,
  MeQueryVariables,
  PublicUserQuery,
  PublicUserQueryVariables,
} from '../../shared/types/generated';
import { defaultPath, maybeCall } from '../../shared/utils/common';
import { setUser } from './actions';
import { hasActiveSubscription, meQuery, publicUserQuery } from './queries';
import { getUserToken } from './selectors';
import { signOut } from './services';

export const useNewUserHandler = (
  id?: string,
  token?: string,
  cb?: () => void,
) => {
  const dispatch = useDispatch();
  const { goNext } = useHistoryNextPath();

  useEffect(() => {
    if (id && token) {
      dispatch(setUser({ id, token }));
      goNext();

      maybeCall(cb);
    }
  }, [id, token]);
};

export const useHistoryNextPath = () => {
  const history = useHistory();
  const { state = {} } = useLocation();

  const goNext = () => {
    // check if state path is passed
    let nextPath = null;

    if (state && typeof state === 'object') {
      // @ts-ignore
      nextPath = state?.from?.pathname;
    }

    if (nextPath) {
      history.push(nextPath);
    }
    // if POP, no history available, cannot go back
    else if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push(defaultPath);
    }
  };

  return {
    goNext,
  };
};

export const useIsAuthorized = () => {
  const userToken = useSelector(getUserToken);

  return { isAuthorized: !!userToken };
};

export const useCurrentUser = () => {
  const { data } = useQuery<MeQuery, MeQueryVariables>(meQuery);

  return { user: data?.me || null };
};

export const useHasActiveSubscription = () => {
  const { isAuthorized } = useIsAuthorized();
  const { data } = useQuery<
    HasActiveSubscriptionQuery,
    HasActiveSubscriptionQueryVariables
  >(hasActiveSubscription, {
    fetchPolicy: 'network-only',
    skip: !isAuthorized,
  });
  return data?.me?.hasActiveSubscription || false;
};

export const usePublicUser = (id?: string) => {
  const [fetchPublicUser, { data }] = useLazyQuery<
    PublicUserQuery,
    PublicUserQueryVariables
  >(publicUserQuery);

  useEffect(() => {
    if (id) {
      fetchPublicUser({ variables: { input: { id } } });
    }
  }, [id]);

  const user = useMemo(() => data?.publicUser || null, [data]);

  return user;
};

export const useSignOut = () => {
  const history = useHistory();

  const processSignOut = async () => {
    await signOut();
    history.push(defaultPath);
  };

  return {
    processSignOut,
  };
};
