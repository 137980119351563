import { complement, isNil, pathOr, pipe } from 'ramda';

export const getUserId = pathOr<string | null>(null, ['user', 'id']);

export const getUserToken = pathOr<string | null>(null, ['user', 'token']);

export const getUserCookiesAgreement = pathOr<boolean>(false, [
  'user',
  'cookiesAgreement',
]);

export const isUserAuthenticated = pipe(getUserToken, complement(isNil));
