import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';

import { getToken } from '../../modules/users/services';
import { graphqlEndpoint } from '../config';
import { apolloCache, getApolloCache } from './cache';
import { handleErrors } from './utils';

const errorLink = onError(
  ({ operation, graphQLErrors, networkError, response }) => {
    console.log('Error while perform operation', {
      graphQLErrors,
      networkError,
      operation,
    });

    handleErrors({ graphQLErrors, networkError });
  },
);

const httpUploadLink = createUploadLink({
  uri: graphqlEndpoint,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([authLink, errorLink, httpUploadLink]),
});

export const getApolloClientForSSR = () =>
  new ApolloClient({
    cache: getApolloCache(),
    link: createHttpLink({
      credentials: 'same-origin',
      fetch,
      uri: 'http://localhost:3000/graphql',
    }),
    ssrMode: true,
  });
