import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICourseType } from '../../../../../../shared/src/data/course-type';
import i18n from '../../../../infrastructure/i18n';
import { CourseType } from '../../../types/generated';
import { SimpleFilter } from '../shared/simple-filter';

interface IFilterType {
  onChange: (value: string) => void;
  value: string;
}

export const CourseTypes: ICourseType[] = [
  {
    code: CourseType.BeginnerToPreIntermediate,
    name: i18n.t('beginner_to_pre_intermediate'),
  },
  {
    code: CourseType.IntermediateToUpperIntermediate,
    name: i18n.t('intermediate_to_upper_intermediate'),
  },
  {
    code: CourseType.Advanced,
    name: i18n.t('advanced'),
  },
];

export const CourseTypeMap: { [key: string]: string } = {
  [CourseType.BeginnerToPreIntermediate]: i18n.t(
    'beginner_to_pre_intermediate',
  ),
  [CourseType.IntermediateToUpperIntermediate]: i18n.t(
    'intermediate_to_upper_intermediate',
  ),
  // [CourseType.Ongoing]: i18n.t('ongoing_class'),
  [CourseType.Advanced]: i18n.t('advanced'),
};

export const TypeFilter: FC<IFilterType> = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <SimpleFilter
      defaultSubtitle={t('select_level')}
      filtersList={CourseTypes}
      onChange={onChange}
      title={t('course_level')}
      value={value}
    />
  );
};
