import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { useFormik as useFrmk } from 'formik';
import debounce from 'lodash.debounce';
import React from 'react';

type UseFormikProps = Parameters<typeof useFrmk>[0];

type FieldChangeEvent =
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | RadioChangeEvent;

export type UseFormik = ReturnType<typeof useFrmk> & {
  getFieldStatus: (fieldName: string) => FormItemProps['status'];
  handlCheckboxChange: (
    fieldName: string,
  ) => (event: CheckboxChangeEvent) => void;
  handleFieldChange: (fieldName: string) => (event: FieldChangeEvent) => void;
  handleRateChange: (fieldName: string) => (value: number) => void;
  handleSelectChange: (fieldName: string) => (value: number) => void;
};

export const useFormik = (props: UseFormikProps): UseFormik => {
  const formik = useFrmk({
    ...props,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const validateField = debounce(formik.validateField, 1000);

  return {
    ...formik,
    getFieldStatus: (fieldName) =>
      formik.errors[fieldName] ? 'error' : undefined,
    handlCheckboxChange: (fieldName) => (event) => {
      formik.setFieldValue(fieldName, event.target.checked);
      validateField(fieldName);
    },
    handleFieldChange: (fieldName) => (event) => {
      formik.setFieldValue(fieldName, event.target.value);
      validateField(fieldName);
    },
    handleRateChange: (fieldName) => (value) => {
      formik.setFieldValue(fieldName, value);
      validateField(fieldName);
    },
    handleSelectChange: (fieldName) => (value) => {
      formik.setFieldValue(fieldName, value);
      validateField(fieldName);
    },
  };
};
