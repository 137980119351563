import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import play from '../../../assets/img/play.svg';
import defaultPoster from '../../../assets/img/player-default-poster.svg';
import styles from './index.module.less';

type VimeoVideoType = {
  className?: string;
  coverPhotoUrl: string;
  vimeoVideoId?: string | null;
};

export const CourseDetailsFeaturedMedia: FC<VimeoVideoType> = ({
  className,
  vimeoVideoId,
  coverPhotoUrl,
}) => {
  if (!vimeoVideoId) {
    return (
      <div className={classNames(styles['vimeo'], className)}>
        <img
          className={styles['vimeo-cover']}
          width="100%"
          src={coverPhotoUrl}
          alt={`Course Cover ${vimeoVideoId}`}
        />
      </div>
    );
  }
  const [iframe, setIframe] = useState(false);
  const [preconnected, setPreconnected] = useState(false);
  const [posterUrl, setPosterUrl] = useState(defaultPoster);

  const warmConnections = () => {
    if (preconnected) return;
    setPreconnected(true);
  };

  const api_url = `https://vimeo.com/api/v2/video/${vimeoVideoId}.json`;

  const getPoster = async () => {
    // Now fetch the JSON that locates our placeholder from vimeo's JSON API
    const api_res = await fetch(api_url);
    const res = await api_res.json();

    setPosterUrl(res[0].thumbnail_large);
  };

  useEffect(() => {
    getPoster();
  }, []);

  return (
    <div
      className={classNames(styles['vimeo'], className)}
      onClick={() => setIframe(true)}
      onPointerOver={warmConnections}
    >
      <link rel="preload" href={posterUrl} as="image" />

      {preconnected && (
        <>
          {/* Host that Vimeo uses to serve JS needed by player */}
          <link rel="preconnect" href={'https://f.vimeocdn.com'} />

          {/* The iframe document comes from player.vimeo.com */}
          <link rel="preconnect" href={'https://player.vimeo.com'} />

          {/* Image for placeholder comes from i.vimeocdn.com */}
          <link rel="preconnect" href={'https://i.vimeocdn.com'} />
          {/* {adNetwork && (
            <>
              <link rel="preconnect" href="https://static.doubleclick.net" />
              <link
                rel="preconnect"
                href="https://googleads.g.doubleclick.net"
              />
            </>
          )} */}
        </>
      )}

      {!iframe && posterUrl && (
        <>
          <img
            className={styles['vimeo-cover']}
            width="100%"
            src={posterUrl}
            // src="https://images.unsplash.com/photo-1532618793091-ec5fe9635fbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2700&q=80"
            alt={`Course Cover ${vimeoVideoId}`}
          />

          <div className={styles['vimeo-play']}>
            <img src={play} alt="play btn" />
          </div>
        </>
      )}

      {iframe && (
        <>
          <iframe
            className={styles['vimeo-iframe']}
            src={`https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </>
      )}
    </div>
  );
};
