import { Button, Col, Empty, Row, Typography } from 'antd';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../shared/components/typography';
import EmptyReviewImg from '../../assets/img/empty-reviews.svg';
import { Review, ReviewType } from './review';

type ReviewsType = {
  canPostReview: boolean;
  data: ReviewType[];
  openReviewModal: () => void;
};

export const Reviews: FC<ReviewsType> = ({
  data,
  canPostReview,
  openReviewModal,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Title.LG id="reviews" className="mb-24 reviews">
        {t('reviews')}
      </Title.LG>
      {!data?.length ? (
        <Row justify="center">
          <Col xs={24} lg={12}>
            <Empty
              image={EmptyReviewImg}
              imageStyle={{
                height: 120,
              }}
              description={
                <Typography.Text>{t('no_reviews_placeholder')}</Typography.Text>
              }
            >
              <Button
                type="primary"
                disabled={!canPostReview}
                ghost
                onClick={openReviewModal}
              >
                {t('write_a_review')}
              </Button>
            </Empty>
          </Col>
        </Row>
      ) : (
        data.map((item) => <Review key={item.id} {...item} />)
      )}
    </Fragment>
  );
};
