import i18n from '../../infrastructure/i18n';
import { CourseCategoryFilter } from '../types/generated';

export interface ICategory {
  code: string;
  name: string;
}

export const categories: ICategory[] = [
  {
    code: CourseCategoryFilter.GeneralEnglish,
    name: i18n.t('generalEnglish'),
  },
  {
    code: CourseCategoryFilter.BusinessEnglish,
    name: i18n.t('businessEnglish'),
  },
  {
    code: CourseCategoryFilter.EnglishForIt,
    name: i18n.t('englishForIt'),
  },
  {
    code: CourseCategoryFilter.Ielts,
    name: i18n.t('ielts'),
  },
  {
    code: CourseCategoryFilter.Toefl,
    name: i18n.t('toefl'),
  },
  {
    code: CourseCategoryFilter.SpeakingClubs,
    name: i18n.t('speakingClubs'),
  },
];
