import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../../../../public/locales/en/translation.json';
import translationPL from '../../../../../public/locales/pl/translation.json';
import translationUK from '../../../../../public/locales/uk/translation.json';
import { Language } from '../../../../shared/src/enums';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    defaultNS: 'translations',
    fallbackLng: Language.en,
    interpolation: { escapeValue: false },
    resources: {
      // country codes reference https://www.npmjs.com/package/i18n-iso-countries
      [Language.en]: { translations: translationEN },
      [Language.pl]: { translations: translationPL },
      [Language.uk]: { translations: translationUK },
    },
    supportedLngs: [Language.en, Language.pl, Language.uk],
  });

export default i18n;
