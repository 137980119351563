import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import { chatReducer, chatState } from '../../modules/chat/reducer';
import { courseReducer, courseState } from '../../modules/courses/reducer';
import { userReducer, userState } from '../../modules/users/reducer';
import { isClient } from '../../shared/utils/common';
import { persistConfig } from './persist';

const rootReducer = combineReducers({
  chat: chatReducer,
  newCourse: courseReducer,
  user: userReducer,
});

const appState = {
  chat: chatState,
  newCourse: courseState,
  user: userState,
};

export type AppStore = Store<typeof appState>;

let store: AppStore | null = null;

export const getStore = () => store;

export const configureReduxStore = (initialState?: typeof appState) => {
  let persistor: Persistor | undefined = undefined;

  if (isClient()) {
    store = createStore(
      persistReducer(persistConfig, rootReducer),
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware)),
    );

    persistor = persistStore(store);
  } else {
    store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(thunkMiddleware),
    );
  }

  return { persistor, store };
};
