import { Radio } from 'antd';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICategory } from '../../../enums/categories';
import { CourseCategoryFilter } from '../../../types/generated';
import styles from './index.module.less';

export interface ICategoryFilter {
  categories: ICategory[];
  className?: string;
  defaultValue?: string;
  onChange: (e: string) => void;
}

export const CategoryFilter: FC<ICategoryFilter> = ({
  onChange,
  className,
  categories,
}) => {
  const { t } = useTranslation();

  return (
    <Radio.Group
      className={classnames(styles['category-filter'], className)}
      onChange={(e) => onChange(e.target.value)}
    >
      {[{ code: '', name: t('all') }, ...categories].map(({ code, name }) => (
        <Radio.Button
          className={styles['category-filter-item']}
          key={code}
          value={code}
        >
          <span className={styles['category-filter-name']}>{name}</span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
