import { PubNubProvider } from 'pubnub-react';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsAuthorized } from '../users/hooks';
import { getUserId } from '../users/selectors';
import { saveMessage } from './actions';
import { pubnub } from './config';
import { handlePresence } from './thunks';
import { makeUserChannelGroupName, makeUserChannelName } from './utils';

const leaveApplication = () => {
  pubnub.unsubscribeAll();
};

const AuthorizedChatProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);

  useEffect(() => {
    if (!userId) {
      return;
    }

    pubnub.setUUID(userId);

    pubnub.addListener({
      message: ({ channel, message, timetoken }) => {
        dispatch(saveMessage({ ...message, channel, timetoken }));
      },
      presence: (event) => {
        dispatch(handlePresence(event));
      },
    });

    const channel = makeUserChannelName(userId);
    const channelGroup = makeUserChannelGroupName(userId);

    pubnub.channelGroups
      .addChannels({
        channelGroup,
        channels: [channel],
      })
      .then(() => {
        pubnub.subscribe({ channelGroups: [channelGroup], withPresence: true });
      })
      .catch((err) => {
        console.log('chat subscription error', err);
      });

    return leaveApplication;
  }, [userId]);

  useEffect(() => {
    window.addEventListener('beforeunload', leaveApplication);
  }, []);

  return <PubNubProvider client={pubnub}>{children}</PubNubProvider>;
};

export const ChatProvider: FC<any> = ({ children }) => {
  const { isAuthorized } = useIsAuthorized();

  return isAuthorized ? (
    <AuthorizedChatProvider>{children}</AuthorizedChatProvider>
  ) : (
    children
  );
};
