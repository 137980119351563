import {
  ResetNewCourseAction,
  UpdateCourseStep,
  UpdateNewCourseAction,
} from './types';

export const updateNewCourse = (
  payload: UpdateNewCourseAction['payload'],
): UpdateNewCourseAction => ({
  payload,
  type: 'UPDATE_NEW_COURSE',
});

export const updateCourseStep = (
  payload: UpdateCourseStep['payload'],
): UpdateCourseStep => ({
  payload,
  type: 'UPDATE_COURSE_STEP',
});

export const resetNewCourse = (): ResetNewCourseAction => ({
  type: 'RESET_NEW_COURSE',
});
