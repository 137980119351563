import { capitalCase } from 'change-case';

import { FailedResult } from './result';

export class EmailNotValidError extends FailedResult {
  public constructor(err: any) {
    super(err);
  }

  public static create(err: any): EmailNotValidError {
    return new EmailNotValidError(err);
  }
}

export class NotValidCredentials extends FailedResult {
  private constructor(err: any) {
    super(err, { foo: 'bar' });
  }

  public static create(err: any): NotValidCredentials {
    return new NotValidCredentials(err);
  }
}

export class SectionStartsTooSoonError extends FailedResult {
  private constructor(err: any, payload?: Record<string, string>) {
    super(err, payload);
  }

  public static create(
    startDate: Date,
    hours: number,
  ): SectionStartsTooSoonError {
    return new SectionStartsTooSoonError(
      `Section that starts on ${startDate.toISOString()} starts too soon. Please set it to be at least ${hours} hours from now`,
      { hours: String(hours), startDate: startDate.toISOString() },
    );
  }
}

export class InstructorNotFoundError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(userId: string): InstructorNotFoundError {
    return new InstructorNotFoundError(
      `Instructor for user ${userId} not found`,
    );
  }
}

export class InstructorNotApproved extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(userId: string): InstructorNotApproved {
    return new InstructorNotApproved(
      `Instructor for user ${userId} is not yet approved`,
    );
  }
}

export class InstructorPaymentIsNotSetUpError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): InstructorPaymentIsNotSetUpError {
    return new InstructorPaymentIsNotSetUpError(
      `Please set up payment method in Instructor Hub`,
    );
  }
}

export class UserNotParticipantError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(
    userId: string,
    sectionId: string,
  ): UserNotParticipantError {
    return new UserNotParticipantError(
      `User ${userId} is not participating in section ${sectionId}`,
    );
  }
}

export class CourseAlreadyStartedError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): CourseAlreadyStartedError {
    return new CourseAlreadyStartedError(`Course has already started`);
  }
}

export class MeetingUrlNotGeneratedError extends FailedResult {
  public constructor(err: string) {
    super(err);
  }

  public static create(): MeetingUrlNotGeneratedError {
    return new MeetingUrlNotGeneratedError('Zoom url not generated');
  }
}

export class PaymentAlreadyConfirmedError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(intentId: string): PaymentAlreadyConfirmedError {
    return new PaymentAlreadyConfirmedError(
      `Payment with intent ${intentId} is already confirmed`,
    );
  }
}

export class CountryIsNotSupportedForPaymentsError extends FailedResult {
  private constructor(err: any, payload?: Record<string, string>) {
    super(err, payload);
  }

  public static create(
    countryCode: string,
  ): CountryIsNotSupportedForPaymentsError {
    return new CountryIsNotSupportedForPaymentsError(
      `We cannot accept payments in ${countryCode} yet`,
      { countryCode },
    );
  }
}

export class UserAlreadyEnrolledToThisCourseError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): UserAlreadyEnrolledToThisCourseError {
    return new UserAlreadyEnrolledToThisCourseError(
      `User has been already enrolled to this course`,
    );
  }
}

export class CannotEnrollToOwnCourseError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): CannotEnrollToOwnCourseError {
    return new CannotEnrollToOwnCourseError(`Cannot enroll to own course`);
  }
}

export class AllSectionPlacesTakenError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): AllSectionPlacesTakenError {
    return new AllSectionPlacesTakenError(`All places in section taken`);
  }
}

export class SectionFailedToStartError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): SectionFailedToStartError {
    return new SectionFailedToStartError(
      `Sorry, section failed to start because of low demand`,
    );
  }
}

export class SectionAlreadyStartedError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): SectionAlreadyStartedError {
    return new SectionAlreadyStartedError(`Section already started`);
  }
}

export class FailedToInitiatePaymentInProcessingError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): FailedToInitiatePaymentInProcessingError {
    return new FailedToInitiatePaymentInProcessingError(
      'Failed to initiate payment, please try again',
    );
  }
}

export class FailedToInitiatePaymentError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): FailedToInitiatePaymentError {
    return new FailedToInitiatePaymentError(
      'Failed to initiate payment, please try again',
    );
  }
}

export class FailedToCreatePaymentCustomerError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): FailedToCreatePaymentCustomerError {
    return new FailedToCreatePaymentCustomerError(
      'Failed to create payment customer for user, please try again',
    );
  }
}

export class FailedToRemovePaymentMethodError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): FailedToRemovePaymentMethodError {
    return new FailedToRemovePaymentMethodError(
      'Failed to remove payment method',
    );
  }
}

export class NotAllowedToCreateReviewError extends FailedResult {
  private constructor(err: any, payload?: Record<string, string>) {
    super(err, payload);
  }

  public static create(
    reviewCategoryName: string,
    id: string,
  ): NotAllowedToCreateReviewError {
    return new NotAllowedToCreateReviewError(
      `Cannot create review for ${reviewCategoryName} ${id.toString()}`,
      { reviewCategoryName },
    );
  }
}

export class InstructorAlreadyApprovedError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): InstructorAlreadyApprovedError {
    return new InstructorAlreadyApprovedError('Instructor already approved');
  }
}

export class EmailAlreadyExistsError extends FailedResult {
  constructor(email: string) {
    super(`The email ${email} associated for this account already exists`);
  }
}

export class InstructorDoesNotExistError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): InstructorDoesNotExistError {
    return new InstructorDoesNotExistError(
      'Instructor profile is not created yet',
    );
  }
}

export class PasswordsDoNotMatchError extends FailedResult {
  constructor() {
    super('Passwords do not match');
  }
}

export class EntityNotFoundError extends FailedResult {
  private constructor(err: string, payload: Record<string, string>) {
    super(err, payload);
  }

  public static create(entityName: string, id?: string): EntityNotFoundError {
    const entityNameText = capitalCase(entityName || 'Entity');
    return new EntityNotFoundError(
      `${entityNameText} ${id ? id + ' ' : ''}is not found`,
      { entityName: entityNameText },
    );
  }
}

export class FileUploadInvalidError extends FailedResult {
  private constructor(err: any, payload: Record<string, string>) {
    super(err, payload);
  }

  public static create(message: string): FileUploadInvalidError {
    const messageText = message || 'File format is invalid';
    return new FileUploadInvalidError(messageText, { message: messageText });
  }
}

export class NotAllowedError extends FailedResult {
  private constructor(err: any, payload: Record<string, string>) {
    super(err, payload);
  }

  public static create(resource?: string): NotAllowedError {
    const resourceName = resource || 'this resource';
    return new NotAllowedError(`Not allowed to access ${resourceName}`, {
      resourceName,
    });
  }
}

export class UnexpectedError extends FailedResult {
  public constructor(err: Error) {
    super(`An unexpected error occurred: ${err.message}`);
    console.error('Unexpected error', err);
  }

  public static create(err: any): UnexpectedError {
    return new UnexpectedError(err);
  }
}

export class StudentAlreadyHasActiveSubscriptionError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): StudentAlreadyHasActiveSubscriptionError {
    return new StudentAlreadyHasActiveSubscriptionError(
      `Student already has active subscription`,
    );
  }
}

export class StudentSubscriptionAlreadyCancelledError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): StudentSubscriptionAlreadyCancelledError {
    return new StudentSubscriptionAlreadyCancelledError(
      `Student subscription is already cancelled`,
    );
  }
}

export class CannotPayForSingleCourseWhenSubscriptionsAreEnabledError extends FailedResult {
  private constructor(err: any) {
    super(err);
  }

  public static create(): CannotPayForSingleCourseWhenSubscriptionsAreEnabledError {
    return new CannotPayForSingleCourseWhenSubscriptionsAreEnabledError(
      `Should use subscriptions instead of paying for course directly`,
    );
  }
}
