import gql from 'graphql-tag';

import { courseFragment, purchaseSectionFragment } from '../courses/fragments';
import {
  instructorFragment,
  paymentInfoFragment,
  publicUserFragment,
  subscriptionFragment,
  userFragment,
} from './fragments';

export const meQuery = gql`
  query me {
    me {
      ...userFragment
      favoriteCourses {
        id
      }
      instructor {
        ...instructorFragment
      }
    }
  }
  ${userFragment}
  ${instructorFragment}
`;

export const hasActiveSubscription = gql`
  query hasActiveSubscription {
    me {
      id
      hasActiveSubscription
    }
  }
`;

export const meCalendarQuery = gql`
  query meCalendar {
    me {
      id
      shareCalendarUrl
    }
  }
`;

export const myProfileQuery = gql`
  query myProfile {
    me {
      ...userFragment
      ...paymentsInfo
      ...subscription
      instructor {
        ...instructorFragment
      }
    }
  }
  ${userFragment}
  ${instructorFragment}
  ${paymentInfoFragment}
  ${subscriptionFragment}
`;

export const userInfoQuery = gql`
  query userInfo {
    me {
      email
      firstName
      id
      lastName
    }
  }
`;

export const headerInfoQuery = gql`
  query headerInfo {
    me {
      avatarUrl
      firstName
      id
      instructor {
        id
        legalName
      }
    }
  }
`;

export const recentlyViewedCoursesQuery = gql`
  query recentlyViewedCourses {
    me {
      id
      recentlyViewedCourses {
        id
        slug
        title
        coverPhotoUrl
        rating
        reviewsCount
        courseDurationWeeks
        type
        price
        currency
        instructor {
          id
          legalName
        }
        nextSection {
          id
          startDate
        }
      }
    }
  }
`;

export const favoriteCoursesIdsQuery = gql`
  query favoriteCoursesIds {
    me {
      id
      favoriteCourses {
        id
      }
    }
  }
`;

export const favoriteCoursesQuery = gql`
  query favoriteCourses {
    me {
      id
      favoriteCourses {
        ...course
      }
    }
  }
  ${courseFragment}
`;

export const myPurchasesQuery = gql`
  query myPurchases {
    mySchedule {
      courses {
        id
        slug
        sections {
          ...purchaseSection
        }
      }
    }
  }
  ${purchaseSectionFragment}
`;

export const instructorWithdrawalsDetailsQuery = gql`
  query instructorWithdrawalsDetailsQuery {
    me {
      id
      instructor {
        id
        approved
        paymentsInfo {
          id
          detailsFilled
          paymentsEnabled
          systemName
          dashboardUrl
        }
      }
    }
  }
`;

export const userPaymentsInfoQuery = gql`
  query userPaymentsInfo {
    me {
      id
      paymentsInfo {
        id
        paymentMethods {
          id
          brand
          last4
        }
      }
    }
  }
`;

export const publicUserQuery = gql`
  query publicUser($input: PublicUserGqlInput!) {
    publicUser(input: $input) {
      ...publicUserFragment
    }
  }
  ${publicUserFragment}
`;
