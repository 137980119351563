import { InMemoryCache } from '@apollo/client';
import { uniqBy } from 'ramda';

export const getApolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          coursesList: {
            keyArgs: ['input', ['filter', 'search', 'sort']],
            merge(existing, incoming, { readField }) {
              return {
                cursor: incoming?.cursor || existing?.cursor,
                data: uniqBy((val) => readField('id', val), [
                  ...(existing?.data || []),
                  ...(incoming?.data || []),
                ]),
                totalCount: incoming?.totalCount || existing?.totalCount,
              };
            },
          },
        },
      },
    },
  });

export const apolloCache = getApolloCache();
