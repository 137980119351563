import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { FC } from 'react';

import i18n from '../../../../../../infrastructure/i18n';
import { CourseScheduleWeekdays } from '../../../../../types/generated';
import styles from './style.module.less';

interface IDay {
  code: string;
  name: string;
}

export const days: IDay[] = [
  {
    code: CourseScheduleWeekdays.Su,
    name: i18n.t('sun'),
  },
  {
    code: CourseScheduleWeekdays.Mo,
    name: i18n.t('mon'),
  },
  {
    code: CourseScheduleWeekdays.Tu,
    name: i18n.t('tue'),
  },
  {
    code: CourseScheduleWeekdays.We,
    name: i18n.t('wed'),
  },
  {
    code: CourseScheduleWeekdays.Th,
    name: i18n.t('thu'),
  },
  {
    code: CourseScheduleWeekdays.Fr,
    name: i18n.t('fri'),
  },
  {
    code: CourseScheduleWeekdays.Sa,
    name: i18n.t('sat'),
  },
];

type DayPickType = {
  onChange: (e: CheckboxValueType[]) => void;
  value: string[];
};

const checkboxOptions = days.map(({ code, name }) => ({
  label: name,
  value: code,
}));

export const DayPicker: FC<DayPickType> = ({ onChange, value }) => (
  <Checkbox.Group
    className={styles['day-pick']}
    options={checkboxOptions}
    onChange={onChange}
    value={value}
  />
);
