import React, { FC } from 'react';
import { Provider } from 'react-redux';

import { AppStore } from './index';

interface Props {
  store: AppStore;
}

export const ReduxProvider: FC<Props> = ({ children, store }) => (
  <Provider store={store}>{children}</Provider>
);
