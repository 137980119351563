import { UserAction, UserState } from './types';

export const userState: UserState = {
  cookiesAgreement: false,
  id: null,
  token: null,
};

export const userReducer = (
  state: UserState = userState,
  action: UserAction,
) => {
  console.log('action.type:', action.type);
  switch (action.type) {
    case 'RESET_USER':
      return userState;
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_USER_TOKEN':
      return {
        ...state,
        token: null,
      };
    case 'SET_USER_COOKIES':
      return {
        ...state,
        cookiesAgreement: true,
      };
    case 'SET_USER_TOKEN':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
