import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Title } from '../typography';

export const GoBack = () => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div className="mb-24">
      <Button className="p-0" type="link" onClick={() => history.goBack()}>
        <ArrowLeftOutlined className="mr-8" />
        <Title.SM className="inline-block text-primary">
          {t('go_back')}
        </Title.SM>
      </Button>
    </div>
  );
};
