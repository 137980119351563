import { CalendarOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { invertObj } from 'ramda';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CourseScheduleTime } from '../../../../../shared/src/enums';
import { daysMapShort } from '../../../screens/add-course/enums/days';
import { CourseSingleDayScheduleGql } from '../../types/generated';
import { Text } from '../typography';

type CourseTimeType = {
  className?: string;
  data: CourseTimeInfo;
  smallSize?: boolean;
};

export type CourseTimeInfo = {
  scheduleByDay?:
    | Pick<CourseSingleDayScheduleGql, 'day' | 'timeHours' | 'timeMinutes'>[]
    | null;
};

const dayPhaseByTime = invertObj(CourseScheduleTime);

const periodDate = (period: string) => {
  const [hours, minutes] = period.split(':');
  return new Date(Date.UTC(0, 0, 0, Number(hours), Number(minutes)));
};

export const getDayPeriod = ({
  timeHours,
  timeMinutes,
}: {
  timeHours: number;
  timeMinutes: number;
}) => {
  const date = new Date(Date.UTC(0, 0, 0, timeHours, timeMinutes));
  if (
    periodDate(CourseScheduleTime.Morning) < date &&
    date <= periodDate(CourseScheduleTime.Afternoon)
  ) {
    return dayPhaseByTime[CourseScheduleTime.Morning];
  } else if (
    periodDate(CourseScheduleTime.Afternoon) < date &&
    date <= periodDate(CourseScheduleTime.Evening)
  ) {
    return dayPhaseByTime[CourseScheduleTime.Afternoon];
  } else if (
    periodDate(CourseScheduleTime.Evening) < date &&
    date <= periodDate(CourseScheduleTime.Night)
  ) {
    return dayPhaseByTime[CourseScheduleTime.Evening];
  } else {
    return dayPhaseByTime[CourseScheduleTime.Night];
  }
};

const hasDifferentPeriod = (
  scheduleByDay: Pick<
    CourseSingleDayScheduleGql,
    'day' | 'timeHours' | 'timeMinutes'
  >[],
) => {
  let previousPeriod = getDayPeriod(scheduleByDay[0]);
  for (const daySchedule of scheduleByDay) {
    const period = getDayPeriod(daySchedule);
    if (period != previousPeriod) {
      return true;
    }
    previousPeriod = period;
  }
  return false;
};

export const CourseTime: FC<CourseTimeType> = ({
  data: { scheduleByDay },
  className,
  smallSize,
}) => {
  let timeString = '';

  const { t } = useTranslation();

  if (!scheduleByDay) {
    timeString = t('course_has_ended');
  } else if (hasDifferentPeriod(scheduleByDay)) {
    timeString = scheduleByDay
      .map((d) => `${getDayPeriod(d)} - ${d.day}`)
      .join('; ');
  } else {
    const sectionDays = (scheduleByDay || [])
      .map((s) => daysMapShort[s.day])
      .join(', ');
    timeString = `${getDayPeriod(scheduleByDay[0])} - ${sectionDays}`;
  }

  const TimeText = smallSize ? Text.XS : Text.SM;

  return (
    <Row className={classNames(className)} wrap={false}>
      <Col>
        <CalendarOutlined className="mr-12" />
      </Col>
      <Col>
        <TimeText>{timeString}</TimeText>
      </Col>
    </Row>
  );
};
