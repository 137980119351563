import { apolloClient } from '../../infrastructure/graphql';
import { getStore } from '../../infrastructure/redux';
import { resetChat } from '../chat/actions';
import { resetNewCourse } from '../courses/actions';
import { resetUser } from './actions';
import { getUserId, getUserToken } from './selectors';

export const getToken = () => {
  const store = getStore();
  return store ? getUserToken(store.getState()) : null;
};

export const signOut = async () => {
  const store = getStore();
  const userId = getUserId(store?.getState());

  store?.dispatch(resetUser());
  store?.dispatch(resetChat());
  store?.dispatch(resetNewCourse());

  apolloClient.cache.evict({
    broadcast: false,
    fieldName: 'me',
    id: 'ROOT_QUERY',
  });

  apolloClient.cache.evict({
    broadcast: false,
    id: `GqlUser:${userId}`,
  });

  apolloClient.cache.gc();
  // need to wait while redux-persist state gets properly whipped out
  await new Promise((resolve) => {
    setTimeout(resolve, 50);
  });
};
