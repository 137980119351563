import { Button, Col, Empty, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import emptyImg from '../../../assets/img/empty-courses.svg';
import { Text } from '../../typography/index';
import { RequestClassForm } from './components/request-class-form';

interface Props {
  onCourseRequestSent?: () => void;
}

export const EmptyClasses = ({ onCourseRequestSent }: Props) => {
  const [requestClass, setRequestClass] = useState(false);
  const { t } = useTranslation();

  if (requestClass) {
    return <RequestClassForm onCourseRequestSent={onCourseRequestSent} />;
  }

  return (
    <Row justify="center" className="mb-40 mt-40">
      <Col>
        <Empty
          image={emptyImg}
          imageStyle={{
            height: 250,
          }}
          description={
            <Text.LGx3 className="block mt-24 mb-12">
              {t('no_classes_found')}
            </Text.LGx3>
          }
        >
          <Text.MD className="block mb-6">
            {t('try_different_keywords')}
          </Text.MD>
          <Text.MD className="block mb-24">
            {t('if_that_does_not_work_you_can_always_request_a_class')}
          </Text.MD>
          <Button type="primary" onClick={() => setRequestClass(true)}>
            {t('request_a_class')}
          </Button>
        </Empty>
      </Col>
    </Row>
  );
};
