import './share.less';

import { Button, Col, Input, message, Modal, Row } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import React, { FC, useRef } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { Title } from '../../typography';

type ShareModalType = {
  coverImage: string;
  onClose: () => void;
  shareLink: string;
  title: string;
};

export const ShareModal: FC<ShareModalType & ModalProps> = ({
  coverImage,
  onClose,
  shareLink,
  title,
  visible,
}) => {
  const input = useRef<Input>(null);

  const onCopy = () => {
    if (!input || !input.current) {
      return;
    }

    input.current.select();
    document.execCommand('copy');

    onClose();

    message.success(`Successfully copied to clipboard`);
  };

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      title={<Title.MD>{title}</Title.MD>}
      visible={visible}
      width="765px"
    >
      <Row gutter={8}>
        <Col span={24} className="text-left">
          <FacebookShareButton className="mr-16 mt-8" url={shareLink}>
            <FacebookIcon size={64} round />
          </FacebookShareButton>
          <PinterestShareButton
            className="mr-16 mt-8"
            url={shareLink}
            media={coverImage}
          >
            <PinterestIcon size={64} round />
          </PinterestShareButton>
          <RedditShareButton className="mr-16 mt-8" url={shareLink}>
            <RedditIcon size={64} round />
          </RedditShareButton>
          <TelegramShareButton className="mr-16 mt-8" url={shareLink}>
            <TelegramIcon size={64} round />
          </TelegramShareButton>
          <TwitterShareButton className="mr-16 mt-8" url={shareLink}>
            <TwitterIcon size={64} round />
          </TwitterShareButton>
          <WhatsappShareButton className="mr-16 mt-8" url={shareLink}>
            <WhatsappIcon size={64} round />
          </WhatsappShareButton>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col flex="1" className="pr-6 pl-4">
          <Input type="text" readOnly value={shareLink} ref={input} />
        </Col>
        <Col flex="0 0 100px" className="pl-6 pr-4">
          <Button type="primary" onClick={onCopy} className="copy">
            Copy
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
