export const colors = {
  black: '#20253A',
  gray: '#969696',
  lightgray: '#DBDBDC',
  maingray: '#74798C',
  primary: '#09A6F3',
  red: '#F44336',
  secondarygray: '#A0A5BA',
  white: '#ffffff',
  yellow: '#F9C201',
};
