import { message, Typography } from 'antd';
import React from 'react';

interface CreateMessage {
  text: string;
  title: string;
}

const createMessage = ({ text, title }: CreateMessage) => (
  <>
    <Typography.Title level={5}>{title}</Typography.Title>
    <Typography.Text>{text}</Typography.Text>
  </>
);

interface ShowMessage extends CreateMessage {
  duration?: number;
  type: 'info' | 'success' | 'error' | 'warning' | 'loading';
}

export const showMessage = ({
  duration = 2,
  text,
  title,
  type,
}: ShowMessage) => {
  message.open({ content: createMessage({ text, title }), duration, type });
};
