import classNames from 'classnames';
import React, { Component, CSSProperties, FC } from 'react';

import styles from './index.module.less';

type TextType = {
  className?: string;
  style?: CSSProperties;
  suppressHydrationWarning?: boolean;
};

const xs: FC<TextType> = ({
  children,
  className,
  style,
  suppressHydrationWarning,
}) => {
  return (
    <span
      style={style}
      className={classNames(styles['cr-text'], styles['cr-text-xs'], className)}
      suppressHydrationWarning={Boolean(suppressHydrationWarning)}
    >
      {children}
    </span>
  );
};

const sm: FC<TextType> = ({
  children,
  className,
  style,
  suppressHydrationWarning,
}) => {
  return (
    <span
      style={style}
      className={classNames(styles['cr-text'], styles['cr-text-sm'], className)}
      suppressHydrationWarning={Boolean(suppressHydrationWarning)}
    >
      {children}
    </span>
  );
};

const md: FC<TextType> = ({ children, style, className }) => {
  return (
    <span
      style={style}
      className={classNames(styles['cr-text'], styles['cr-text-md'], className)}
    >
      {children}
    </span>
  );
};

const mdx: FC<TextType> = ({ children, style, className }) => {
  return (
    <span
      style={style}
      className={classNames(
        styles['cr-text'],
        styles['cr-text-mdx'],
        className,
      )}
    >
      {children}
    </span>
  );
};

const lg: FC<TextType> = ({ children, style, className }) => {
  return (
    <span
      style={style}
      className={classNames(styles['cr-text'], styles['cr-text-lg'], className)}
    >
      {children}
    </span>
  );
};

const lgx3: FC<TextType> = ({ children, style, className }) => {
  return (
    <span
      style={style}
      className={classNames(
        styles['cr-text'],
        styles['cr-text-lg-3x'],
        className,
      )}
    >
      {children}
    </span>
  );
};

const big: FC<TextType> = ({ children, style, className }) => {
  return (
    <span
      style={style}
      className={classNames(
        styles['cr-text'],
        styles['cr-text-big'],
        className,
      )}
    >
      {children}
    </span>
  );
};

export default class Text extends Component<TextType> {
  static XS = xs;
  static SM = sm;
  static MD = md;
  static MDX = mdx;
  static LG = lg;
  static LGx3 = lgx3;
  static Big = big;

  render() {
    return (
      <Text.LG style={this.props.style} className={this.props.className}>
        {this.props.children}
      </Text.LG>
    );
  }
}
