import './index.less';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CustomArrowProps } from '@ant-design/react-slick';
import { Carousel } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

type CarouselType = {
  className?: string;
  slidesToShow?: number;
};

export const CarouselCustom: FC<CarouselType> = ({
  children,
  className,
  slidesToShow = 4,
}) => {
  const NextArrow = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentSlide,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    slideCount,
    ...props
  }: CustomArrowProps) => <RightOutlined {...props} />;

  const PrevArrow = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentSlide,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    slideCount,
    ...props
  }: CustomArrowProps) => <LeftOutlined {...props} />;

  return (
    <Carousel
      adaptiveHeight={false}
      className={classNames('carousel', className)}
      infinite={false}
      arrows
      speed={300}
      rows={1}
      dots={false}
      slidesToScroll={1}
      slidesToShow={slidesToShow}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      responsive={[
        {
          breakpoint: 978,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ]}
    >
      {children}
    </Carousel>
  );
};
