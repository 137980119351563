import { createTransform } from 'redux-persist';

export const newCourseTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState, key) => {
    if (key === 'newCourse') {
      return {
        // @ts-ignore
        ...outboundState,
        // Max step is 2
        currentStep: Math.min(outboundState.currentStep, 2),
        input: {
          // keep input
          ...outboundState.input,
          // reset upload files
          ...{
            coverPhoto: '',
            images: [],
            vimeoVideoId: '',
          },
        },
      };
    }

    return outboundState;
  },
);
