import './index.less';

import { Collapse as AntCollapse } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

const { Panel } = AntCollapse;

type CollapseType = {
  className: string;
  data: CollapseItemType[];
  pClassName?: string;
};

export type CollapseItemType = {
  id: string;
  text: string;
  title: string;
};

export const Collapse: FC<CollapseType> = ({ className, data, pClassName }) => {
  return (
    <AntCollapse
      className={classNames('cr-collapse', className)}
      expandIconPosition="right"
      ghost
    >
      {data.map(({ id, title, text }) => (
        <Panel className="text-primary" header={title} key={id}>
          <p className={pClassName}>{text}</p>
        </Panel>
      ))}
    </AntCollapse>
  );
};
