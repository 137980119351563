import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { meQuery } from '../../../modules/users/queries';
import { isUserAuthenticated } from '../../../modules/users/selectors';
import { MeQuery, MeQueryVariables } from '../../types/generated';
import { defaultPath, isClient } from '../../utils/common';

export const InstructorRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { data } = useQuery<MeQuery, MeQueryVariables>(meQuery, {
    fetchPolicy: 'cache-only',
  });
  const isInstructor = Boolean(data?.me?.instructor);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const shouldRedirect = isClient() && !isAuthenticated && !isInstructor;

  return Component ? (
    <Route
      {...rest}
      render={(props) =>
        shouldRedirect ? (
          <Redirect
            to={{
              pathname: defaultPath,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  ) : null;
};
