import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import * as Yup from 'yup';

import { useFormik } from '../../../../../../infrastructure/formik';
import { createCourseRequestMutation } from '../../../../../../modules/users/mutations';
import { useFocusedField } from '../../../../../hooks/use-focused-field';
import { showMessage } from '../../../../../services/show-message';
import {
  CourseCategory,
  CreateCourseRequestMutation,
  CreateCourseRequestMutationVariables,
} from '../../../../../types/generated';
import { maybeCall } from '../../../../../utils/common';
import { LabelField } from '../../../../label-field';
import { SelectCategory } from '../../../../select/category';
import { Title } from '../../../../typography';

const requestClassSchema = Yup.object().shape({
  category: Yup.string().required(),
  description: Yup.string().required(),
  email: Yup.string().required(),
});

const { TextArea } = Input;

interface Props {
  onCourseRequestSent?: () => void;
}

export const RequestClassForm = ({ onCourseRequestSent }: Props) => {
  const [createCourseRequest, { loading }] = useMutation<
    CreateCourseRequestMutation,
    CreateCourseRequestMutationVariables
  >(createCourseRequestMutation);

  const { isFocusedField, onBlurField, onFocusField } = useFocusedField();

  const formik = useFormik({
    initialValues: {
      category: '',
      description: '',
      email: '',
    },
    onSubmit: async (values) => {
      const { data } = await createCourseRequest({
        variables: {
          input: {
            category: values.category,
            description: values.description,
            requesterEmail: values.email,
          },
        },
      });

      if (data?.createCourseRequest.courseRequest) {
        showMessage({
          text: 'Course request sent',
          title: 'Success',
          type: 'success',
        });

        maybeCall(onCourseRequestSent);
      }
    },
    validationSchema: requestClassSchema,
  });

  return (
    <>
      <Form
        style={{ margin: '0 auto', maxWidth: 320 }}
        name="request-class"
        onFinish={formik.handleSubmit}
      >
        <Title.BigX className="text-center mb-32">Request a Class</Title.BigX>
        <Row>
          <Col span={24}>
            <Form.Item
              help={formik.errors.category}
              validateStatus={formik.getFieldStatus('category')}
            >
              <SelectCategory
                // @ts-ignore
                onChange={formik.handleSelectChange('category')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <LabelField
              placeholder="Class description"
              focused={isFocusedField('description')}
              active={!!formik.values.description}
              size="large"
            >
              <Form.Item
                help={formik.errors.description}
                validateStatus={formik.getFieldStatus('description')}
              >
                <TextArea
                  onBlur={onBlurField()}
                  onFocus={onFocusField('description')}
                  onChange={formik.handleFieldChange('description')}
                  autoSize={{ maxRows: 8, minRows: 3 }}
                  size="large"
                />
              </Form.Item>
            </LabelField>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <LabelField
              placeholder="Email"
              focused={isFocusedField('email')}
              active={!!formik.values.email}
              size="large"
            >
              <Form.Item
                help={formik.errors.email}
                validateStatus={formik.getFieldStatus('email')}
              >
                <Input
                  onBlur={onBlurField()}
                  onFocus={onFocusField('email')}
                  onChange={formik.handleFieldChange('email')}
                  type="text"
                  value={formik.values.email}
                  size="large"
                />
              </Form.Item>
            </LabelField>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button loading={loading} htmlType="submit" block type="primary">
              Request
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
