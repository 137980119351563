import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { maybeCall } from '../../utils/common';
import styles from './index.module.less';

interface IntersectionLoaderProps {
  deps?: any[];
  isEndReached?: boolean;
  isLoading?: boolean;
  onLoad: () => void;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const IntersectionLoader = ({
  deps = [],
  isEndReached,
  isLoading,
  onLoad,
}: IntersectionLoaderProps) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isEndReached && !isLoading) {
      maybeCall(onLoad);
    }
  }, [inView, isEndReached, isLoading, ...deps]);

  return (
    <div ref={ref} className={styles['intersection-loader-container']}>
      {isLoading ? <Spin indicator={loadingIcon} /> : null}
    </div>
  );
};
