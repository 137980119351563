import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/img/logo-full.svg';

type LogoType = {
  sm?: boolean;
};
export const Logo: FC<LogoType> = ({ sm = false }) => {
  const width = sm ? '140px' : '160px';

  return (
    <Link to="/">
      <img style={{ maxWidth: width, width: '100%' }} src={logo} alt="logo" />
    </Link>
  );
};
