import { HeartOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsAuthorized } from '../../../../../modules/users/hooks';
import {
  addFavoriteCourseMutation,
  removeFavoriteCourseMutation,
} from '../../../../../modules/users/mutations';
import { favoriteCoursesIdsQuery } from '../../../../../modules/users/queries';
import {
  AddFavoriteCourseMutation,
  AddFavoriteCourseMutationVariables,
  FavoriteCoursesIdsQuery,
  RemoveFavoriteCourseMutation,
  RemoveFavoriteCourseMutationVariables,
} from '../../../../types/generated';
import styles from './index.module.less';

interface FavoriteButtonProps {
  buttonType?: 'badge' | 'button';
  courseId: string;
  favoriteCoursesQuery: FavoriteCoursesIdsQuery;
}

const FavoriteButton: FC<FavoriteButtonProps> = ({
  buttonType = 'button',
  courseId,
  favoriteCoursesQuery,
}) => {
  const [
    addFavoriteCourse,
    { loading: addFavoriteCourseLoading },
  ] = useMutation<
    AddFavoriteCourseMutation,
    AddFavoriteCourseMutationVariables
  >(addFavoriteCourseMutation, { variables: { input: { courseId } } });

  const [
    removeFavoriteCourse,
    { loading: removeFavoriteCourseLoading },
  ] = useMutation<
    RemoveFavoriteCourseMutation,
    RemoveFavoriteCourseMutationVariables
  >(removeFavoriteCourseMutation, { variables: { input: { courseId } } });

  const isFavorite = useMemo(
    () =>
      !!(favoriteCoursesQuery.me?.favoriteCourses || []).find(
        (course) => course.id === courseId,
      ),
    [favoriteCoursesQuery],
  );

  const buttonCommonProps = useMemo(
    () => ({
      icon: <HeartOutlined />,
      loading: addFavoriteCourseLoading || removeFavoriteCourseLoading,
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        (isFavorite ? removeFavoriteCourse : addFavoriteCourse)();
      },
    }),
    [
      addFavoriteCourse,
      addFavoriteCourseLoading,
      isFavorite,
      removeFavoriteCourse,
      removeFavoriteCourseLoading,
    ],
  );

  const { t } = useTranslation();

  return buttonType === 'badge' ? (
    <Button
      {...buttonCommonProps}
      className={classNames('ant-btn-card-bagde', {
        [styles['favorite-course-button']]: isFavorite,
      })}
      shape="round"
      type="primary"
    >
      {t(isFavorite ? 'saved' : 'save')}
    </Button>
  ) : (
    <Button
      {...buttonCommonProps}
      className="mr-16"
      ghost={!isFavorite}
      size="small"
      type="primary"
    >
      {t(isFavorite ? 'added_to_favorites' : 'add_to_favorites')}
    </Button>
  );
};

export type FavoriteCourseButtonProps = Omit<
  FavoriteButtonProps,
  'favoriteCoursesQuery'
>;

export const FavoriteCourseButton: FC<FavoriteCourseButtonProps> = (props) => {
  const { isAuthorized } = useIsAuthorized();
  const { data } = useQuery<FavoriteCoursesIdsQuery>(favoriteCoursesIdsQuery, {
    fetchPolicy: 'cache-only',
  });

  return isAuthorized && data ? (
    <FavoriteButton {...props} favoriteCoursesQuery={data} />
  ) : null;
};
