import gql from 'graphql-tag';

import {
  instructorFragment,
  paymentInfoFragment,
  userFragment,
} from './fragments';

export const createUserMutation = gql`
  mutation createUser($input: GqlCreateUserInput!) {
    createUser(input: $input) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`;

export const updateUserMutation = gql`
  mutation updateUser($input: GqlUpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`;

export const removePaymentMethodMutation = gql`
  mutation removePaymentMethod($input: GqlRemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      user {
        id
        ...paymentsInfo
      }
    }
  }
  ${paymentInfoFragment}
`;

export const signInMutation = gql`
  mutation signIn($input: GqlSignInInput!) {
    signIn(input: $input) {
      token
      user {
        ...userFragment
        instructor {
          ...instructorFragment
        }
      }
    }
  }
  ${userFragment}
  ${instructorFragment}
`;

export const createInstructorMutation = gql`
  mutation createInstructor($input: GqlCreateInstructorInput!) {
    createInstructor(input: $input) {
      user {
        ...userFragment
        instructor {
          ...instructorFragment
        }
      }
    }
  }
  ${instructorFragment}
  ${userFragment}
`;

export const forgotUserPasswordMutation = gql`
  mutation forgotUserPassword($input: GqlForgotUserPasswordInput!) {
    forgotUserPassword(input: $input) {
      ok
    }
  }
`;

export const resetUserPasswordMutation = gql`
  mutation resetUserPassword($input: GqlResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${userFragment}
`;

export const createInstructorReviewMutation = gql`
  mutation createInstructorReview($input: CreateInstructorReviewGqlInput!) {
    createInstructorReview(input: $input) {
      review {
        id
        content
        rate
        instructor {
          id
          rating
          reviewsCount
          reviews {
            content
            id
            rate
            createdAt
            author {
              id
              avatarUrl
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const addFavoriteCourseMutation = gql`
  mutation addFavoriteCourse($input: AddFavoriteCourseGqlInput!) {
    addFavoriteCourse(input: $input) {
      user {
        id
        favoriteCourses {
          id
          title
        }
      }
    }
  }
`;

export const removeFavoriteCourseMutation = gql`
  mutation removeFavoriteCourse($input: RemoveFavoriteCourseGqlInput!) {
    removeFavoriteCourse(input: $input) {
      user {
        id
        favoriteCourses {
          id
        }
      }
    }
  }
`;

export const getVideoUploadUrl = gql`
  mutation getVideoUploadUrl($size: Int!) {
    videoUploadUrl(size: $size) {
      url
      id
    }
  }
`;

export const createCourseRequestMutation = gql`
  mutation createCourseRequest($input: CreateCourseRequestGqlInput!) {
    createCourseRequest(input: $input) {
      courseRequest {
        category
        description
        id
        requesterEmail
      }
    }
  }
`;

export const cancelSubscriptionMutation = gql`
  mutation cancelSubscription {
    cancelSubscription {
      success
    }
  }
`;
