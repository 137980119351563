/* eslint-disable sort-keys-fix/sort-keys-fix */
import './footer.less';

import { useQuery } from '@apollo/react-hooks';
import { Button, Col, Row } from 'antd';
import { TFunctionResult } from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { Language } from '../../../../../shared/src/enums';
import { linksListQuery } from '../../../modules/link/queries';
import { headerInfoQuery } from '../../../modules/users/queries';
import { isUserAuthenticated } from '../../../modules/users/selectors';
import Facebook from '../../assets/img/facebook.svg';
import Instagram from '../../assets/img/instagram.svg';
import Master from '../../assets/img/master.svg';
import Pinterest from '../../assets/img/pinterest.svg';
import Twitter from '../../assets/img/twitter.svg';
import VisaWhite from '../../assets/img/visa-white.svg';
import Youtube from '../../assets/img/youtube.svg';
import {
  HeaderInfoQuery,
  HeaderInfoQueryVariables,
  LinksListQuery,
  LinksListQueryVariables,
} from '../../types/generated';
import { Logo } from '../logo';
import { Text, Title } from '../typography';

const defaultXsSmMd = {
  xs: 24,
  sm: 24,
  md: 12,
};

const defaultSizes = {
  ...defaultXsSmMd,
  lg: 5,
  xl: 5,
  xxl: 5,
};

type SocialLink = {
  slug: string;
  src: string;
  to: string;
};

const socialMedia = [
  {
    to: '/',
    slug: 'facebook',
    src: Facebook,
  },
  {
    to: '/',
    slug: 'instagram',
    src: Instagram,
  },
  {
    to: '/',
    slug: 'youtube',
    src: Youtube,
  },
  {
    to: '/',
    slug: 'pinterest',
    src: Pinterest,
  },
  {
    to: '/',
    slug: 'twitter',
    src: Twitter,
  },
];

export const Footer: FC = () => {
  const isAuthenticated = useSelector(isUserAuthenticated);

  const { data: userData } = useQuery<
    HeaderInfoQuery,
    HeaderInfoQueryVariables
  >(headerInfoQuery, {
    fetchPolicy: 'cache-first',
    skip: !isAuthenticated,
  });

  const { instructor } = userData?.me || {};

  const year = new Date().getFullYear();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: string) => i18n.changeLanguage(lang);

  const [socialLinks, setSocialLinks] = useState<SocialLink[]>([]);

  const { data: linksData } = useQuery<LinksListQuery, LinksListQueryVariables>(
    linksListQuery,
  );

  const openChaport = () => {
    if (typeof window === 'undefined') {
      return;
    }

    // @ts-ignore Property 'chaport' does not exist on type 'Window & typeof globalThis'
    window.chaport?.open();
  };

  interface IHyperLinkChild {
    name: string;
    onClick?: () => void;
    to: string;
  }
  interface IHyperLink {
    links: IHyperLinkChild[];
    title: TFunctionResult | string | boolean;
  }

  const getHyperlinks: (e: boolean) => IHyperLink[] = (isInstructor) => [
    {
      title: false,
      links: [
        { name: t('support'), to: '#', onClick: openChaport },
        { name: t('faq'), to: '/faq' },
      ],
    },
    {
      title: t('for_learners'),
      links: [
        { name: t('learner_schedule'), to: '/schedule' },
        { name: t('find_courses'), to: '/' },
        { name: t('favorites'), to: '/favorites' },
        { name: t('my_courses'), to: '/my-purchases' },
        { name: t('chat'), to: '/chat' },
      ],
    },
    {
      title: t('for_instructors'),
      links: [
        isInstructor
          ? {
              name: t('instructor_hub'),
              to: '/instructor-hub',
            }
          : { name: t('teach_on_classrool'), to: '/instructor-application' },
      ],
    },
  ];

  useEffect(() => {
    const links = linksData?.linksList || [];

    if (links.length > 0) {
      const socLinks = socialMedia.reduce((acc, val) => {
        const link = links.find((l) => l.slug === val.slug);

        return link ? [...acc, { ...val, to: link.url }] : acc;
      }, [] as SocialLink[]);

      setSocialLinks(socLinks);
    }
  }, [linksData]);

  return (
    <div className="footer">
      <div className="container">
        <Row justify="space-between">
          <Col className="logo-container" {...defaultSizes} md={24}>
            <Logo sm />
          </Col>
          {getHyperlinks(!!instructor).map(({ title, links }, index) => (
            <Col
              key={`${title}${index}`}
              className="hyperlinks"
              {...defaultSizes}
            >
              {title && (
                <Title.MD className="group-title pb-24">{title}</Title.MD>
              )}
              {links.map(({ name, to, onClick }, i) => (
                <Text.SM key={`${name}${i}`} className="block pb-16">
                  <Link
                    onClick={onClick}
                    className="text-gray-main text-opacity-80"
                    to={to}
                  >
                    {name}
                  </Link>
                </Text.SM>
              ))}
            </Col>
          ))}
          <Col className="hyperlinks" {...defaultXsSmMd} lg={4} xl={4} xxl={4}>
            <Title.MD className="group-title pb-8">
              {t('payment_methods')}
            </Title.MD>
            <Row className="bank-cards text-gray-secondary pt-16">
              <Col>
                <ReactSVG src={VisaWhite} />
              </Col>
              <Col className="master-card-container ml-20">
                <ReactSVG src={Master} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-24">
          {socialLinks.map(({ to, src }, index) => (
            <a
              className="social-media"
              href={to}
              key={`${to}${index}`}
              target="_blank"
            >
              <ReactSVG className="mr-20 sm-image" src={src} />
            </a>
          ))}
        </Row>

        <Row>
          <Col>
            <Button type="link" onClick={() => changeLanguage(Language.pl)}>
              Polish
            </Button>
          </Col>
          <Col>
            <Button type="link" onClick={() => changeLanguage(Language.en)}>
              English
            </Button>
          </Col>
          <Col>
            <Button type="link" onClick={() => changeLanguage(Language.uk)}>
              Ukrainian
            </Button>
          </Col>
        </Row>

        <Row className="pt-32">
          <Col {...defaultXsSmMd} lg={9} xl={7} xxl={5}>
            <Text.SM
              className="block text-gray-main text-opacity-60"
              suppressHydrationWarning
            >
              Copyright © {year}. Classrool.
            </Text.SM>
          </Col>
          <Col xs={24} sm={24} md={0} lg={6} xl={10} xxl={14} />
          <Col {...defaultXsSmMd} lg={4} xl={3} xxl={2}>
            <Text.SM className="block">
              <Link
                className="text-gray-main text-opacity-60"
                to="/privacy-policy"
              >
                {t('privacy_policy')}
              </Link>
            </Text.SM>
          </Col>
          <Col xs={0} sm={0} md={12} lg={0} xl={0} xxl={0} />
          <Col {...defaultXsSmMd} lg={5} xl={4} xxl={3}>
            <Text.SM className="block">
              <Link
                className="text-gray-main text-opacity-60"
                to="/terms-and-conditions"
              >
                {t('terms_and_conditions')}
              </Link>
            </Text.SM>
          </Col>
        </Row>
      </div>
    </div>
  );
};
