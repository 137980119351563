import {
  CourseCategory,
  CourseCurrency,
  CourseType,
} from '../../shared/types/generated';
import { CourseAction, CourseState } from './types';

export interface ICurrentStep {
  currentStep: number;
}

export const courseState: CourseState & ICurrentStep = {
  currentStep: 1,
  input: {
    additionalMaterials: '',
    category: CourseCategory.GeneralEnglish,
    classDurationMinutes: 40,
    courseDurationWeeks: 6,
    coverPhoto: '',
    currency: CourseCurrency.Usd,
    description: '',
    images: [],
    maxStudents: 8,
    maxStudentsAge: null,
    minStudents: 4,
    minStudentsAge: null,
    price: 0,
    sections: [],
    summary: '',
    supplies: '',
    title: '',
    type: CourseType.BeginnerToPreIntermediate,
    tzid: '',
    vimeoVideoId: '126695958',
    welcomeMessage: '',
  },
};

export const courseReducer = (
  state: CourseState & ICurrentStep = courseState,
  action: CourseAction,
) => {
  switch (action.type) {
    case 'RESET_NEW_COURSE':
      return courseState;
    case 'UPDATE_COURSE_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'UPDATE_NEW_COURSE':
      return {
        ...state,
        input: {
          ...state.input,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
