import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { isUserAuthenticated } from '../../../modules/users/selectors';
import { isClient } from '../../utils/common';

export const PrivateRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const shouldRedirect = isClient() && !isAuthenticated;
  return Component ? (
    <Route
      {...rest}
      render={(props) =>
        shouldRedirect ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  ) : null;
};
