import { CarryOutOutlined, HistoryOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CourseCurrency } from '../../../types/generated';
import { PriceFormat } from '../../price-format/';
import { RateRead } from '../../rate';
import { Text, Title } from '../../typography';
import CardClasses from './index.module.less';

export type CourseData = {
  coverPhotoUrl: string;
  currency?: CourseCurrency | null;
  instructor: InstructorInfo;
  nextSection?: NextSectionStartDateInfo | null;
  price?: number | null;
  rating: number;
  reviewsCount: number;
  slug: string;
  title: string;
  type: string;
};

type NextSectionStartDateInfo = {
  startDate: Date;
};

type InstructorInfo = {
  legalName: string;
};
export const CourseCard: FC<CourseData> = ({
  coverPhotoUrl,
  title,
  reviewsCount,
  rating,
  type,
  instructor,
  slug,
  nextSection,
  price,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <Link to={`/course/${slug}`}>
      <Card
        bordered={false}
        className={CardClasses.card}
        cover={
          <img
            width="100"
            className={CardClasses['card-img']}
            src={coverPhotoUrl}
            alt={`${title} Cover Photo`}
          />
        }
      >
        <div className={CardClasses['card-author']}>
          <Text.XS className="text-gray-main">{instructor.legalName}</Text.XS>
        </div>
        <div className={CardClasses['ant-card-body-main']}>
          <Title.SM className="mb-8 ant-typography-ellipsis-multiple-line">
            {title}
          </Title.SM>

          <RateRead reviews={reviewsCount} rating={rating} className="mb-12" />
        </div>

        <Row justify="end" className="mb-12">
          <Col>
            <Text.XS>
              <HistoryOutlined className="mr-6" />
              {capitalCase(type)}
            </Text.XS>
          </Col>
        </Row>
        <Row justify="end" className="mb-12">
          <Col>
            <Text.XS>
              <CarryOutOutlined className="mr-6" />
              {nextSection
                ? format(new Date(nextSection.startDate), 'E, d MMM / hh:mm a')
                : t('course_has_ended')}
            </Text.XS>
          </Col>
        </Row>

        <Divider style={{ margin: '10px 0' }} />

        {price && currency && (
          <Row justify="space-between" align="bottom">
            <Col>
              <Text.SM className="text-gray-main">
                {t('price_per_course')}:
              </Text.SM>
            </Col>

            <Col>
              <Text.Big>
                <PriceFormat price={price} currency={currency} />
              </Text.Big>
            </Col>
          </Row>
        )}
      </Card>
    </Link>
  );
};
