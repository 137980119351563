import classnames from 'classnames';
import React, { FC } from 'react';

import classes from './index.module.less';

type labelFieldProps = {
  active: boolean;
  focused: boolean;
  placeholder: string;
  size?: 'small' | 'middle' | 'large';
};

export const LabelField: FC<labelFieldProps> = ({
  placeholder,
  focused,
  active,
  children,
  size = 'middle',
}) => {
  return (
    <div
      className={classnames(classes['label-field'], {
        [classes['label-field-focused']]: active || focused,
      })}
    >
      <span
        className={classnames(
          classes['label-field-placeholder'],
          classes[`label-field-placeholder-${size}`],
        )}
      >
        {placeholder}
      </span>
      {children}
    </div>
  );
};
