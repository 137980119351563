import { useQuery } from '@apollo/react-hooks';
import { Col, Input, Row } from 'antd';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { coursesListQuery } from '../../modules/courses/queries';
import { CourseCardFull } from '../../shared/components/card/course/full';
import { EmptyClasses } from '../../shared/components/empty/class';
import { CategoryFilter, TypeFilter } from '../../shared/components/filters';
import { AvailabilityFilter } from '../../shared/components/filters/availability';
import { IntersectionLoader } from '../../shared/components/IntersectionLoader';
import { Layout } from '../../shared/components/layout';
import { SimpleLoader } from '../../shared/components/loader';
import { SelectSort } from '../../shared/components/select/sorting';
import { Text, Title } from '../../shared/components/typography';
import { categories } from '../../shared/enums/categories';
import {
  CourseCategoryFilter,
  CourseListOrderBy,
  CourseScheduleFrequency,
  CourseScheduleTimeGql,
  CourseScheduleWeekdays,
  CoursesListQuery,
  CoursesListQueryVariables,
  CourseType,
  GetCoursesListGqlFilterInput,
  SortDirection,
} from '../../shared/types/generated';
import { getTzid, inUnits } from '../../shared/utils/common';
import {
  COURSES_PER_REQUEST,
  coursesListQueryInitialInput,
  INITIAL_COURSE_LOAD,
  seo,
  sortOptions,
} from './constants';
import styles from './home.module.less';

const { Search } = Input;

const getAfter = (data?: CoursesListQuery) => data?.coursesList?.cursor?.after;

export const FindCourses = () => {
  const [availabilityWeekDay, setAvailabilityWeekDay] = useState<string[]>([]);
  const [availabilityDayPhase, setAvailabilityDayPhase] = useState('');
  const [type, setType] = useState('');
  const [frequency, setFrequency] = useState('');
  const [category, setCategory] = useState('');
  const [studentsAge, setStudentsAge] = useState<{
    max: number;
    min: number;
  } | null>(null);
  const [sort, setSort] = useState(coursesListQueryInitialInput?.sort?.orderBy);
  const [filter, setFilter] = useState(coursesListQueryInitialInput?.filter);
  const [sortQuery, setSortQuery] = useState({
    direction: coursesListQueryInitialInput?.sort?.direction,
    orderBy: sort,
  });
  const tzid = getTzid();

  useEffect(() => {
    const filterParams: GetCoursesListGqlFilterInput = {
      ...(availabilityWeekDay.length
        ? { byWeekDay: availabilityWeekDay as CourseScheduleWeekdays[] }
        : {}),
      ...(availabilityDayPhase
        ? { time: availabilityDayPhase as CourseScheduleTimeGql }
        : {}),
      ...(category ? { category: category as CourseCategoryFilter } : {}),
      ...(studentsAge ? { studentsAge } : {}),
      ...(type ? { type: type as CourseType } : {}),
      ...(frequency ? { frequency: frequency as CourseScheduleFrequency } : {}),
    };
    if (!isEmpty(filterParams)) {
      filterParams.tzid = tzid;
    }

    setFilter(filterParams);
  }, [
    availabilityDayPhase,
    availabilityWeekDay,
    category,
    frequency,
    studentsAge,
    type,
    tzid,
  ]);

  useEffect(() => {
    if (sort === CourseListOrderBy.CreatedAt) {
      setSortQuery({
        direction: SortDirection.Desc,
        orderBy: sort,
      });
    } else {
      setSortQuery({
        direction: SortDirection.Asc,
        orderBy: sort,
      });
    }
  }, [sort]);

  const { t } = useTranslation();

  const [search, setSearch] = useState<string | undefined>();
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, fetchMore, loading, refetch } = useQuery<
    CoursesListQuery,
    CoursesListQueryVariables
  >(coursesListQuery, {
    variables: {
      input: {
        filter,
        pagination: { first: INITIAL_COURSE_LOAD },
        sort: sortQuery,
      },
    },
  });

  const classes = data?.coursesList.data || [];
  const classesTotalCount = data?.coursesList.totalCount || 0;

  const onSearch = async (val?: string) => {
    setSearch(val);
    setSearching(true);

    await refetch({
      input: {
        filter,
        pagination: { first: INITIAL_COURSE_LOAD },
        search: val,
        sort: sortQuery,
      },
    });

    setSearching(false);
    setSearched(!!val);
  };

  useEffect(() => {
    if (!isEmpty(filter)) {
      refetch({
        input: {
          filter,
          pagination: { first: INITIAL_COURSE_LOAD },
          search,
          sort: sortQuery,
        },
      });
    }
  }, [filter, sortQuery, search]);

  const onFetchMoreCourses = async () => {
    const afterCursor = getAfter(data);

    if (afterCursor) {
      setFetchingMore(true);

      await fetchMore({
        variables: {
          input: {
            filter,
            pagination: {
              after: afterCursor,
              first: COURSES_PER_REQUEST,
            },
            search,
            sort: sortQuery,
          },
        },
      });

      setFetchingMore(false);
    }
  };

  const isLoading = loading || fetchingMore;
  const isInitialLoading = !classes.length && isLoading;

  return (
    <Layout>
      <Helmet>
        <meta content={seo.description} name="description" />
        <meta content={seo.keywords} name="keywords" />
      </Helmet>
      <div className={styles['home']}>
        <div className={styles['home-top']}>
          <div className="container">
            <CategoryFilter
              categories={categories}
              className="mb-16"
              onChange={(e) => setCategory(e)}
            />

            <Row
              className={styles['home-filters']}
              align="middle"
              justify="space-between"
            >
              <Col xs={24} lg={10} className={styles['home-filters-main']}>
                <Row>
                  <Col xs={24} lg={12} sm={12}>
                    <AvailabilityFilter
                      dayPhaseValue={availabilityDayPhase}
                      onWeekDayChange={setAvailabilityWeekDay}
                      onDayPhaseChange={setAvailabilityDayPhase}
                      weekDayValue={availabilityWeekDay}
                    />
                  </Col>
                  <Col xs={24} lg={12} sm={12}>
                    <TypeFilter value={type} onChange={setType} />
                  </Col>
                  {/*<Col xs={24} sm={6}>*/}
                  {/*  <FrequencyFilter*/}
                  {/*    value={frequency}*/}
                  {/*    onChange={setFrequency}*/}
                  {/*  />*/}
                  {/*</Col>*/}
                </Row>
              </Col>
              <Col xs={24} lg={10}>
                <Search
                  allowClear
                  className={styles['home-filters-search']}
                  enterButton={t('search')}
                  loading={searching}
                  onChange={(e) => setSearch(e.target.value)}
                  onSearch={onSearch}
                  placeholder={t('what_you_are_looking_for')}
                  value={search}
                  size="middle"
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="container">
          <Row className="mb-16" justify="space-between" align="middle">
            <Col xs={24} lg={12}>
              <Title.SM>{inUnits(classesTotalCount, 'result')}</Title.SM>
            </Col>
            <Col xs={24} lg={12} className="text-right">
              <Text.SM className="mr-8">{t('sort_by')}:</Text.SM>
              <SelectSort
                defaultValue={sort as string}
                options={sortOptions}
                onChange={(e) => setSort(e.toString() as CourseListOrderBy)}
              />
            </Col>
          </Row>
          {isInitialLoading ? (
            <div className="container">
              <Row justify="center" className="mb-40 mt-40" key="some key">
                <Col>
                  <SimpleLoader size="large" />
                </Col>
              </Row>
            </div>
          ) : classes.length ? (
            <Row gutter={[16, 16]}>
              {classes.map((course) => {
                return (
                  <Col
                    key={course.id}
                    lg={8}
                    md={12}
                    sm={12}
                    xl={6}
                    xs={24}
                    xxl={6}
                  >
                    <CourseCardFull course={course} />
                  </Col>
                );
              })}
              <Col
                lg={8}
                md={12}
                sm={12}
                xl={6}
                xs={24}
                xxl={6}
                style={{ minHeight: 10 }}
              >
                <IntersectionLoader
                  deps={[searched]}
                  isEndReached={!getAfter(data)}
                  isLoading={isLoading}
                  onLoad={onFetchMoreCourses}
                />
              </Col>
            </Row>
          ) : (
            <EmptyClasses
              onCourseRequestSent={() => {
                setFilter({});
                setSearch('');
                refetch({
                  input: { filter: {}, search: '' },
                });
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
