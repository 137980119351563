import { purgeStoredState } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import { newCourseTransform } from '../../modules/courses/transforms';

export const REDUX_PERSIST_VERSION = 1;

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    removeItem() {
      return Promise.resolve();
    },
    setItem(_: any, value: any) {
      return Promise.resolve(value);
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

export const persistConfig = {
  key: 'root',
  storage,
  timeout: 100,
  transforms: [newCourseTransform],
  version: REDUX_PERSIST_VERSION,
};

export const purgeReduxStoredState = () => purgeStoredState(persistConfig);
