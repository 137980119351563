import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Rate, Row } from 'antd';
import { format } from 'date-fns';
import React, { FC } from 'react';

import { colors } from '../../colors';
import { Text, Title } from '../../typography';

export const Review: FC<ReviewType> = ({
  author: { avatarUrl, firstName, lastName },
  content,
  createdAt,
  rate,
}) => {
  return (
    <Row justify="space-between" className="mb-40">
      <Col flex="0 0 60px">
        <Avatar size={40} src={avatarUrl} icon={<UserOutlined />} />
      </Col>
      <Col flex="1">
        <Row justify="space-between" align="middle" className="mb-4">
          <Col>
            <Title.SM className="mb-0">
              {firstName} {lastName}
            </Title.SM>
          </Col>
          <Col>
            <Row align="bottom" gutter={8}>
              <Col>
                <Text.XS>{rate}/5</Text.XS>
              </Col>
              <Col>
                <Rate
                  style={{ color: colors.yellow, fontSize: 12 }}
                  allowHalf
                  disabled
                  defaultValue={4}
                  value={rate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-8">
          <Col>
            <Text.XS className="text-gray-main">
              {createdAt ? format(new Date(createdAt), 'MMMM d, y') : ''}
            </Text.XS>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text.SM className="new-line-text">{content}</Text.SM>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export type ReviewType = {
  author: {
    avatarUrl: string;
    firstName: string;
    lastName: string;
  };
  content: string;
  createdAt: string;
  id: string;
  rate: number;
};
