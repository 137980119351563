import React, { FC } from 'react';

import { Footer } from '../footer';
import { Header } from '../header';

export const Layout: FC = ({ children }) => {
  return (
    <>
      <Header />
      <div className="cr-layout-content">{children}</div>
      <Footer />
    </>
  );
};
