import gql from 'graphql-tag';

export const documentQuery = gql`
  query document($input: GetDocumentGqlInput!) {
    document(input: $input) {
      id
      slug
      text
    }
  }
`;
