import {
  ResetChatAction,
  SaveMessageAction,
  SetChannelMembersAction,
  SetChannelMetaAction,
  SetCurrentChannelAction,
  SetOnlineMembersAction,
  StartFetchingLastMessages,
  StopFetchingLastMessages,
} from './types';

export const resetChat = (): ResetChatAction => ({
  type: 'RESET_CHAT',
});

export const saveMessage = (
  payload: SaveMessageAction['payload'],
): SaveMessageAction => ({
  payload,
  type: 'SAVE_MESSAGE',
});

export const setCurrentChannel = (
  payload: SetCurrentChannelAction['payload'],
): SetCurrentChannelAction => ({
  payload,
  type: 'SET_CURRENT_CHANNEL',
});

export const setChannelMembers = (
  payload: SetChannelMembersAction['payload'],
): SetChannelMembersAction => ({
  payload,
  type: 'SET_CHANNEL_MEMBERS',
});

export const setChannelMeta = (
  payload: SetChannelMetaAction['payload'],
): SetChannelMetaAction => ({
  payload,
  type: 'SET_CHANNEL_META',
});

export const setOnlineMembers = (
  payload: SetOnlineMembersAction['payload'],
): SetOnlineMembersAction => ({
  payload,
  type: 'SET_ONLINE_MEMBERS',
});

export const startFetchingLastMessages = (): StartFetchingLastMessages => ({
  type: 'START_FETCHING_LAST_MESSAGES',
});

export const stopFetchingLastMessages = (): StopFetchingLastMessages => ({
  type: 'STOP_FETCHING_LAST_MESSAGES',
});
