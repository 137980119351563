import { useMutation } from '@apollo/react-hooks';
import { Button, Col, Form, Input, Modal, Rate, Row } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFormik } from '../../../../infrastructure/formik';
import { createCourseReviewMutation } from '../../../../modules/courses/mutations';
import { createInstructorReviewMutation } from '../../../../modules/users/mutations';
import { useFocusedField } from '../../../hooks/use-focused-field';
import {
  CreateCourseReviewMutation,
  CreateCourseReviewMutationVariables,
  CreateInstructorReviewMutation,
  CreateInstructorReviewMutationVariables,
} from '../../../types/generated';
import { LabelField } from '../../label-field';
import { Text, Title } from '../../typography';
import { ReviewTitle, ReviewType } from './enum';

type ReviewModalType = {
  onCancel: () => void;
  onOk: () => void;
  subject?: ReactNode;
  type: string;
};

export const ReviewModal: FC<ReviewModalType & ModalProps> = ({
  type = ReviewType.teacher,
  onOk,
  onCancel,
  visible,
  subject,
}) => {
  const { title, subtitle } = ReviewTitle[type];

  const { isFocusedField, onBlurField, onFocusField } = useFocusedField();

  const [
    createCourseReview,
    { data: courseReviewData, loading: courseReviewLoading },
  ] = useMutation<
    CreateCourseReviewMutation,
    CreateCourseReviewMutationVariables
  >(createCourseReviewMutation);

  const [
    createInstructorReview,
    { data: instructorReviewData, loading: instructorReviewLoading },
  ] = useMutation<
    CreateInstructorReviewMutation,
    CreateInstructorReviewMutationVariables
  >(createInstructorReviewMutation);

  const { id } = useParams<{ id: string }>();

  const formik = useFormik({
    initialValues: {
      content: '',
      id,
      rate: 5,
    },
    onSubmit: (values) => {
      if (type === 'course') {
        createCourseReview({
          variables: {
            input: {
              content: values.content,
              courseId: id,
              rate: values.rate,
            },
          },
        });
      }

      if (type === 'teacher') {
        createInstructorReview({
          variables: {
            input: {
              content: values.content,
              instructorId: id,
              rate: values.rate,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (courseReviewData || instructorReviewData) {
      onOk();
    }
  }, [courseReviewData, instructorReviewData]);

  const { TextArea } = Input;

  const { t } = useTranslation();

  return (
    <Modal
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={courseReviewLoading || instructorReviewLoading}
          onClick={formik.handleSubmit as () => void}
        >
          {t('ok')}
        </Button>,
      ]}
      title={
        <>
          <Title.MD>{title}</Title.MD>
          <Text.SM>{subtitle}</Text.SM>
        </>
      }
      visible={visible}
      width="765px"
      onCancel={onCancel}
    >
      {subject}
      <Row>
        <Col xs={0} lg={3}></Col>
        <Col xs={24} lg={21}>
          <Title.SM className="mb-8">{t('rating')}</Title.SM>
          <div className="mb-24">
            <Rate
              className="mr-12"
              onChange={formik.handleRateChange('rate')}
              defaultValue={formik.values.rate}
            />
            <Text.SM>{formik.values.rate} of 5</Text.SM>
          </div>
          <Form>
            <LabelField
              placeholder={t('add_review')}
              focused={isFocusedField('content')}
              active={!!formik.values.content}
            >
              <Form.Item
                help={formik.errors.content}
                validateStatus={formik.getFieldStatus('content')}
              >
                <TextArea
                  onBlur={onBlurField()}
                  onFocus={onFocusField('content')}
                  onChange={formik.handleFieldChange('content')}
                  autoSize={{ maxRows: 8, minRows: 5 }}
                />
              </Form.Item>
            </LabelField>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
