import * as React from 'react';
import { hydrate } from 'react-dom';
import TagManager from 'react-gtm-module';

import { App } from './src/app';
import { apolloClient } from './src/infrastructure/graphql';
import { apolloCache } from './src/infrastructure/graphql/cache';
import { configureReduxStore } from './src/infrastructure/redux';
import { isClient } from './src/shared/utils/common';

const { persistor, store } = configureReduxStore();

if (isClient() && persistor) {
  persistor.subscribe(async () => {
    const tagManagerArgs = {
      gtmId: 'GTM-MNKK2G8',
    };

    TagManager.initialize(tagManagerArgs);

    const { bootstrapped } = persistor.getState();

    if (!bootstrapped) {
      return;
    }

    // @ts-ignore
    apolloCache.restore(window.__APOLLO_STATE__ || {});

    hydrate(
      <App store={store} client={apolloClient} />,
      document.getElementById('root'),
    );
  });
}

if (module.hot) {
  module.hot.accept();
}
