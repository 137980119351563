import i18n from '../../infrastructure/i18n';
import { ISortItem } from '../../shared/components/select/sorting';
import {
  CourseListOrderBy,
  CoursesListQueryVariables,
  SortDirection,
} from '../../shared/types/generated';

export const seo = {
  description:
    '24/7 live English classes with expert ESL teachers. ' +
    'Business English, General English, IELTS/TOEFL tests preparation. ' +
    'Career-focused English.',
  keywords: [
    'fun virtual classes for kids',
    'online classes for kids',
    'hobby classes for kids',
    'evening classes for kids',
    'classes for kids near me',
    'business classes for kids',
    'zoom classes for kids',
    'foreign language classes for kids',
    'Outschool for kids',
    'online classes for kids hobbies',
    'online classes for kids to learn english',
    'online painting classes for kids',
    'online ballet classes for kids',
    'online watercolor classes for kids',
    'online coding esl math classes for kids',
    'online typing classes for kids',
    'online grammar classes for kids',
    'online literacy',
    'outschool like classes for kids',
    'is there an outschool for kids',
    'outschool equivalent for kids',
    'online classes for kids',
    'virtual classes for kids',
    'online classes for kids california',
    'online cooking classes for kids',
    'online classes for kids during coronavirus',
    'online live class platform',
    'live classes online',
  ].join(', '),
};

export const sortOptions: ISortItem[] = [
  {
    code: CourseListOrderBy.CreatedAt,
    name: i18n.t('new'),
  },
  {
    code: CourseListOrderBy.Today,
    name: i18n.t('today'),
  },
  {
    code: CourseListOrderBy.StartDate,
    name: i18n.t('coming_soon'),
  },
  // {
  //   code: CourseListOrderBy.Price,
  //   name: i18n.t('price'),
  // },
];

export const initialSortParam = sortOptions[0].code as CourseListOrderBy;

export const sortQuery = {
  direction: SortDirection.Asc,
  orderBy: initialSortParam,
};

export const COURSES_PER_REQUEST = 5;
export const INITIAL_COURSE_LOAD = 8;

export const coursesListQueryInitialInput: CoursesListQueryVariables['input'] = {
  filter: {},
  pagination: { first: INITIAL_COURSE_LOAD },
  sort: { direction: SortDirection.Asc, orderBy: initialSortParam },
};
