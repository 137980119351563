import i18n from '../../../infrastructure/i18n';
import { CourseScheduleWeekdays } from '../../../shared/types/generated';

export interface IDay {
  code: CourseScheduleWeekdays;
  name: string;
}

export const days: IDay[] = [
  {
    code: CourseScheduleWeekdays.Su,
    name: i18n.t('sunday'),
  },
  {
    code: CourseScheduleWeekdays.Mo,
    name: i18n.t('monday'),
  },
  {
    code: CourseScheduleWeekdays.Tu,
    name: i18n.t('tuesday'),
  },
  {
    code: CourseScheduleWeekdays.We,
    name: i18n.t('wednesday'),
  },
  {
    code: CourseScheduleWeekdays.Th,
    name: i18n.t('thursday'),
  },
  {
    code: CourseScheduleWeekdays.Fr,
    name: i18n.t('friday'),
  },
  {
    code: CourseScheduleWeekdays.Sa,
    name: i18n.t('saturday'),
  },
];

export const pluralDays: IDay[] = [
  {
    code: CourseScheduleWeekdays.Su,
    name: i18n.t('sundays'),
  },
  {
    code: CourseScheduleWeekdays.Mo,
    name: i18n.t('mondays'),
  },
  {
    code: CourseScheduleWeekdays.Tu,
    name: i18n.t('tuesdays'),
  },
  {
    code: CourseScheduleWeekdays.We,
    name: i18n.t('wednesdays'),
  },
  {
    code: CourseScheduleWeekdays.Th,
    name: i18n.t('thursdays'),
  },
  {
    code: CourseScheduleWeekdays.Fr,
    name: i18n.t('fridays'),
  },
  {
    code: CourseScheduleWeekdays.Sa,
    name: i18n.t('saturdays'),
  },
];

export const daysMap: { [key: string]: string } = {
  [CourseScheduleWeekdays.Su]: 'Sunday',
  [CourseScheduleWeekdays.Mo]: 'Monday',
  [CourseScheduleWeekdays.Tu]: 'Tuesday',
  [CourseScheduleWeekdays.We]: 'Wednesday',
  [CourseScheduleWeekdays.Th]: 'Thursday',
  [CourseScheduleWeekdays.Fr]: 'Friday',
  [CourseScheduleWeekdays.Sa]: 'Saturday',
};

export const pluralDaysMap: { [key: string]: string } = {
  [CourseScheduleWeekdays.Su]: i18n.t('sundays'),
  [CourseScheduleWeekdays.Mo]: i18n.t('mondays'),
  [CourseScheduleWeekdays.Tu]: i18n.t('tuesdays'),
  [CourseScheduleWeekdays.We]: i18n.t('wednesdays'),
  [CourseScheduleWeekdays.Th]: i18n.t('thursdays'),
  [CourseScheduleWeekdays.Fr]: i18n.t('fridays'),
  [CourseScheduleWeekdays.Sa]: i18n.t('saturdays'),
};

export const daysMapShort: { [key: string]: string } = {
  [CourseScheduleWeekdays.Su]: 'Sun',
  [CourseScheduleWeekdays.Mo]: 'Mon',
  [CourseScheduleWeekdays.Tu]: 'Tue',
  [CourseScheduleWeekdays.We]: 'Wed',
  [CourseScheduleWeekdays.Th]: 'Thu',
  [CourseScheduleWeekdays.Fr]: 'Fri',
  [CourseScheduleWeekdays.Sa]: 'Sat',
};
