import i18n from '../../infrastructure/i18n';
import { CourseScheduleFrequency } from '../types/generated';

export interface ICourseFrequency {
  code: CourseScheduleFrequency;
  name: string;
}

export const courseFrequencies: ICourseFrequency[] = [
  {
    code: CourseScheduleFrequency.OnceAWeek,
    name: i18n.t('once_a_week'),
  },
  {
    code: CourseScheduleFrequency.TwiceAWeek,
    name: i18n.t('twice_a_week'),
  },
  {
    code: CourseScheduleFrequency.OnWeekends,
    name: i18n.t('on_weekends'),
  },
];
