export class Result<T> {
  private readonly _value?: T;
  public readonly error?: string;
  public readonly ok: boolean;
  public readonly payload?: Record<string, string>;

  public constructor(
    ok: boolean,
    error?: string,
    value?: T,
    payload?: Record<string, string>,
  ) {
    if (ok && error) {
      throw new Error(
        'InvalidOperation: A result cannot be successful and contain an error',
      );
    }
    if (!ok && !error) {
      throw new Error(
        'InvalidOperation: A failing result needs to contain an error message',
      );
    }

    this.ok = ok;
    this.error = error;
    this._value = value;
    this.payload = payload;

    Object.freeze(this);
  }

  public get value(): T {
    if (!this.ok) {
      throw new Error(
        `Can't get the value of an error result. Use 'error' instead. Error is ${String(
          this.error,
        )}`,
      );
    }

    return this._value as T;
  }

  public static ok<U>(value?: U): Result<U> {
    return new Result<U>(true, undefined, value);
  }

  public static fail<U>(error?: string): Result<U> {
    return new Result<U>(false, error);
  }

  public static combine(results: Result<any>[]): Result<any> {
    for (const result of results) {
      if (!result.ok) return result;
    }
    return Result.ok();
  }
}

export class FailedResult extends Result<string> {
  constructor(error: string, payload?: Record<string, string>) {
    super(false, error, undefined, payload);
  }
}
