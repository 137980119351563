import {
  CarryOutOutlined,
  FireOutlined,
  HistoryOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CourseFragment } from '../../../../types/generated';
import { CourseTime } from '../../../course-time';
import { PriceFormat } from '../../../price-format/';
import { RateRead } from '../../../rate';
import { Text, Title } from '../../../typography';
import { FavoriteCourseButton } from '../favorite-course-button';
import CardClasses from './index.module.less';

interface CourseListData extends CourseFragment {
  reviewsCount: number;
}

export interface CourseCardFullProps {
  course: CourseListData;
}

export const CourseCardFull: FC<CourseCardFullProps> = ({ course }) => {
  const cover = course.coverPhotoUrl;
  const courseType = capitalCase(course.type);
  const date = course?.nextSection?.startDate
    ? format(new Date(course.nextSection.startDate), 'E, d MMM / hh:mm a')
    : 'Enrollment has ended';

  const {
    slug,
    title,
    price,
    rating,
    reviewsCount,
    popular,
    currency,
  } = course;

  const { t } = useTranslation();

  return (
    <Link to={`/course/${slug}`} className={CardClasses['card-link']}>
      <Card
        extra={
          <>
            {popular ? (
              <Button
                className="mr-8 ant-btn-card-bagde"
                type="primary"
                shape="round"
                icon={<FireOutlined />}
              >
                {t('popular')}
              </Button>
            ) : null}
            <FavoriteCourseButton buttonType="badge" courseId={slug} />
          </>
        }
        loading={false}
        bordered={false}
        className={CardClasses.card}
        cover={
          <img width="100" className={CardClasses['card-img']} src={cover} />
        }
      >
        <div className={CardClasses['card-author']}>
          <Text.SM className="text-gray-main">
            {course.instructor?.legalName || ''}
          </Text.SM>
        </div>
        <div className={CardClasses['ant-card-body-main']}>
          <Title.MD className="mb-8 ant-typography-ellipsis-multiple-line">
            {title}
          </Title.MD>

          <RateRead reviews={reviewsCount} rating={rating} className="mb-12" />
        </div>

        <Row justify="space-between" className="mb-12">
          <Col>
            <Text.XS className="text-gray-main">{t('class_size')}</Text.XS>
          </Col>
          <Col>
            <Text.XS>
              <UsergroupAddOutlined className="mr-6" />
              {course.minStudents} - {course.maxStudents} {t('people_class')}
            </Text.XS>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-12">
          <Col>
            <Text.XS className="text-gray-main">{t('level')}</Text.XS>
          </Col>
          <Col>
            <Text.XS>
              <HistoryOutlined className="mr-6" />
              {courseType}
            </Text.XS>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-12">
          <Col>
            <Text.XS className="text-gray-main">{t('next_on')}</Text.XS>
          </Col>
          <Col>
            <Text.XS suppressHydrationWarning>
              <CarryOutOutlined className="mr-6" />
              {date}
            </Text.XS>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-12" wrap={false}>
          <Col xs={8}>
            <Text.XS className="text-gray-main">{t('meets_on')}</Text.XS>
          </Col>
          <Col>
            <CourseTime
              smallSize
              data={{ scheduleByDay: course?.nextSection?.scheduleByDay }}
            />
          </Col>
        </Row>

        {price && currency && (
          <Row justify="space-between" align="bottom">
            <Col>
              <Text.MD className="text-gray-main">
                {t('price_per_course')}:
              </Text.MD>
            </Col>
            <Col>
              <Title.Big>
                <PriceFormat price={price} currency={currency} />
              </Title.Big>
            </Col>
          </Row>
        )}
      </Card>
    </Link>
  );
};
