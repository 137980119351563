import {
  ResetUserAction,
  ResetUserTokenAction,
  SetUserAction,
  SetUserCookiesAction,
  SetUserTokenAction,
} from './types';

export const setUserToken = (
  payload: SetUserTokenAction['payload'],
): SetUserTokenAction => ({
  payload,
  type: 'SET_USER_TOKEN',
});

export const resetUserToken = (): ResetUserTokenAction => ({
  type: 'RESET_USER_TOKEN',
});

export const setUser = (payload: SetUserAction['payload']): SetUserAction => ({
  payload,
  type: 'SET_USER',
});

export const resetUser = (): ResetUserAction => ({
  type: 'RESET_USER',
});

export const setUserCookies = (): SetUserCookiesAction => ({
  type: 'SET_USER_COOKIES',
});
