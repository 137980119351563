import { Menu } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../../infrastructure/i18n';
import { Text } from '../../typography';
import { Filter } from '../shared/filter';
import { DayPicker, days } from './components/day';
import { PhasePicker } from './components/phase';
import styles from './style.module.less';

interface IAvailabilityFilter {
  dayPhaseValue: string;
  onDayPhaseChange: (value: string) => void;
  onWeekDayChange: (e: string[]) => void;
  weekDayValue: string[];
}

const getSubtitle = (dayPhaseValue: string, weekDayValue: Array<any>) => {
  if (!dayPhaseValue && !weekDayValue.length) {
    return i18n.t('select_time');
  }
  const weekDayNames = days
    .filter(({ code }) => weekDayValue.includes(code))
    .map(({ name }) => name);
  const spacer = dayPhaseValue && weekDayValue.length ? ' / ' : '';

  return `${dayPhaseValue}${spacer}${weekDayNames.join(', ')}`;
};

export const AvailabilityFilter: FC<IAvailabilityFilter> = ({
  dayPhaseValue,
  onWeekDayChange,
  onDayPhaseChange,
  weekDayValue,
}) => {
  const { t } = useTranslation();

  const onClear = () => {
    onWeekDayChange([]);
    onDayPhaseChange('');
  };
  const menu = (
    <Menu className={styles['availability-item']}>
      <Menu.Item>
        <PhasePicker
          onChange={(e) => onDayPhaseChange(e.target.value)}
          value={dayPhaseValue}
        />
        <Text.SM className="block mb-8 text-gray-main">
          {t('days_of_the_week')}
        </Text.SM>
        <DayPicker
          onChange={(e) => onWeekDayChange(e.map((el) => el.toString()))}
          value={weekDayValue}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Filter
      className={styles['availability']}
      menu={menu}
      onClear={onClear}
      showClearButton={!!onClear && (!!dayPhaseValue || !!weekDayValue.length)}
      subtitle={getSubtitle(dayPhaseValue, weekDayValue)}
      title={t('availability')}
    />
  );
};
