import gql from 'graphql-tag';

export const linksListQuery = gql`
  query linksList {
    linksList {
      id
      slug
      url
    }
  }
`;
