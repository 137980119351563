import './simple-filter.less';

import { CloseOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { FC } from 'react';

import { Filter } from '../filter';

const getSubtitle = (
  value: string,
  filtersList: Array<any>,
  defaultSubtitle: string,
) => {
  const selectedFilter = filtersList.find(({ code }) => code === value);

  return selectedFilter ? selectedFilter.name : defaultSubtitle;
};

export interface ISimpleFilter {
  defaultSubtitle: string;
  filtersList: Array<any>;
  onChange: (value: any) => void;
  title: string;
  value: string;
}

export const SimpleFilter: FC<ISimpleFilter> = ({
  defaultSubtitle,
  filtersList,
  onChange,
  title,
  value,
}) => {
  const onMenuItemClick = (e: any) => onChange(e.key === value ? '' : e.key);
  const onClear = () => onChange('');
  const menu = (
    <Menu className="cr-simple-filter">
      {filtersList.map(({ code, name }) => (
        <Menu.Item
          className={code === value ? 'ant-dropdown-menu-item-selected' : ''}
          key={code}
          onClick={onMenuItemClick}
        >
          <span className="sf-label">{name}</span>
          {value === code ? <CloseOutlined className="sf-close" /> : null}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Filter
      menu={menu}
      showClearButton={!!onClear && !!value}
      subtitle={getSubtitle(value, filtersList, defaultSubtitle)}
      title={title}
      onClear={onClear}
    />
  );
};
