import PubNub from 'pubnub';

import { configs } from '../../infrastructure/config';

export const pubnub = new PubNub({
  heartbeatInterval: 0,
  publishKey: configs?.RAZZLE_PUBNUB_PUBLISH_KEY,
  restore: true,
  subscribeKey: configs?.RAZZLE_PUBNUB_SUBSCRIBE_KEY as string,
});
