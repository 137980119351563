import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './index.module.less';

interface ISimpleLoader {
  size?: 'small' | 'medium' | 'large';
}

export const SimpleLoader: FC<ISimpleLoader> = ({ size = 'large' }) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <div
      className={classNames(styles['cr-loader-wrap'], {
        [styles['cr-loader-wrap--small']]: isSmall,
        [styles['cr-loader-wrap--large']]: isLarge,
      })}
    >
      <Spin
        indicator={
          <LoadingOutlined
            className={classNames(styles['cr-loader'], {
              [styles['cr-loader--small']]: isSmall,
              [styles['cr-loader--large']]: isLarge,
            })}
            spin
          />
        }
      />
    </div>
  );
};
