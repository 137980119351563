import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: boolean;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  Float: number;
  ID: string;
  Int: number;
  String: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddFavoriteCourseGqlInput = {
  courseId: Scalars['String'];
};

export type AddFavoriteCourseGqlResponse = {
  __typename?: 'AddFavoriteCourseGqlResponse';
  user: GqlUser;
};

export type CancelSubscriptionGqlResponse = {
  __typename?: 'CancelSubscriptionGqlResponse';
  success: Scalars['Boolean'];
};

export enum CourseCategory {
  Arts = 'Arts',
  Business = 'Business',
  BusinessEnglish = 'BusinessEnglish',
  Design = 'Design',
  English = 'English',
  EnglishForIt = 'EnglishForIT',
  GeneralEnglish = 'GeneralEnglish',
  HealthAndFitness = 'HealthAndFitness',
  Hobbies = 'Hobbies',
  Ielts = 'IELTS',
  Lifestyle = 'Lifestyle',
  Math = 'Math',
  Music = 'Music',
  PersonalDevelopment = 'PersonalDevelopment',
  Science = 'Science',
  SpeakingClubs = 'SpeakingClubs',
  TechSkills = 'TechSkills',
  Toefl = 'TOEFL',
  WorldLanguages = 'WorldLanguages',
}

export enum CourseCategoryFilter {
  Arts = 'Arts',
  Bestselling = 'Bestselling',
  Business = 'Business',
  BusinessEnglish = 'BusinessEnglish',
  Design = 'Design',
  English = 'English',
  EnglishForIt = 'EnglishForIT',
  GeneralEnglish = 'GeneralEnglish',
  HealthAndFitness = 'HealthAndFitness',
  Hobbies = 'Hobbies',
  Ielts = 'IELTS',
  Lifestyle = 'Lifestyle',
  Math = 'Math',
  Music = 'Music',
  PersonalDevelopment = 'PersonalDevelopment',
  Science = 'Science',
  SpeakingClubs = 'SpeakingClubs',
  TechSkills = 'TechSkills',
  Toefl = 'TOEFL',
  WorldLanguages = 'WorldLanguages',
}

export type CourseClassGql = {
  __typename?: 'CourseClassGql';
  course: CourseGql;
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  section: CourseSectionGql;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  zoomLink?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
};

export enum CourseCurrency {
  Pln = 'PLN',
  Usd = 'USD',
}

export type CourseGql = {
  __typename?: 'CourseGql';
  additionalMaterials?: Maybe<Scalars['String']>;
  bestSelling: Scalars['Boolean'];
  canPostReview: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
  category: CourseCategory;
  classDurationMinutes: Scalars['Int'];
  classDurationString: Scalars['String'];
  completedByCount: Scalars['Int'];
  courseDurationWeeks: Scalars['Float'];
  coverPhotoUrl: Scalars['String'];
  currency?: Maybe<CourseCurrency>;
  description: Scalars['String'];
  id: Scalars['String'];
  imageUrls: Array<Scalars['String']>;
  instructor: GqlInstructor;
  isActive: Scalars['Boolean'];
  isOngoing: Scalars['Boolean'];
  isUserEnrolledInActiveSection: Scalars['Boolean'];
  maxStudents: Scalars['Int'];
  maxStudentsAge?: Maybe<Scalars['Int']>;
  minStudents: Scalars['Int'];
  minStudentsAge?: Maybe<Scalars['Int']>;
  nextSection?: Maybe<CourseSectionGql>;
  popular: Scalars['Boolean'];
  /** Price in cents of a particular currency */
  price?: Maybe<Scalars['Int']>;
  rating: Scalars['Float'];
  reviews: Array<GqlReview>;
  reviewsCount: Scalars['Float'];
  sections: Array<CourseSectionGql>;
  slug: Scalars['String'];
  summary: Scalars['String'];
  supplies?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: CourseType;
  tzid: Scalars['String'];
  upcomingSections: Array<CourseSectionGql>;
  vimeoVideoId?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export enum CourseListOrderBy {
  CreatedAt = 'createdAt',
  Price = 'price',
  StartDate = 'startDate',
  Today = 'today',
}

export type CourseRequestGql = {
  __typename?: 'CourseRequestGql';
  category: CourseCategory;
  description: Scalars['String'];
  id: Scalars['String'];
  requesterEmail: Scalars['String'];
};

export enum CourseScheduleFrequency {
  EveryDay = 'EveryDay',
  OnWeekends = 'OnWeekends',
  OnceAWeek = 'OnceAWeek',
  TwiceAWeek = 'TwiceAWeek',
}

export enum CourseScheduleTimeGql {
  Afternoon = 'Afternoon',
  Evening = 'Evening',
  Morning = 'Morning',
  Night = 'Night',
}

export enum CourseScheduleWeekdays {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE',
}

export type CourseSectionGql = {
  __typename?: 'CourseSectionGql';
  classes: Array<CourseClassGql>;
  course: CourseGql;
  /** UTC date time when the last class completes */
  endDate: Scalars['DateTime'];
  enrolledCount: Scalars['Int'];
  /** If no min students enrolled in time, the section is considered failed */
  failed: Scalars['Boolean'];
  id: Scalars['String'];
  scheduleByDay: Array<CourseSingleDayScheduleGql>;
  /** UTC date time of schedule start */
  startDate: Scalars['DateTime'];
};

export type CourseSingleDayScheduleGql = {
  __typename?: 'CourseSingleDayScheduleGql';
  day: CourseScheduleWeekdays;
  id: Scalars['String'];
  /** UTC 24h format hours */
  timeHours: Scalars['Int'];
  timeMinutes: Scalars['Int'];
};

export enum CourseType {
  Advanced = 'Advanced',
  BeginnerToPreIntermediate = 'BeginnerToPreIntermediate',
  IntermediateToUpperIntermediate = 'IntermediateToUpperIntermediate',
}

export type CreateCourseGqlInput = {
  additionalMaterials?: Maybe<Scalars['String']>;
  category: CourseCategory;
  classDurationMinutes: Scalars['Int'];
  courseDurationWeeks: Scalars['Int'];
  coverPhoto: Scalars['Upload'];
  currency?: Maybe<CourseCurrency>;
  description: Scalars['String'];
  images: Array<Scalars['Upload']>;
  maxStudents: Scalars['Int'];
  maxStudentsAge?: Maybe<Scalars['Int']>;
  minStudents: Scalars['Int'];
  minStudentsAge?: Maybe<Scalars['Int']>;
  /** Price in cents of a particular currency */
  price?: Maybe<Scalars['Int']>;
  sections: Array<CreateCourseSectionGqlInput>;
  summary: Scalars['String'];
  supplies?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: CourseType;
  tzid: Scalars['String'];
  vimeoVideoId?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export type CreateCourseGqlResponse = {
  __typename?: 'CreateCourseGqlResponse';
  course: CourseGql;
};

export type CreateCourseRequestGqlInput = {
  category: CourseCategory;
  description: Scalars['String'];
  requesterEmail: Scalars['String'];
};

export type CreateCourseRequestGqlResponse = {
  __typename?: 'CreateCourseRequestGqlResponse';
  courseRequest: CourseRequestGql;
};

export type CreateCourseReviewGqlInput = {
  content?: Maybe<Scalars['String']>;
  courseId: Scalars['String'];
  rate: Scalars['Float'];
};

export type CreateCourseSectionGqlInput = {
  scheduleByDay: Array<CreateCourseSingleDayScheduleGqlGqlInput>;
  /** UTC date time of first scheduled entity */
  startDate: Scalars['DateTime'];
};

export type CreateCourseSingleDayScheduleGqlGqlInput = {
  day: CourseScheduleWeekdays;
  /** UTC hors for this day */
  timeHours: Scalars['Int'];
  timeMinutes: Scalars['Int'];
};

export type CreateInstructorReviewGqlInput = {
  content?: Maybe<Scalars['String']>;
  instructorId: Scalars['String'];
  rate: Scalars['Float'];
};

export type CreateMeetingGqlInput = {
  instructorId: Scalars['String'];
};

export type CreateMeetingGqlResponse = {
  __typename?: 'CreateMeetingGqlResponse';
  meetings: Array<MeetingGql>;
};

export type CreateReviewGqlResponse = {
  __typename?: 'CreateReviewGqlResponse';
  review: GqlReview;
};

export type CreateSubscriptionGqlResponse = {
  __typename?: 'CreateSubscriptionGqlResponse';
  clientSecret?: Maybe<Scalars['String']>;
  processed: Scalars['Boolean'];
};

export type Cursor = {
  __typename?: 'Cursor';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type DocumentGql = {
  __typename?: 'DocumentGql';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  slug: Scalars['String'];
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum FaqCategory {
  Instructor = 'instructor',
  Student = 'student',
}

export enum FeatureFlagName {
  UseSubscriptions = 'useSubscriptions',
}

export type GetCourseGqlInput = {
  slug: Scalars['String'];
};

export type GetCoursesListGqlFilterInput = {
  byWeekDay?: Maybe<Array<CourseScheduleWeekdays>>;
  category?: Maybe<CourseCategoryFilter>;
  frequency?: Maybe<CourseScheduleFrequency>;
  studentsAge?: Maybe<StudentsAgeFilterGqlInput>;
  time?: Maybe<CourseScheduleTimeGql>;
  type?: Maybe<CourseType>;
  tzid?: Maybe<Scalars['String']>;
};

export type GetCoursesListGqlInput = {
  filter?: Maybe<GetCoursesListGqlFilterInput>;
  pagination?: Maybe<PaginationGqlInput>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<GetCoursesListGqlSortingInput>;
};

export type GetCoursesListGqlResponse = {
  __typename?: 'GetCoursesListGqlResponse';
  cursor: Cursor;
  data: Array<CourseGql>;
  totalCount: Scalars['Int'];
};

export type GetCoursesListGqlSortingInput = {
  direction?: Maybe<SortDirection>;
  orderBy?: Maybe<CourseListOrderBy>;
};

export type GetDocumentGqlInput = {
  slug: Scalars['String'];
};

export type GetLinkGqlInput = {
  slug: Scalars['String'];
};

export type GetMeetingStartUrlGqlInput = {
  meetingId: Scalars['String'];
};

export type GetMeetingStartUrlGqlResponse = {
  __typename?: 'GetMeetingStartUrlGqlResponse';
  startUrl: Scalars['String'];
};

export type GqlCourseInstructorRevenueStats = {
  __typename?: 'GqlCourseInstructorRevenueStats';
  amount: Scalars['Float'];
  currency: CourseCurrency;
  id: Scalars['String'];
};

export type GqlCourseStats = {
  __typename?: 'GqlCourseStats';
  currency: CourseCurrency;
  enrolments: Scalars['Float'];
  id: Scalars['String'];
  revenue: Scalars['Float'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};

export type GqlCreateInstructorInput = {
  countryOfLivingCode: Scalars['String'];
  hasOnlineTeachingExperience: Scalars['Boolean'];
  hasTeachingExperience: Scalars['Boolean'];
  hearAboutClassroolFrom: HearAboutClassroolFrom;
  legalName: Scalars['String'];
  levelOfEducation: LevelOfEducation;
  socialAccount: Scalars['String'];
  teachingAvailability: TeachingAvailability;
  teachingPreferencesDescription: Scalars['String'];
  vimeoVideoId?: Maybe<Scalars['String']>;
};

export type GqlCreateInstructorResponse = {
  __typename?: 'GqlCreateInstructorResponse';
  user: GqlUser;
};

export type GqlCreateSubscriptionInput = {
  priceId: Scalars['String'];
};

export type GqlCreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type GqlCreateUserResponse = {
  __typename?: 'GqlCreateUserResponse';
  token: Scalars['String'];
  user: GqlUser;
};

export type GqlFaq = {
  __typename?: 'GqlFaq';
  category: FaqCategory;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  language: Scalars['String'];
  order: Scalars['Float'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GqlFeatureFlag = {
  __typename?: 'GqlFeatureFlag';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: FeatureFlagName;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GqlForgotUserPasswordInput = {
  email: Scalars['String'];
};

export type GqlForgotUserPasswordResponse = {
  __typename?: 'GqlForgotUserPasswordResponse';
  ok: Scalars['Boolean'];
};

export type GqlGetInstructorInput = {
  id: Scalars['String'];
};

export type GqlGetInstructorStatsInput = {
  userId: Scalars['String'];
};

export type GqlInstructor = {
  __typename?: 'GqlInstructor';
  approved: Scalars['Boolean'];
  canPostReview: Scalars['Boolean'];
  countryOfLivingCode: Scalars['String'];
  courses: Array<CourseGql>;
  createdAt: Scalars['DateTime'];
  hasOnlineTeachingExperience: Scalars['Boolean'];
  hasTeachingExperience: Scalars['Boolean'];
  hearAboutClassroolFrom: HearAboutClassroolFrom;
  id: Scalars['String'];
  legalName: Scalars['String'];
  levelOfEducation: LevelOfEducation;
  paymentsInfo?: Maybe<InstructorPaymentsInfo>;
  publicInformation?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
  reviews: Array<GqlReview>;
  reviewsCount: Scalars['Float'];
  socialAccount: Scalars['String'];
  teachingAvailability: TeachingAvailability;
  teachingPreferencesDescription: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: GqlUser;
  vimeoVideoId?: Maybe<Scalars['String']>;
};

export type GqlInstructorStatsResponse = {
  __typename?: 'GqlInstructorStatsResponse';
  courseStats: Array<GqlCourseStats>;
  enrolmentsThisMonth: Scalars['Float'];
  enrolmentsTotal: Scalars['Float'];
  ratingTotal: Scalars['Float'];
  revenueThisMonth: Array<GqlCourseInstructorRevenueStats>;
  revenueTotal: Array<GqlCourseInstructorRevenueStats>;
  thisMonthRatingsCount: Scalars['Float'];
};

export type GqlOnboardInstructorResponse = {
  __typename?: 'GqlOnboardInstructorResponse';
  url: Scalars['String'];
};

export type GqlPaymentMethod = {
  __typename?: 'GqlPaymentMethod';
  brand: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type GqlPrice = {
  __typename?: 'GqlPrice';
  amount: Scalars['Float'];
  currency: CourseCurrency;
  id: Scalars['String'];
  interval: SubscriptionInterval;
  intervalCount: Scalars['Float'];
  oneTime: Scalars['Boolean'];
};

export type GqlProcessEnrollmentInput = {
  paymentMethodId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
  saveCard?: Maybe<Scalars['Boolean']>;
  sectionId: Scalars['String'];
};

export type GqlRemovePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
};

export type GqlRemovePaymentMethodResponse = {
  __typename?: 'GqlRemovePaymentMethodResponse';
  user: GqlUser;
};

export type GqlResetUserPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type GqlResetUserPasswordResponse = {
  __typename?: 'GqlResetUserPasswordResponse';
  token: Scalars['String'];
  user: GqlUser;
};

export type GqlRetrievePricesInput = {
  currency: CourseCurrency;
};

export type GqlReview = {
  __typename?: 'GqlReview';
  author: GqlUser;
  content?: Maybe<Scalars['String']>;
  course?: Maybe<CourseGql>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  instructor?: Maybe<GqlInstructor>;
  rate: Scalars['Float'];
};

export type GqlSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type GqlSignInResponse = {
  __typename?: 'GqlSignInResponse';
  token: Scalars['String'];
  user: GqlUser;
};

export type GqlSingleFaqInput = {
  id: Scalars['String'];
};

export type GqlSingleFeatureFlagInput = {
  name: Scalars['String'];
};

export type GqlSubscription = {
  __typename?: 'GqlSubscription';
  amount: Scalars['Float'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  currency: CourseCurrency;
  currentPeriodEnd: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  id: Scalars['String'];
  interval: SubscriptionInterval;
  intervalCount: Scalars['Float'];
  payedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  status: SubscriptionStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GqlUpdateInstructorInput = {
  countryOfLivingCode?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  publicInformation?: Maybe<Scalars['String']>;
};

export type GqlUpdateInstructorResponse = {
  __typename?: 'GqlUpdateInstructorResponse';
  user: GqlUser;
};

export type GqlUpdateUserInput = {
  avatar?: Maybe<Scalars['Upload']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
};

export type GqlUpdateUserResponse = {
  __typename?: 'GqlUpdateUserResponse';
  user: GqlUser;
};

export type GqlUser = {
  __typename?: 'GqlUser';
  avatarUrl?: Maybe<Scalars['String']>;
  courses: Array<CourseGql>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  favoriteCourses: Array<CourseGql>;
  firstName: Scalars['String'];
  hasActiveSubscription: Scalars['Boolean'];
  id: Scalars['String'];
  instructor?: Maybe<GqlInstructor>;
  isEmailVerified: Scalars['Boolean'];
  lastName: Scalars['String'];
  paymentsInfo?: Maybe<GqlUserPaymentsInfo>;
  recentlyViewedCourses: Array<CourseGql>;
  sections: Array<CourseSectionGql>;
  shareCalendarUrl?: Maybe<Scalars['String']>;
  subscription?: Maybe<GqlSubscription>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GqlUserPaymentsInfo = {
  __typename?: 'GqlUserPaymentsInfo';
  id: Scalars['String'];
  paymentMethods: Array<GqlPaymentMethod>;
  systemName: PaymentSystems;
};

export enum HearAboutClassroolFrom {
  AnotherInstructor = 'AnotherInstructor',
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  SomebodyWhoTookClass = 'SomebodyWhoTookClass',
}

export type InstructorPaymentsInfo = {
  __typename?: 'InstructorPaymentsInfo';
  dashboardUrl?: Maybe<Scalars['String']>;
  detailsFilled: Scalars['Boolean'];
  id: Scalars['String'];
  paymentsEnabled: Scalars['Boolean'];
  systemName: PaymentSystems;
};

export type InstructorScheduleGqlResponse = {
  __typename?: 'InstructorScheduleGqlResponse';
  courses: Array<CourseGql>;
};

export enum LevelOfEducation {
  AssociateDegree = 'AssociateDegree',
  BachelorDegree = 'BachelorDegree',
  DoctorDegree = 'DoctorDegree',
  HighSchoolDiploma = 'HighSchoolDiploma',
  MasterDegree = 'MasterDegree',
}

export type LinkGql = {
  __typename?: 'LinkGql';
  id: Scalars['String'];
  slug: Scalars['String'];
  url: Scalars['String'];
};

export type MeetingGql = {
  __typename?: 'MeetingGql';
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFavoriteCourse: AddFavoriteCourseGqlResponse;
  cancelSubscription: CancelSubscriptionGqlResponse;
  createCourse: CreateCourseGqlResponse;
  createCourseRequest: CreateCourseRequestGqlResponse;
  createCourseReview: CreateReviewGqlResponse;
  createInstructor: GqlCreateInstructorResponse;
  createInstructorReview: CreateReviewGqlResponse;
  createMeeting: CreateMeetingGqlResponse;
  createSubscription: CreateSubscriptionGqlResponse;
  createUser: GqlCreateUserResponse;
  forgotUserPassword: GqlForgotUserPasswordResponse;
  onboardInstructorPayments: GqlOnboardInstructorResponse;
  processEnrollment: ProcessEnrollmentGqlResponse;
  removeFavoriteCourse: RemoveFavoriteCourseGqlResponse;
  removePaymentMethod: GqlRemovePaymentMethodResponse;
  resetUserPassword: GqlResetUserPasswordResponse;
  signIn: GqlSignInResponse;
  updateCourse: UpdateCourseGqlResponse;
  updateInstructor: GqlUpdateInstructorResponse;
  updateUser: GqlUpdateUserResponse;
  videoUploadUrl: VideoUploadResponse;
};

export type MutationAddFavoriteCourseArgs = {
  input: AddFavoriteCourseGqlInput;
};

export type MutationCreateCourseArgs = {
  input: CreateCourseGqlInput;
};

export type MutationCreateCourseRequestArgs = {
  input: CreateCourseRequestGqlInput;
};

export type MutationCreateCourseReviewArgs = {
  input: CreateCourseReviewGqlInput;
};

export type MutationCreateInstructorArgs = {
  input: GqlCreateInstructorInput;
};

export type MutationCreateInstructorReviewArgs = {
  input: CreateInstructorReviewGqlInput;
};

export type MutationCreateMeetingArgs = {
  input: CreateMeetingGqlInput;
};

export type MutationCreateSubscriptionArgs = {
  input: GqlCreateSubscriptionInput;
};

export type MutationCreateUserArgs = {
  input: GqlCreateUserInput;
};

export type MutationForgotUserPasswordArgs = {
  input: GqlForgotUserPasswordInput;
};

export type MutationProcessEnrollmentArgs = {
  input: GqlProcessEnrollmentInput;
};

export type MutationRemoveFavoriteCourseArgs = {
  input: RemoveFavoriteCourseGqlInput;
};

export type MutationRemovePaymentMethodArgs = {
  input: GqlRemovePaymentMethodInput;
};

export type MutationResetUserPasswordArgs = {
  input: GqlResetUserPasswordInput;
};

export type MutationSignInArgs = {
  input: GqlSignInInput;
};

export type MutationUpdateCourseArgs = {
  input: UpdateCourseGqlInput;
};

export type MutationUpdateInstructorArgs = {
  input: GqlUpdateInstructorInput;
};

export type MutationUpdateUserArgs = {
  input: GqlUpdateUserInput;
};

export type MutationVideoUploadUrlArgs = {
  size: Scalars['Int'];
};

export type MyScheduleGqlResponse = {
  __typename?: 'MyScheduleGqlResponse';
  courses: Array<CourseGql>;
};

export type PaginationGqlInput = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
};

export enum PaymentSystems {
  Custom = 'Custom',
  Stripe = 'Stripe',
}

export type ProcessEnrollmentGqlResponse = {
  __typename?: 'ProcessEnrollmentGqlResponse';
  clientSecret?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  processed: Scalars['Boolean'];
};

export type PublicUserGql = {
  __typename?: 'PublicUserGql';
  avatarUrl?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type PublicUserGqlInput = {
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  course: CourseGql;
  coursesList: GetCoursesListGqlResponse;
  document: DocumentGql;
  faq: GqlFaq;
  faqList: Array<GqlFaq>;
  featureFlag?: Maybe<GqlFeatureFlag>;
  featureFlagsList: Array<GqlFeatureFlag>;
  getMeetingStartUrl: GetMeetingStartUrlGqlResponse;
  instructor: GqlInstructor;
  instructorSchedule: InstructorScheduleGqlResponse;
  instructorStats: GqlInstructorStatsResponse;
  link: LinkGql;
  linksList: Array<LinkGql>;
  me: GqlUser;
  mySchedule: MyScheduleGqlResponse;
  publicUser: PublicUserGql;
  retrievePrices: Array<GqlPrice>;
};

export type QueryCourseArgs = {
  input: GetCourseGqlInput;
};

export type QueryCoursesListArgs = {
  input?: Maybe<GetCoursesListGqlInput>;
};

export type QueryDocumentArgs = {
  input: GetDocumentGqlInput;
};

export type QueryFaqArgs = {
  input: GqlSingleFaqInput;
};

export type QueryFeatureFlagArgs = {
  input: GqlSingleFeatureFlagInput;
};

export type QueryGetMeetingStartUrlArgs = {
  input: GetMeetingStartUrlGqlInput;
};

export type QueryInstructorArgs = {
  input: GqlGetInstructorInput;
};

export type QueryInstructorStatsArgs = {
  input: GqlGetInstructorStatsInput;
};

export type QueryLinkArgs = {
  input: GetLinkGqlInput;
};

export type QueryPublicUserArgs = {
  input: PublicUserGqlInput;
};

export type QueryRetrievePricesArgs = {
  input: GqlRetrievePricesInput;
};

export type RemoveFavoriteCourseGqlInput = {
  courseId: Scalars['String'];
};

export type RemoveFavoriteCourseGqlResponse = {
  __typename?: 'RemoveFavoriteCourseGqlResponse';
  user: GqlUser;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StudentsAgeFilterGqlInput = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export enum SubscriptionInterval {
  Custom = 'Custom',
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year',
}

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'IncompleteExpired',
  PastDue = 'PastDue',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid',
}

export enum TeachingAvailability {
  FiveToTen = 'FiveToTen',
  MoreThenTen = 'MoreThenTen',
  OneToTwo = 'OneToTwo',
  TwoToFive = 'TwoToFive',
}

export type UpdateCourseGqlInput = {
  courseId: Scalars['String'];
  update: UpdateCourseGqlPayloadInput;
};

export type UpdateCourseGqlPayloadInput = {
  addImages?: Maybe<Array<Scalars['Upload']>>;
  additionalMaterials?: Maybe<Scalars['String']>;
  category?: Maybe<CourseCategory>;
  classDurationMinutes?: Maybe<Scalars['Int']>;
  courseDurationWeeks?: Maybe<Scalars['Int']>;
  coverPhoto?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  maxStudents?: Maybe<Scalars['Int']>;
  maxStudentsAge?: Maybe<Scalars['Int']>;
  minStudents?: Maybe<Scalars['Int']>;
  minStudentsAge?: Maybe<Scalars['Int']>;
  /** Price in cents of a particular currency */
  price?: Maybe<Scalars['Int']>;
  removeImages?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<CreateCourseSectionGqlInput>>;
  summary?: Maybe<Scalars['String']>;
  supplies?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CourseType>;
  tzid?: Maybe<Scalars['String']>;
  vimeoVideoId?: Maybe<Scalars['String']>;
  welcomeMessage?: Maybe<Scalars['String']>;
};

export type UpdateCourseGqlResponse = {
  __typename?: 'UpdateCourseGqlResponse';
  course: CourseGql;
};

export type VideoUploadResponse = {
  __typename?: 'VideoUploadResponse';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type CourseFragment = { __typename?: 'CourseGql' } & Pick<
  CourseGql,
  | 'bestSelling'
  | 'coverPhotoUrl'
  | 'description'
  | 'id'
  | 'slug'
  | 'maxStudents'
  | 'maxStudentsAge'
  | 'minStudents'
  | 'minStudentsAge'
  | 'popular'
  | 'price'
  | 'currency'
  | 'rating'
  | 'reviewsCount'
  | 'summary'
  | 'title'
  | 'type'
> & {
    instructor: { __typename?: 'GqlInstructor' } & Pick<
      GqlInstructor,
      'id' | 'legalName'
    >;
    nextSection?: Maybe<
      { __typename?: 'CourseSectionGql' } & Pick<
        CourseSectionGql,
        'id' | 'startDate'
      > & {
          scheduleByDay: Array<
            { __typename?: 'CourseSingleDayScheduleGql' } & Pick<
              CourseSingleDayScheduleGql,
              'id' | 'day' | 'timeHours' | 'timeMinutes'
            >
          >;
        }
    >;
  };

export type CourseCardFragment = { __typename?: 'CourseGql' } & Pick<
  CourseGql,
  | 'title'
  | 'id'
  | 'type'
  | 'price'
  | 'currency'
  | 'rating'
  | 'slug'
  | 'reviewsCount'
  | 'coverPhotoUrl'
  | 'isOngoing'
  | 'canUpdate'
  | 'maxStudents'
  | 'minStudents'
> & {
    instructor: { __typename?: 'GqlInstructor' } & Pick<
      GqlInstructor,
      'id' | 'legalName'
    >;
    nextSection?: Maybe<
      { __typename?: 'CourseSectionGql' } & Pick<
        CourseSectionGql,
        'id' | 'startDate'
      > & {
          scheduleByDay: Array<
            { __typename?: 'CourseSingleDayScheduleGql' } & Pick<
              CourseSingleDayScheduleGql,
              'id' | 'day' | 'timeHours' | 'timeMinutes'
            >
          >;
        }
    >;
  };

export type CourseEditDetailsFragmentFragment = {
  __typename?: 'CourseGql';
} & Pick<
  CourseGql,
  | 'id'
  | 'additionalMaterials'
  | 'classDurationMinutes'
  | 'category'
  | 'canUpdate'
  | 'classDurationString'
  | 'courseDurationWeeks'
  | 'imageUrls'
  | 'supplies'
  | 'price'
  | 'currency'
  | 'welcomeMessage'
  | 'vimeoVideoId'
> & {
    instructor: { __typename?: 'GqlInstructor' } & Pick<
      GqlInstructor,
      'legalName' | 'id' | 'reviewsCount' | 'rating'
    > & {
        user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id' | 'avatarUrl'>;
      };
    upcomingSections: Array<
      { __typename?: 'CourseSectionGql' } & Pick<
        CourseSectionGql,
        'endDate' | 'id' | 'startDate'
      > & {
          scheduleByDay: Array<
            { __typename?: 'CourseSingleDayScheduleGql' } & Pick<
              CourseSingleDayScheduleGql,
              'id' | 'day' | 'timeHours' | 'timeMinutes'
            >
          >;
        }
    >;
  } & CourseFragment;

export type PurchaseCourseFragment = { __typename?: 'CourseGql' } & Pick<
  CourseGql,
  | 'id'
  | 'slug'
  | 'coverPhotoUrl'
  | 'rating'
  | 'reviewsCount'
  | 'title'
  | 'price'
  | 'currency'
>;

export type PurchaseSectionFragment = {
  __typename?: 'CourseSectionGql';
} & Pick<CourseSectionGql, 'id' | 'startDate' | 'endDate'> & {
    course: { __typename?: 'CourseGql' } & PurchaseCourseFragment;
  };

export type CreateCourseReviewMutationVariables = Exact<{
  input: CreateCourseReviewGqlInput;
}>;

export type CreateCourseReviewMutation = { __typename?: 'Mutation' } & {
  createCourseReview: { __typename?: 'CreateReviewGqlResponse' } & {
    review: { __typename?: 'GqlReview' } & Pick<GqlReview, 'id'> & {
        course?: Maybe<
          { __typename?: 'CourseGql' } & Pick<
            CourseGql,
            'id' | 'slug' | 'rating'
          > & {
              reviews: Array<
                { __typename?: 'GqlReview' } & Pick<
                  GqlReview,
                  'content' | 'id' | 'rate' | 'createdAt'
                > & {
                    author: { __typename?: 'GqlUser' } & Pick<
                      GqlUser,
                      'id' | 'avatarUrl' | 'firstName' | 'lastName'
                    >;
                  }
              >;
            }
        >;
      };
  };
};

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseGqlInput;
}>;

export type CreateCourseMutation = { __typename?: 'Mutation' } & {
  createCourse: { __typename?: 'CreateCourseGqlResponse' } & {
    course: { __typename?: 'CourseGql' } & CourseFragment;
  };
};

export type ProcessEnrollmentMutationVariables = Exact<{
  input: GqlProcessEnrollmentInput;
}>;

export type ProcessEnrollmentMutation = { __typename?: 'Mutation' } & {
  processEnrollment: { __typename?: 'ProcessEnrollmentGqlResponse' } & Pick<
    ProcessEnrollmentGqlResponse,
    'processed' | 'clientSecret' | 'paymentId'
  >;
};

export type UpdateCourseMutationVariables = Exact<{
  input: UpdateCourseGqlInput;
}>;

export type UpdateCourseMutation = { __typename?: 'Mutation' } & {
  updateCourse: { __typename?: 'UpdateCourseGqlResponse' } & {
    course: { __typename?: 'CourseGql' } & Pick<CourseGql, 'slug' | 'id'>;
  };
};

export type CoursesListQueryVariables = Exact<{
  input?: Maybe<GetCoursesListGqlInput>;
}>;

export type CoursesListQuery = { __typename?: 'Query' } & {
  coursesList: { __typename?: 'GetCoursesListGqlResponse' } & Pick<
    GetCoursesListGqlResponse,
    'totalCount'
  > & {
      cursor: { __typename?: 'Cursor' } & Pick<Cursor, 'after'>;
      data: Array<{ __typename?: 'CourseGql' } & CourseFragment>;
    };
};

export type CourseDetailsQueryVariables = Exact<{
  input: GetCourseGqlInput;
}>;

export type CourseDetailsQuery = { __typename?: 'Query' } & {
  course: { __typename?: 'CourseGql' } & Pick<
    CourseGql,
    | 'id'
    | 'completedByCount'
    | 'additionalMaterials'
    | 'classDurationMinutes'
    | 'isUserEnrolledInActiveSection'
    | 'classDurationString'
    | 'courseDurationWeeks'
    | 'imageUrls'
    | 'supplies'
    | 'price'
    | 'currency'
    | 'canPostReview'
    | 'tzid'
    | 'vimeoVideoId'
  > & {
      instructor: { __typename?: 'GqlInstructor' } & Pick<
        GqlInstructor,
        'legalName' | 'id' | 'reviewsCount' | 'rating'
      > & {
          user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id' | 'avatarUrl'>;
        };
      reviews: Array<
        { __typename?: 'GqlReview' } & Pick<
          GqlReview,
          'content' | 'id' | 'rate' | 'createdAt'
        > & {
            author: { __typename?: 'GqlUser' } & Pick<
              GqlUser,
              'id' | 'avatarUrl' | 'firstName' | 'lastName'
            >;
          }
      >;
      upcomingSections: Array<
        { __typename?: 'CourseSectionGql' } & Pick<
          CourseSectionGql,
          'id' | 'startDate' | 'enrolledCount'
        > & {
            scheduleByDay: Array<
              { __typename?: 'CourseSingleDayScheduleGql' } & Pick<
                CourseSingleDayScheduleGql,
                'id' | 'day' | 'timeHours' | 'timeMinutes'
              >
            >;
          }
      >;
    } & CourseFragment;
};

export type CourseEditDetailsQueryVariables = Exact<{
  input: GetCourseGqlInput;
}>;

export type CourseEditDetailsQuery = { __typename?: 'Query' } & {
  course: { __typename?: 'CourseGql' } & CourseEditDetailsFragmentFragment;
};

export type DocumentQueryVariables = Exact<{
  input: GetDocumentGqlInput;
}>;

export type DocumentQuery = { __typename?: 'Query' } & {
  document: { __typename?: 'DocumentGql' } & Pick<
    DocumentGql,
    'id' | 'slug' | 'text'
  >;
};

export type FaqListQueryVariables = Exact<{ [key: string]: never }>;

export type FaqListQuery = { __typename?: 'Query' } & {
  faqList: Array<
    { __typename?: 'GqlFaq' } & Pick<
      GqlFaq,
      'id' | 'title' | 'category' | 'content' | 'language'
    >
  >;
};

export type FaqQueryVariables = Exact<{
  input: GqlSingleFaqInput;
}>;

export type FaqQuery = { __typename?: 'Query' } & {
  faq: { __typename?: 'GqlFaq' } & Pick<
    GqlFaq,
    'id' | 'title' | 'content' | 'language'
  >;
};

export type FeatureFlagQueryVariables = Exact<{
  input: GqlSingleFeatureFlagInput;
}>;

export type FeatureFlagQuery = { __typename?: 'Query' } & {
  featureFlag?: Maybe<
    { __typename?: 'GqlFeatureFlag' } & Pick<
      GqlFeatureFlag,
      'id' | 'name' | 'isActive'
    >
  >;
};

export type UpdateInstructorMutationVariables = Exact<{
  input: GqlUpdateInstructorInput;
}>;

export type UpdateInstructorMutation = { __typename?: 'Mutation' } & {
  updateInstructor: { __typename?: 'GqlUpdateInstructorResponse' } & {
    user: { __typename?: 'GqlUser' } & {
      instructor?: Maybe<
        { __typename?: 'GqlInstructor' } & InstructorFragmentFragment
      >;
    } & UserFragmentFragment;
  };
};

export type OnboardInstructorPaymentsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type OnboardInstructorPaymentsMutation = { __typename?: 'Mutation' } & {
  onboardInstructorPayments: {
    __typename?: 'GqlOnboardInstructorResponse';
  } & Pick<GqlOnboardInstructorResponse, 'url'>;
};

export type InstructorQueryVariables = Exact<{
  input: GqlGetInstructorInput;
}>;

export type InstructorQuery = { __typename?: 'Query' } & {
  instructor: { __typename?: 'GqlInstructor' } & Pick<
    GqlInstructor,
    | 'id'
    | 'legalName'
    | 'rating'
    | 'reviewsCount'
    | 'publicInformation'
    | 'canPostReview'
  > & {
      courses: Array<
        { __typename?: 'CourseGql' } & Pick<
          CourseGql,
          | 'title'
          | 'id'
          | 'slug'
          | 'type'
          | 'price'
          | 'currency'
          | 'rating'
          | 'reviewsCount'
          | 'coverPhotoUrl'
        > & {
            nextSection?: Maybe<
              { __typename?: 'CourseSectionGql' } & Pick<
                CourseSectionGql,
                'id' | 'startDate'
              >
            >;
          }
      >;
      reviews: Array<
        { __typename?: 'GqlReview' } & Pick<
          GqlReview,
          'id' | 'content' | 'createdAt' | 'rate'
        > & {
            author: { __typename?: 'GqlUser' } & Pick<
              GqlUser,
              'id' | 'avatarUrl' | 'firstName' | 'lastName'
            >;
          }
      >;
      user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id' | 'avatarUrl'>;
    };
};

export type InstructorClassesListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InstructorClassesListQuery = { __typename?: 'Query' } & {
  instructorSchedule: { __typename?: 'InstructorScheduleGqlResponse' } & {
    courses: Array<
      { __typename?: 'CourseGql' } & ScheduleClassFragmentFragment
    >;
  };
};

export type InstructorMyCoursesListQueryVariables = Exact<{
  input: GqlGetInstructorInput;
}>;

export type InstructorMyCoursesListQuery = { __typename?: 'Query' } & {
  instructor: { __typename?: 'GqlInstructor' } & {
    courses: Array<{ __typename?: 'CourseGql' } & CourseCardFragment>;
  };
};

export type InstructorStatsQueryVariables = Exact<{
  input: GqlGetInstructorStatsInput;
}>;

export type InstructorStatsQuery = { __typename?: 'Query' } & {
  instructorStats: { __typename?: 'GqlInstructorStatsResponse' } & Pick<
    GqlInstructorStatsResponse,
    | 'enrolmentsThisMonth'
    | 'enrolmentsTotal'
    | 'thisMonthRatingsCount'
    | 'ratingTotal'
  > & {
      courseStats: Array<
        { __typename?: 'GqlCourseStats' } & Pick<
          GqlCourseStats,
          'enrolments' | 'id' | 'revenue' | 'startDate' | 'title' | 'currency'
        >
      >;
      revenueThisMonth: Array<
        { __typename?: 'GqlCourseInstructorRevenueStats' } & Pick<
          GqlCourseInstructorRevenueStats,
          'currency' | 'amount'
        >
      >;
      revenueTotal: Array<
        { __typename?: 'GqlCourseInstructorRevenueStats' } & Pick<
          GqlCourseInstructorRevenueStats,
          'currency' | 'amount'
        >
      >;
    };
};

export type LinksListQueryVariables = Exact<{ [key: string]: never }>;

export type LinksListQuery = { __typename?: 'Query' } & {
  linksList: Array<
    { __typename?: 'LinkGql' } & Pick<LinkGql, 'id' | 'slug' | 'url'>
  >;
};

export type ScheduleClassFragmentFragment = { __typename?: 'CourseGql' } & Pick<
  CourseGql,
  'title' | 'id' | 'type' | 'slug'
> & {
    sections: Array<
      { __typename?: 'CourseSectionGql' } & Pick<CourseSectionGql, 'id'> & {
          classes: Array<
            { __typename?: 'CourseClassGql' } & Pick<
              CourseClassGql,
              'id' | 'startDate' | 'endDate' | 'zoomLink' | 'zoomMeetingId'
            >
          >;
          scheduleByDay: Array<
            { __typename?: 'CourseSingleDayScheduleGql' } & Pick<
              CourseSingleDayScheduleGql,
              'id' | 'day'
            >
          >;
        }
    >;
  };

export type ClassesListQueryVariables = Exact<{ [key: string]: never }>;

export type ClassesListQuery = { __typename?: 'Query' } & {
  mySchedule: { __typename?: 'MyScheduleGqlResponse' } & {
    courses: Array<
      { __typename?: 'CourseGql' } & ScheduleClassFragmentFragment
    >;
  };
};

export type GetMeetingStartUrlQueryVariables = Exact<{
  input: GetMeetingStartUrlGqlInput;
}>;

export type GetMeetingStartUrlQuery = { __typename?: 'Query' } & {
  getMeetingStartUrl: { __typename?: 'GetMeetingStartUrlGqlResponse' } & Pick<
    GetMeetingStartUrlGqlResponse,
    'startUrl'
  >;
};

export type CreateSubscriptionMutationVariables = Exact<{
  input: GqlCreateSubscriptionInput;
}>;

export type CreateSubscriptionMutation = { __typename?: 'Mutation' } & {
  createSubscription: { __typename?: 'CreateSubscriptionGqlResponse' } & Pick<
    CreateSubscriptionGqlResponse,
    'clientSecret' | 'processed'
  >;
};

export type RetrievePricesQueryVariables = Exact<{
  input: GqlRetrievePricesInput;
}>;

export type RetrievePricesQuery = { __typename?: 'Query' } & {
  retrievePrices: Array<
    { __typename?: 'GqlPrice' } & Pick<
      GqlPrice,
      'id' | 'amount' | 'interval' | 'currency' | 'intervalCount' | 'oneTime'
    >
  >;
};

export type UserFragmentFragment = { __typename?: 'GqlUser' } & Pick<
  GqlUser,
  'avatarUrl' | 'email' | 'firstName' | 'id' | 'lastName'
>;

export type PaymentsInfoFragment = { __typename?: 'GqlUser' } & {
  paymentsInfo?: Maybe<
    { __typename?: 'GqlUserPaymentsInfo' } & Pick<GqlUserPaymentsInfo, 'id'> & {
        paymentMethods: Array<
          { __typename?: 'GqlPaymentMethod' } & Pick<
            GqlPaymentMethod,
            'id' | 'brand' | 'last4'
          >
        >;
      }
  >;
};

export type SubscriptionFragment = { __typename?: 'GqlUser' } & {
  subscription?: Maybe<
    { __typename?: 'GqlSubscription' } & Pick<
      GqlSubscription,
      | 'id'
      | 'createdAt'
      | 'currency'
      | 'startDate'
      | 'amount'
      | 'currentPeriodStart'
      | 'currentPeriodEnd'
      | 'cancelledAt'
      | 'interval'
      | 'intervalCount'
      | 'status'
      | 'cancelAtPeriodEnd'
    >
  >;
};

export type InstructorFragmentFragment = {
  __typename?: 'GqlInstructor';
} & Pick<
  GqlInstructor,
  | 'id'
  | 'approved'
  | 'countryOfLivingCode'
  | 'hasOnlineTeachingExperience'
  | 'hasTeachingExperience'
  | 'hearAboutClassroolFrom'
  | 'legalName'
  | 'levelOfEducation'
  | 'publicInformation'
  | 'socialAccount'
  | 'teachingAvailability'
  | 'teachingPreferencesDescription'
>;

export type PublicUserFragmentFragment = {
  __typename?: 'PublicUserGql';
} & Pick<PublicUserGql, 'avatarUrl' | 'firstName' | 'id' | 'lastName'>;

export type CreateUserMutationVariables = Exact<{
  input: GqlCreateUserInput;
}>;

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  createUser: { __typename?: 'GqlCreateUserResponse' } & Pick<
    GqlCreateUserResponse,
    'token'
  > & { user: { __typename?: 'GqlUser' } & UserFragmentFragment };
};

export type UpdateUserMutationVariables = Exact<{
  input: GqlUpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'GqlUpdateUserResponse' } & {
    user: { __typename?: 'GqlUser' } & UserFragmentFragment;
  };
};

export type RemovePaymentMethodMutationVariables = Exact<{
  input: GqlRemovePaymentMethodInput;
}>;

export type RemovePaymentMethodMutation = { __typename?: 'Mutation' } & {
  removePaymentMethod: { __typename?: 'GqlRemovePaymentMethodResponse' } & {
    user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> &
      PaymentsInfoFragment;
  };
};

export type SignInMutationVariables = Exact<{
  input: GqlSignInInput;
}>;

export type SignInMutation = { __typename?: 'Mutation' } & {
  signIn: { __typename?: 'GqlSignInResponse' } & Pick<
    GqlSignInResponse,
    'token'
  > & {
      user: { __typename?: 'GqlUser' } & {
        instructor?: Maybe<
          { __typename?: 'GqlInstructor' } & InstructorFragmentFragment
        >;
      } & UserFragmentFragment;
    };
};

export type CreateInstructorMutationVariables = Exact<{
  input: GqlCreateInstructorInput;
}>;

export type CreateInstructorMutation = { __typename?: 'Mutation' } & {
  createInstructor: { __typename?: 'GqlCreateInstructorResponse' } & {
    user: { __typename?: 'GqlUser' } & {
      instructor?: Maybe<
        { __typename?: 'GqlInstructor' } & InstructorFragmentFragment
      >;
    } & UserFragmentFragment;
  };
};

export type ForgotUserPasswordMutationVariables = Exact<{
  input: GqlForgotUserPasswordInput;
}>;

export type ForgotUserPasswordMutation = { __typename?: 'Mutation' } & {
  forgotUserPassword: { __typename?: 'GqlForgotUserPasswordResponse' } & Pick<
    GqlForgotUserPasswordResponse,
    'ok'
  >;
};

export type ResetUserPasswordMutationVariables = Exact<{
  input: GqlResetUserPasswordInput;
}>;

export type ResetUserPasswordMutation = { __typename?: 'Mutation' } & {
  resetUserPassword: { __typename?: 'GqlResetUserPasswordResponse' } & Pick<
    GqlResetUserPasswordResponse,
    'token'
  > & { user: { __typename?: 'GqlUser' } & UserFragmentFragment };
};

export type CreateInstructorReviewMutationVariables = Exact<{
  input: CreateInstructorReviewGqlInput;
}>;

export type CreateInstructorReviewMutation = { __typename?: 'Mutation' } & {
  createInstructorReview: { __typename?: 'CreateReviewGqlResponse' } & {
    review: { __typename?: 'GqlReview' } & Pick<
      GqlReview,
      'id' | 'content' | 'rate'
    > & {
        instructor?: Maybe<
          { __typename?: 'GqlInstructor' } & Pick<
            GqlInstructor,
            'id' | 'rating' | 'reviewsCount'
          > & {
              reviews: Array<
                { __typename?: 'GqlReview' } & Pick<
                  GqlReview,
                  'content' | 'id' | 'rate' | 'createdAt'
                > & {
                    author: { __typename?: 'GqlUser' } & Pick<
                      GqlUser,
                      'id' | 'avatarUrl' | 'firstName' | 'lastName'
                    >;
                  }
              >;
            }
        >;
      };
  };
};

export type AddFavoriteCourseMutationVariables = Exact<{
  input: AddFavoriteCourseGqlInput;
}>;

export type AddFavoriteCourseMutation = { __typename?: 'Mutation' } & {
  addFavoriteCourse: { __typename?: 'AddFavoriteCourseGqlResponse' } & {
    user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
        favoriteCourses: Array<
          { __typename?: 'CourseGql' } & Pick<CourseGql, 'id' | 'title'>
        >;
      };
  };
};

export type RemoveFavoriteCourseMutationVariables = Exact<{
  input: RemoveFavoriteCourseGqlInput;
}>;

export type RemoveFavoriteCourseMutation = { __typename?: 'Mutation' } & {
  removeFavoriteCourse: { __typename?: 'RemoveFavoriteCourseGqlResponse' } & {
    user: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
        favoriteCourses: Array<
          { __typename?: 'CourseGql' } & Pick<CourseGql, 'id'>
        >;
      };
  };
};

export type GetVideoUploadUrlMutationVariables = Exact<{
  size: Scalars['Int'];
}>;

export type GetVideoUploadUrlMutation = { __typename?: 'Mutation' } & {
  videoUploadUrl: { __typename?: 'VideoUploadResponse' } & Pick<
    VideoUploadResponse,
    'url' | 'id'
  >;
};

export type CreateCourseRequestMutationVariables = Exact<{
  input: CreateCourseRequestGqlInput;
}>;

export type CreateCourseRequestMutation = { __typename?: 'Mutation' } & {
  createCourseRequest: { __typename?: 'CreateCourseRequestGqlResponse' } & {
    courseRequest: { __typename?: 'CourseRequestGql' } & Pick<
      CourseRequestGql,
      'category' | 'description' | 'id' | 'requesterEmail'
    >;
  };
};

export type CancelSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelSubscriptionMutation = { __typename?: 'Mutation' } & {
  cancelSubscription: { __typename?: 'CancelSubscriptionGqlResponse' } & Pick<
    CancelSubscriptionGqlResponse,
    'success'
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & {
    favoriteCourses: Array<
      { __typename?: 'CourseGql' } & Pick<CourseGql, 'id'>
    >;
    instructor?: Maybe<
      { __typename?: 'GqlInstructor' } & InstructorFragmentFragment
    >;
  } & UserFragmentFragment;
};

export type HasActiveSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HasActiveSubscriptionQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<
    GqlUser,
    'id' | 'hasActiveSubscription'
  >;
};

export type MeCalendarQueryVariables = Exact<{ [key: string]: never }>;

export type MeCalendarQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id' | 'shareCalendarUrl'>;
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & {
    instructor?: Maybe<
      { __typename?: 'GqlInstructor' } & InstructorFragmentFragment
    >;
  } & UserFragmentFragment &
    PaymentsInfoFragment &
    SubscriptionFragment;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<
    GqlUser,
    'email' | 'firstName' | 'id' | 'lastName'
  >;
};

export type HeaderInfoQueryVariables = Exact<{ [key: string]: never }>;

export type HeaderInfoQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<
    GqlUser,
    'avatarUrl' | 'firstName' | 'id'
  > & {
      instructor?: Maybe<
        { __typename?: 'GqlInstructor' } & Pick<
          GqlInstructor,
          'id' | 'legalName'
        >
      >;
    };
};

export type RecentlyViewedCoursesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecentlyViewedCoursesQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
      recentlyViewedCourses: Array<
        { __typename?: 'CourseGql' } & Pick<
          CourseGql,
          | 'id'
          | 'slug'
          | 'title'
          | 'coverPhotoUrl'
          | 'rating'
          | 'reviewsCount'
          | 'courseDurationWeeks'
          | 'type'
          | 'price'
          | 'currency'
        > & {
            instructor: { __typename?: 'GqlInstructor' } & Pick<
              GqlInstructor,
              'id' | 'legalName'
            >;
            nextSection?: Maybe<
              { __typename?: 'CourseSectionGql' } & Pick<
                CourseSectionGql,
                'id' | 'startDate'
              >
            >;
          }
      >;
    };
};

export type FavoriteCoursesIdsQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteCoursesIdsQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
      favoriteCourses: Array<
        { __typename?: 'CourseGql' } & Pick<CourseGql, 'id'>
      >;
    };
};

export type FavoriteCoursesQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteCoursesQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
      favoriteCourses: Array<{ __typename?: 'CourseGql' } & CourseFragment>;
    };
};

export type MyPurchasesQueryVariables = Exact<{ [key: string]: never }>;

export type MyPurchasesQuery = { __typename?: 'Query' } & {
  mySchedule: { __typename?: 'MyScheduleGqlResponse' } & {
    courses: Array<
      { __typename?: 'CourseGql' } & Pick<CourseGql, 'id' | 'slug'> & {
          sections: Array<
            { __typename?: 'CourseSectionGql' } & PurchaseSectionFragment
          >;
        }
    >;
  };
};

export type InstructorWithdrawalsDetailsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InstructorWithdrawalsDetailsQueryQuery = {
  __typename?: 'Query';
} & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
      instructor?: Maybe<
        { __typename?: 'GqlInstructor' } & Pick<
          GqlInstructor,
          'id' | 'approved'
        > & {
            paymentsInfo?: Maybe<
              { __typename?: 'InstructorPaymentsInfo' } & Pick<
                InstructorPaymentsInfo,
                | 'id'
                | 'detailsFilled'
                | 'paymentsEnabled'
                | 'systemName'
                | 'dashboardUrl'
              >
            >;
          }
      >;
    };
};

export type UserPaymentsInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserPaymentsInfoQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'GqlUser' } & Pick<GqlUser, 'id'> & {
      paymentsInfo?: Maybe<
        { __typename?: 'GqlUserPaymentsInfo' } & Pick<
          GqlUserPaymentsInfo,
          'id'
        > & {
            paymentMethods: Array<
              { __typename?: 'GqlPaymentMethod' } & Pick<
                GqlPaymentMethod,
                'id' | 'brand' | 'last4'
              >
            >;
          }
      >;
    };
};

export type PublicUserQueryVariables = Exact<{
  input: PublicUserGqlInput;
}>;

export type PublicUserQuery = { __typename?: 'Query' } & {
  publicUser: { __typename?: 'PublicUserGql' } & PublicUserFragmentFragment;
};

export const CourseCardFragmentDoc = gql`
  fragment courseCard on CourseGql {
    title
    id
    type
    price
    currency
    rating
    slug
    reviewsCount
    coverPhotoUrl
    isOngoing
    canUpdate
    instructor {
      id
      legalName
    }
    maxStudents
    minStudents
    nextSection {
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
  }
`;
export const CourseFragmentDoc = gql`
  fragment course on CourseGql {
    bestSelling
    coverPhotoUrl
    description
    id
    slug
    maxStudents
    maxStudentsAge
    minStudents
    minStudentsAge
    instructor {
      id
      legalName
    }
    popular
    price
    currency
    rating
    reviewsCount
    summary
    title
    type
    nextSection {
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
  }
`;
export const CourseEditDetailsFragmentFragmentDoc = gql`
  fragment courseEditDetailsFragment on CourseGql {
    ...course
    id
    additionalMaterials
    classDurationMinutes
    category
    canUpdate
    classDurationString
    courseDurationWeeks
    imageUrls
    supplies
    price
    currency
    welcomeMessage
    upcomingSections {
      endDate
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
    vimeoVideoId
    instructor {
      legalName
      id
      reviewsCount
      rating
      user {
        id
        avatarUrl
      }
    }
  }
  ${CourseFragmentDoc}
`;
export const PurchaseCourseFragmentDoc = gql`
  fragment purchaseCourse on CourseGql {
    id
    slug
    coverPhotoUrl
    rating
    reviewsCount
    title
    price
    currency
  }
`;
export const PurchaseSectionFragmentDoc = gql`
  fragment purchaseSection on CourseSectionGql {
    id
    startDate
    endDate
    course {
      ...purchaseCourse
    }
  }
  ${PurchaseCourseFragmentDoc}
`;
export const ScheduleClassFragmentFragmentDoc = gql`
  fragment scheduleClassFragment on CourseGql {
    title
    id
    type
    slug
    sections {
      id
      scheduleByDay {
        id
        day
      }
      classes {
        id
        startDate
        endDate
        zoomLink
        zoomMeetingId
      }
    }
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on GqlUser {
    avatarUrl
    email
    firstName
    id
    lastName
  }
`;
export const PaymentsInfoFragmentDoc = gql`
  fragment paymentsInfo on GqlUser {
    paymentsInfo {
      id
      paymentMethods {
        id
        brand
        last4
      }
    }
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment subscription on GqlUser {
    subscription {
      id
      createdAt
      currency
      startDate
      amount
      currentPeriodStart
      currentPeriodEnd
      cancelledAt
      interval
      intervalCount
      status
      cancelAtPeriodEnd
    }
  }
`;
export const InstructorFragmentFragmentDoc = gql`
  fragment instructorFragment on GqlInstructor {
    id
    approved
    countryOfLivingCode
    hasOnlineTeachingExperience
    hasTeachingExperience
    hearAboutClassroolFrom
    legalName
    levelOfEducation
    publicInformation
    socialAccount
    teachingAvailability
    teachingPreferencesDescription
  }
`;
export const PublicUserFragmentFragmentDoc = gql`
  fragment publicUserFragment on PublicUserGql {
    avatarUrl
    firstName
    id
    lastName
  }
`;
export const CreateCourseReviewDocument = gql`
  mutation createCourseReview($input: CreateCourseReviewGqlInput!) {
    createCourseReview(input: $input) {
      review {
        id
        course {
          id
          slug
          rating
          reviews {
            content
            id
            rate
            createdAt
            author {
              id
              avatarUrl
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
export type CreateCourseReviewMutationFn = Apollo.MutationFunction<
  CreateCourseReviewMutation,
  CreateCourseReviewMutationVariables
>;
export type CreateCourseReviewMutationResult = Apollo.MutationResult<CreateCourseReviewMutation>;
export type CreateCourseReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseReviewMutation,
  CreateCourseReviewMutationVariables
>;
export const CreateCourseDocument = gql`
  mutation createCourse($input: CreateCourseGqlInput!) {
    createCourse(input: $input) {
      course {
        ...course
      }
    }
  }
  ${CourseFragmentDoc}
`;
export type CreateCourseMutationFn = Apollo.MutationFunction<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const ProcessEnrollmentDocument = gql`
  mutation processEnrollment($input: GqlProcessEnrollmentInput!) {
    processEnrollment(input: $input) {
      processed
      clientSecret
      paymentId
    }
  }
`;
export type ProcessEnrollmentMutationFn = Apollo.MutationFunction<
  ProcessEnrollmentMutation,
  ProcessEnrollmentMutationVariables
>;
export type ProcessEnrollmentMutationResult = Apollo.MutationResult<ProcessEnrollmentMutation>;
export type ProcessEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  ProcessEnrollmentMutation,
  ProcessEnrollmentMutationVariables
>;
export const UpdateCourseDocument = gql`
  mutation updateCourse($input: UpdateCourseGqlInput!) {
    updateCourse(input: $input) {
      course {
        slug
        id
      }
    }
  }
`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const CoursesListDocument = gql`
  query coursesList($input: GetCoursesListGqlInput) {
    coursesList(input: $input) {
      cursor {
        after
      }
      data {
        ...course
      }
      totalCount
    }
  }
  ${CourseFragmentDoc}
`;
export type CoursesListQueryResult = Apollo.QueryResult<
  CoursesListQuery,
  CoursesListQueryVariables
>;
export const CourseDetailsDocument = gql`
  query courseDetails($input: GetCourseGqlInput!) {
    course(input: $input) {
      ...course
      id
      completedByCount
      additionalMaterials
      classDurationMinutes
      isUserEnrolledInActiveSection
      classDurationString
      courseDurationWeeks
      imageUrls
      supplies
      price
      currency
      canPostReview
      tzid
      upcomingSections {
        id
        startDate
        enrolledCount
        scheduleByDay {
          id
          day
          timeHours
          timeMinutes
        }
      }
      vimeoVideoId
      instructor {
        legalName
        id
        reviewsCount
        rating
        user {
          id
          avatarUrl
        }
      }
      reviews {
        content
        id
        rate
        createdAt
        author {
          id
          avatarUrl
          firstName
          lastName
        }
      }
    }
  }
  ${CourseFragmentDoc}
`;
export type CourseDetailsQueryResult = Apollo.QueryResult<
  CourseDetailsQuery,
  CourseDetailsQueryVariables
>;
export const CourseEditDetailsDocument = gql`
  query courseEditDetails($input: GetCourseGqlInput!) {
    course(input: $input) {
      ...courseEditDetailsFragment
    }
  }
  ${CourseEditDetailsFragmentFragmentDoc}
`;
export type CourseEditDetailsQueryResult = Apollo.QueryResult<
  CourseEditDetailsQuery,
  CourseEditDetailsQueryVariables
>;
export const DocumentDocument = gql`
  query document($input: GetDocumentGqlInput!) {
    document(input: $input) {
      id
      slug
      text
    }
  }
`;
export type DocumentQueryResult = Apollo.QueryResult<
  DocumentQuery,
  DocumentQueryVariables
>;
export const FaqListDocument = gql`
  query faqList {
    faqList {
      id
      title
      category
      content
      language
    }
  }
`;
export type FaqListQueryResult = Apollo.QueryResult<
  FaqListQuery,
  FaqListQueryVariables
>;
export const FaqDocument = gql`
  query faq($input: GqlSingleFaqInput!) {
    faq(input: $input) {
      id
      title
      content
      language
    }
  }
`;
export type FaqQueryResult = Apollo.QueryResult<FaqQuery, FaqQueryVariables>;
export const FeatureFlagDocument = gql`
  query featureFlag($input: GqlSingleFeatureFlagInput!) {
    featureFlag(input: $input) {
      id
      name
      isActive
    }
  }
`;
export type FeatureFlagQueryResult = Apollo.QueryResult<
  FeatureFlagQuery,
  FeatureFlagQueryVariables
>;
export const UpdateInstructorDocument = gql`
  mutation updateInstructor($input: GqlUpdateInstructorInput!) {
    updateInstructor(input: $input) {
      user {
        ...userFragment
        instructor {
          ...instructorFragment
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${InstructorFragmentFragmentDoc}
`;
export type UpdateInstructorMutationFn = Apollo.MutationFunction<
  UpdateInstructorMutation,
  UpdateInstructorMutationVariables
>;
export type UpdateInstructorMutationResult = Apollo.MutationResult<UpdateInstructorMutation>;
export type UpdateInstructorMutationOptions = Apollo.BaseMutationOptions<
  UpdateInstructorMutation,
  UpdateInstructorMutationVariables
>;
export const OnboardInstructorPaymentsDocument = gql`
  mutation onboardInstructorPayments {
    onboardInstructorPayments {
      url
    }
  }
`;
export type OnboardInstructorPaymentsMutationFn = Apollo.MutationFunction<
  OnboardInstructorPaymentsMutation,
  OnboardInstructorPaymentsMutationVariables
>;
export type OnboardInstructorPaymentsMutationResult = Apollo.MutationResult<OnboardInstructorPaymentsMutation>;
export type OnboardInstructorPaymentsMutationOptions = Apollo.BaseMutationOptions<
  OnboardInstructorPaymentsMutation,
  OnboardInstructorPaymentsMutationVariables
>;
export const InstructorDocument = gql`
  query instructor($input: GqlGetInstructorInput!) {
    instructor(input: $input) {
      id
      legalName
      rating
      reviewsCount
      publicInformation
      canPostReview
      user {
        id
        avatarUrl
      }
      courses {
        title
        id
        slug
        type
        price
        currency
        rating
        reviewsCount
        coverPhotoUrl
        nextSection {
          id
          startDate
        }
      }
      reviews {
        id
        content
        createdAt
        rate
        author {
          id
          avatarUrl
          firstName
          lastName
        }
      }
    }
  }
`;
export type InstructorQueryResult = Apollo.QueryResult<
  InstructorQuery,
  InstructorQueryVariables
>;
export const InstructorClassesListDocument = gql`
  query instructorClassesList {
    instructorSchedule {
      courses {
        ...scheduleClassFragment
      }
    }
  }
  ${ScheduleClassFragmentFragmentDoc}
`;
export type InstructorClassesListQueryResult = Apollo.QueryResult<
  InstructorClassesListQuery,
  InstructorClassesListQueryVariables
>;
export const InstructorMyCoursesListDocument = gql`
  query instructorMyCoursesList($input: GqlGetInstructorInput!) {
    instructor(input: $input) {
      courses {
        ...courseCard
      }
    }
  }
  ${CourseCardFragmentDoc}
`;
export type InstructorMyCoursesListQueryResult = Apollo.QueryResult<
  InstructorMyCoursesListQuery,
  InstructorMyCoursesListQueryVariables
>;
export const InstructorStatsDocument = gql`
  query instructorStats($input: GqlGetInstructorStatsInput!) {
    instructorStats(input: $input) {
      courseStats {
        enrolments
        id
        revenue
        startDate
        title
        currency
      }
      enrolmentsThisMonth
      enrolmentsTotal
      thisMonthRatingsCount
      ratingTotal
      revenueThisMonth {
        currency
        amount
      }
      revenueTotal {
        currency
        amount
      }
    }
  }
`;
export type InstructorStatsQueryResult = Apollo.QueryResult<
  InstructorStatsQuery,
  InstructorStatsQueryVariables
>;
export const LinksListDocument = gql`
  query linksList {
    linksList {
      id
      slug
      url
    }
  }
`;
export type LinksListQueryResult = Apollo.QueryResult<
  LinksListQuery,
  LinksListQueryVariables
>;
export const ClassesListDocument = gql`
  query classesList {
    mySchedule {
      courses {
        ...scheduleClassFragment
      }
    }
  }
  ${ScheduleClassFragmentFragmentDoc}
`;
export type ClassesListQueryResult = Apollo.QueryResult<
  ClassesListQuery,
  ClassesListQueryVariables
>;
export const GetMeetingStartUrlDocument = gql`
  query getMeetingStartUrl($input: GetMeetingStartUrlGqlInput!) {
    getMeetingStartUrl(input: $input) {
      startUrl
    }
  }
`;
export type GetMeetingStartUrlQueryResult = Apollo.QueryResult<
  GetMeetingStartUrlQuery,
  GetMeetingStartUrlQueryVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation createSubscription($input: GqlCreateSubscriptionInput!) {
    createSubscription(input: $input) {
      clientSecret
      processed
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const RetrievePricesDocument = gql`
  query retrievePrices($input: GqlRetrievePricesInput!) {
    retrievePrices(input: $input) {
      id
      amount
      interval
      currency
      intervalCount
      oneTime
    }
  }
`;
export type RetrievePricesQueryResult = Apollo.QueryResult<
  RetrievePricesQuery,
  RetrievePricesQueryVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($input: GqlCreateUserInput!) {
    createUser(input: $input) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: GqlUpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RemovePaymentMethodDocument = gql`
  mutation removePaymentMethod($input: GqlRemovePaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      user {
        id
        ...paymentsInfo
      }
    }
  }
  ${PaymentsInfoFragmentDoc}
`;
export type RemovePaymentMethodMutationFn = Apollo.MutationFunction<
  RemovePaymentMethodMutation,
  RemovePaymentMethodMutationVariables
>;
export type RemovePaymentMethodMutationResult = Apollo.MutationResult<RemovePaymentMethodMutation>;
export type RemovePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  RemovePaymentMethodMutation,
  RemovePaymentMethodMutationVariables
>;
export const SignInDocument = gql`
  mutation signIn($input: GqlSignInInput!) {
    signIn(input: $input) {
      token
      user {
        ...userFragment
        instructor {
          ...instructorFragment
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${InstructorFragmentFragmentDoc}
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const CreateInstructorDocument = gql`
  mutation createInstructor($input: GqlCreateInstructorInput!) {
    createInstructor(input: $input) {
      user {
        ...userFragment
        instructor {
          ...instructorFragment
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${InstructorFragmentFragmentDoc}
`;
export type CreateInstructorMutationFn = Apollo.MutationFunction<
  CreateInstructorMutation,
  CreateInstructorMutationVariables
>;
export type CreateInstructorMutationResult = Apollo.MutationResult<CreateInstructorMutation>;
export type CreateInstructorMutationOptions = Apollo.BaseMutationOptions<
  CreateInstructorMutation,
  CreateInstructorMutationVariables
>;
export const ForgotUserPasswordDocument = gql`
  mutation forgotUserPassword($input: GqlForgotUserPasswordInput!) {
    forgotUserPassword(input: $input) {
      ok
    }
  }
`;
export type ForgotUserPasswordMutationFn = Apollo.MutationFunction<
  ForgotUserPasswordMutation,
  ForgotUserPasswordMutationVariables
>;
export type ForgotUserPasswordMutationResult = Apollo.MutationResult<ForgotUserPasswordMutation>;
export type ForgotUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotUserPasswordMutation,
  ForgotUserPasswordMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation resetUserPassword($input: GqlResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      token
      user {
        ...userFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const CreateInstructorReviewDocument = gql`
  mutation createInstructorReview($input: CreateInstructorReviewGqlInput!) {
    createInstructorReview(input: $input) {
      review {
        id
        content
        rate
        instructor {
          id
          rating
          reviewsCount
          reviews {
            content
            id
            rate
            createdAt
            author {
              id
              avatarUrl
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
export type CreateInstructorReviewMutationFn = Apollo.MutationFunction<
  CreateInstructorReviewMutation,
  CreateInstructorReviewMutationVariables
>;
export type CreateInstructorReviewMutationResult = Apollo.MutationResult<CreateInstructorReviewMutation>;
export type CreateInstructorReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateInstructorReviewMutation,
  CreateInstructorReviewMutationVariables
>;
export const AddFavoriteCourseDocument = gql`
  mutation addFavoriteCourse($input: AddFavoriteCourseGqlInput!) {
    addFavoriteCourse(input: $input) {
      user {
        id
        favoriteCourses {
          id
          title
        }
      }
    }
  }
`;
export type AddFavoriteCourseMutationFn = Apollo.MutationFunction<
  AddFavoriteCourseMutation,
  AddFavoriteCourseMutationVariables
>;
export type AddFavoriteCourseMutationResult = Apollo.MutationResult<AddFavoriteCourseMutation>;
export type AddFavoriteCourseMutationOptions = Apollo.BaseMutationOptions<
  AddFavoriteCourseMutation,
  AddFavoriteCourseMutationVariables
>;
export const RemoveFavoriteCourseDocument = gql`
  mutation removeFavoriteCourse($input: RemoveFavoriteCourseGqlInput!) {
    removeFavoriteCourse(input: $input) {
      user {
        id
        favoriteCourses {
          id
        }
      }
    }
  }
`;
export type RemoveFavoriteCourseMutationFn = Apollo.MutationFunction<
  RemoveFavoriteCourseMutation,
  RemoveFavoriteCourseMutationVariables
>;
export type RemoveFavoriteCourseMutationResult = Apollo.MutationResult<RemoveFavoriteCourseMutation>;
export type RemoveFavoriteCourseMutationOptions = Apollo.BaseMutationOptions<
  RemoveFavoriteCourseMutation,
  RemoveFavoriteCourseMutationVariables
>;
export const GetVideoUploadUrlDocument = gql`
  mutation getVideoUploadUrl($size: Int!) {
    videoUploadUrl(size: $size) {
      url
      id
    }
  }
`;
export type GetVideoUploadUrlMutationFn = Apollo.MutationFunction<
  GetVideoUploadUrlMutation,
  GetVideoUploadUrlMutationVariables
>;
export type GetVideoUploadUrlMutationResult = Apollo.MutationResult<GetVideoUploadUrlMutation>;
export type GetVideoUploadUrlMutationOptions = Apollo.BaseMutationOptions<
  GetVideoUploadUrlMutation,
  GetVideoUploadUrlMutationVariables
>;
export const CreateCourseRequestDocument = gql`
  mutation createCourseRequest($input: CreateCourseRequestGqlInput!) {
    createCourseRequest(input: $input) {
      courseRequest {
        category
        description
        id
        requesterEmail
      }
    }
  }
`;
export type CreateCourseRequestMutationFn = Apollo.MutationFunction<
  CreateCourseRequestMutation,
  CreateCourseRequestMutationVariables
>;
export type CreateCourseRequestMutationResult = Apollo.MutationResult<CreateCourseRequestMutation>;
export type CreateCourseRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseRequestMutation,
  CreateCourseRequestMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription {
    cancelSubscription {
      success
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      ...userFragment
      favoriteCourses {
        id
      }
      instructor {
        ...instructorFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${InstructorFragmentFragmentDoc}
`;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const HasActiveSubscriptionDocument = gql`
  query hasActiveSubscription {
    me {
      id
      hasActiveSubscription
    }
  }
`;
export type HasActiveSubscriptionQueryResult = Apollo.QueryResult<
  HasActiveSubscriptionQuery,
  HasActiveSubscriptionQueryVariables
>;
export const MeCalendarDocument = gql`
  query meCalendar {
    me {
      id
      shareCalendarUrl
    }
  }
`;
export type MeCalendarQueryResult = Apollo.QueryResult<
  MeCalendarQuery,
  MeCalendarQueryVariables
>;
export const MyProfileDocument = gql`
  query myProfile {
    me {
      ...userFragment
      ...paymentsInfo
      ...subscription
      instructor {
        ...instructorFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${PaymentsInfoFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${InstructorFragmentFragmentDoc}
`;
export type MyProfileQueryResult = Apollo.QueryResult<
  MyProfileQuery,
  MyProfileQueryVariables
>;
export const UserInfoDocument = gql`
  query userInfo {
    me {
      email
      firstName
      id
      lastName
    }
  }
`;
export type UserInfoQueryResult = Apollo.QueryResult<
  UserInfoQuery,
  UserInfoQueryVariables
>;
export const HeaderInfoDocument = gql`
  query headerInfo {
    me {
      avatarUrl
      firstName
      id
      instructor {
        id
        legalName
      }
    }
  }
`;
export type HeaderInfoQueryResult = Apollo.QueryResult<
  HeaderInfoQuery,
  HeaderInfoQueryVariables
>;
export const RecentlyViewedCoursesDocument = gql`
  query recentlyViewedCourses {
    me {
      id
      recentlyViewedCourses {
        id
        slug
        title
        coverPhotoUrl
        rating
        reviewsCount
        courseDurationWeeks
        type
        price
        currency
        instructor {
          id
          legalName
        }
        nextSection {
          id
          startDate
        }
      }
    }
  }
`;
export type RecentlyViewedCoursesQueryResult = Apollo.QueryResult<
  RecentlyViewedCoursesQuery,
  RecentlyViewedCoursesQueryVariables
>;
export const FavoriteCoursesIdsDocument = gql`
  query favoriteCoursesIds {
    me {
      id
      favoriteCourses {
        id
      }
    }
  }
`;
export type FavoriteCoursesIdsQueryResult = Apollo.QueryResult<
  FavoriteCoursesIdsQuery,
  FavoriteCoursesIdsQueryVariables
>;
export const FavoriteCoursesDocument = gql`
  query favoriteCourses {
    me {
      id
      favoriteCourses {
        ...course
      }
    }
  }
  ${CourseFragmentDoc}
`;
export type FavoriteCoursesQueryResult = Apollo.QueryResult<
  FavoriteCoursesQuery,
  FavoriteCoursesQueryVariables
>;
export const MyPurchasesDocument = gql`
  query myPurchases {
    mySchedule {
      courses {
        id
        slug
        sections {
          ...purchaseSection
        }
      }
    }
  }
  ${PurchaseSectionFragmentDoc}
`;
export type MyPurchasesQueryResult = Apollo.QueryResult<
  MyPurchasesQuery,
  MyPurchasesQueryVariables
>;
export const InstructorWithdrawalsDetailsQueryDocument = gql`
  query instructorWithdrawalsDetailsQuery {
    me {
      id
      instructor {
        id
        approved
        paymentsInfo {
          id
          detailsFilled
          paymentsEnabled
          systemName
          dashboardUrl
        }
      }
    }
  }
`;
export type InstructorWithdrawalsDetailsQueryQueryResult = Apollo.QueryResult<
  InstructorWithdrawalsDetailsQueryQuery,
  InstructorWithdrawalsDetailsQueryQueryVariables
>;
export const UserPaymentsInfoDocument = gql`
  query userPaymentsInfo {
    me {
      id
      paymentsInfo {
        id
        paymentMethods {
          id
          brand
          last4
        }
      }
    }
  }
`;
export type UserPaymentsInfoQueryResult = Apollo.QueryResult<
  UserPaymentsInfoQuery,
  UserPaymentsInfoQueryVariables
>;
export const PublicUserDocument = gql`
  query publicUser($input: PublicUserGqlInput!) {
    publicUser(input: $input) {
      ...publicUserFragment
    }
  }
  ${PublicUserFragmentFragmentDoc}
`;
export type PublicUserQueryResult = Apollo.QueryResult<
  PublicUserQuery,
  PublicUserQueryVariables
>;
