import { Radio } from 'antd';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CourseScheduleTimeGql } from '../../../../../types/generated';
import { Text } from '../../../../typography';
import styles from './style.module.less';

interface IPhase {
  code: CourseScheduleTimeGql;
  name: string;
  ui: string;
}

const phaseImgMap: { [key: string]: ReactElement } = {
  afternoon: (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.999 25.373a.956.956 0 00-.956.955v2.717a.956.956 0 001.911 0v-2.717a.955.955 0 00-.955-.955zM15.999 6.628a.955.955 0 00.955-.956V2.956a.955.955 0 10-1.91 0v2.716c0 .528.427.956.955.956zM8.022 22.627L6.1 24.547A.955.955 0 107.451 25.9l1.922-1.92a.955.955 0 10-1.351-1.352zM23.302 9.652a.953.953 0 00.676-.28l1.921-1.92A.956.956 0 0024.55 6.1l-1.922 1.92a.955.955 0 00.675 1.632zM6.627 16a.956.956 0 00-.955-.955H2.955a.956.956 0 000 1.91h2.717A.956.956 0 006.627 16zM29.045 15.045h-2.718a.955.955 0 100 1.91h2.718a.955.955 0 100-1.91zM8.022 9.372a.953.953 0 001.35 0 .956.956 0 000-1.35L7.453 6.1a.955.955 0 10-1.351 1.352l1.92 1.92zM23.979 22.627a.955.955 0 10-1.352 1.351l1.92 1.92a.953.953 0 001.352.001.955.955 0 000-1.351l-1.92-1.92zM16 8.563c-4.101 0-7.438 3.336-7.438 7.437 0 4.101 3.337 7.437 7.437 7.437 4.101 0 7.438-3.336 7.438-7.437 0-4.1-3.337-7.437-7.438-7.437zm0 12.963A5.532 5.532 0 0110.472 16 5.532 5.532 0 0116 10.474 5.533 5.533 0 0121.526 16a5.532 5.532 0 01-5.527 5.526z"
        fill="currentColor"
      />
    </svg>
  ),
  evening: (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path d="M20.454 5.949a.937.937 0 01-.938-.938V3.123a.937.937 0 111.875 0v1.888c0 .518-.42.938-.937.938zM28.959 9.38l1.635-.944a.937.937 0 11.937 1.624l-1.635.944a.937.937 0 11-.937-1.624zM29.896 19.742l1.635.944a.937.937 0 11-.937 1.624l-1.635-.944a.937.937 0 11.937-1.623zM11.01 11.004l-1.634-.944a.937.937 0 11.937-1.624l1.635.944a.937.937 0 11-.937 1.624zM7.069 16.704a7.047 7.047 0 016.54-4.393 7.51 7.51 0 016.845-4.438c4.135 0 7.5 3.365 7.5 7.5a7.5 7.5 0 01-3.47 6.322c2.222 3.512-.305 8.116-4.464 8.116-14.75-.016-13.943.038-14.2-.042A6.563 6.563 0 010 23.249c0-3.851 3.29-6.84 7.069-6.545zm19.01-1.33a5.631 5.631 0 00-5.625-5.626 5.627 5.627 0 00-4.888 2.847 7.032 7.032 0 015.052 6.676c.931.102 1.81.45 2.566 1.018a5.629 5.629 0 002.895-4.916zM6.175 27.92c.333.027 8.841.011 13.845.016 3.092 0 4.598-3.832 2.298-5.932a3.374 3.374 0 00-2.611-.875.938.938 0 01-1.014-1.064c.435-3.102-1.967-5.88-5.105-5.88a5.165 5.165 0 00-4.976 3.8.938.938 0 01-1.094.673c-2.923-.604-5.643 1.63-5.643 4.59 0 2.423 1.889 4.474 4.3 4.672z" />
      </g>
    </svg>
  ),
  morning: (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.998 13.29c.604 0 1.092-.49 1.092-1.093V9.092a1.092 1.092 0 10-2.184 0v3.105c0 .604.49 1.092 1.092 1.092zM24.345 16.746c.28 0 .559-.107.772-.32l2.196-2.195a1.092 1.092 0 00-1.544-1.545l-2.196 2.195a1.092 1.092 0 00.772 1.865zM31.955 24c0-.603-.489-1.092-1.092-1.092H1.092a1.092 1.092 0 000 2.184h29.771c.603 0 1.092-.488 1.092-1.092z"
        fill="currentColor"
      />
      <path
        d="M30.908 22.908h-3.106a1.092 1.092 0 100 2.184h3.106a1.092 1.092 0 100-2.184zM6.882 16.426a1.089 1.089 0 001.544 0 1.092 1.092 0 000-1.544l-2.195-2.195a1.092 1.092 0 00-1.544 1.544l2.195 2.195zM16 15.501c-4.687 0-8.5 3.813-8.5 8.5 0 1.332 3.813.666 8.5.666 4.686 0 8.499 0 8.499-.667 0-4.686-3.813-8.499-8.5-8.499zm0 8.148c-3.483 0-6.316 1.684-6.316.351A6.322 6.322 0 0116 17.685 6.323 6.323 0 0122.315 24c-.315 1.334-2.833-.351-6.316-.351z"
        fill="currentColor"
      />
    </svg>
  ),
  night: (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.643 18.355a.856.856 0 00-1.043.034c-3.624 2.979-8.995 2.771-12.378-.611C9.844 14.4 9.627 9.03 12.612 5.4a.856.856 0 00-.965-1.344 11.756 11.756 0 00-4.173 2.7c-4.628 4.628-4.637 12.134 0 16.77 4.627 4.627 12.133 4.637 16.77 0a11.758 11.758 0 002.7-4.173.856.856 0 00-.3-.998zm-3.61 3.96c-3.937 3.938-10.362 3.988-14.348 0-3.925-3.923-4-10.349 0-14.348.291-.291.598-.563.917-.815-.866 2.147-1.932 7.495 2.41 11.836 4.336 4.337 9.68 3.28 11.836 2.41-.251.32-.523.626-.815.918z"
        fill="currentColor"
      />
    </svg>
  ),
};

type PhasePickType = {
  onChange: (e: any) => void;
  value: string;
};

export const PhasePicker: FC<PhasePickType> = ({ onChange, value }) => {
  const { t } = useTranslation();

  const phases: IPhase[] = [
    {
      code: CourseScheduleTimeGql.Morning,
      name: t('morning_classes'),
      ui: 'morning',
    },
    {
      code: CourseScheduleTimeGql.Afternoon,
      name: t('afternoon_classes'),
      ui: 'afternoon',
    },
    {
      code: CourseScheduleTimeGql.Evening,
      name: t('evening_classes'),
      ui: 'evening',
    },
  ];
  return (
    <Radio.Group onChange={onChange} className="block mb-24" value={value}>
      {phases.map(({ code, name, ui }) => (
        <Radio.Button
          className={styles['day-phase-button']}
          key={code}
          value={code}
          onClick={() => {
            if (value === code) {
              onChange({ target: { value: '' } });
            }
          }}
        >
          <div className={styles['day-phase-content']}>
            <div className={styles['day-phase-img']}>{phaseImgMap[ui]}</div>
            <Text.XS>{name}</Text.XS>
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
