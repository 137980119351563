export enum CourseScheduleTime {
  Afternoon = '12:00:00',
  Evening = '18:00:00',
  Morning = '07:00:00',
  Night = '23:00:00',
}

export enum Language {
  en = 'en',
  pl = 'pl',
  uk = 'uk',
}

export enum CourseCurrency {
  PLN = 'pln',
  USD = 'usd',
}

export const DEFAULT_CURRENCY = CourseCurrency.USD;
