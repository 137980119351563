import classNames from 'classnames';
import React, { Component, CSSProperties, FC } from 'react';

import styles from './index.module.less';

type TitleType = {
  className?: string;
  id?: string;
  style?: CSSProperties;
};

const xs: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h6
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-xs'],
        className,
      )}
    >
      {children}
    </h6>
  );
};

const sm: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h5
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-sm'],
        className,
      )}
    >
      {children}
    </h5>
  );
};

const md: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h4
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-md'],
        className,
      )}
    >
      {children}
    </h4>
  );
};

const lg: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h3
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-lg'],
        className,
      )}
    >
      {children}
    </h3>
  );
};

const lgx: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h2
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-lg-x'],
        className,
      )}
    >
      {children}
    </h2>
  );
};

const lgx2: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h2
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-lg-2x'],
        className,
      )}
    >
      {children}
    </h2>
  );
};

const lgx3: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h2
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-lg-3x'],
        className,
      )}
    >
      {children}
    </h2>
  );
};

const big: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-big'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

const bigx: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-big-x'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

const big2x: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-big-2x'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

const big3x: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-big-3x'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

const big4x: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-big-4x'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

const huge: FC<TitleType> = ({ id, children, style, className }) => {
  return (
    <h1
      id={id}
      style={style}
      className={classNames(
        styles['cr-title'],
        styles['cr-title-huge'],
        className,
      )}
    >
      {children}
    </h1>
  );
};

export default class Title extends Component<TitleType> {
  static XS = xs;
  static SM = sm;
  static MD = md;
  static LG = lg;
  static LGX = lgx;
  static LGX2 = lgx2;
  static LGX3 = lgx3;
  static Big = big;
  static BigX = bigx;
  static Big2x = big2x;
  static Big3x = big3x;
  static Big4x = big4x;
  static Huge = huge;

  render() {
    return (
      <Title.Big2x style={this.props.style} className={this.props.className}>
        {this.props.children}
      </Title.Big2x>
    );
  }
}
