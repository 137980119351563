import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Row } from 'antd';
import classnames from 'classnames';
import React, { FC, ReactElement } from 'react';

import { Text, Title } from '../../../typography';
import styles from '../style/filter-style.module.less';

export interface IFilter {
  className?: string;
  menu: ReactElement;
  onClear: () => void;
  showClearButton: boolean;
  subtitle: string;
  title: string;
}

export const Filter: FC<IFilter> = ({
  className,
  menu,
  onClear,
  showClearButton,
  subtitle,
  title,
}) => (
  <Dropdown
    arrow
    placement="bottomCenter"
    className={classnames(styles['filter-item'], className)}
    overlay={menu}
    trigger={['click']}
  >
    <Button className="text-left">
      {showClearButton ? (
        <Title.XS className={styles['filter-item-clear']}>
          <a
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          >
            Clear
          </a>
        </Title.XS>
      ) : null}
      <Title.XS>{title}</Title.XS>
      <Row className="text-gray-main" justify="start" wrap={false}>
        <Col className={styles['filter-item-subtitle']}>
          <Text.SM>{subtitle}</Text.SM>
        </Col>
        <Col>
          <Text.SM>
            <CaretDownOutlined />
          </Text.SM>
        </Col>
      </Row>
    </Button>
  </Dropdown>
);
