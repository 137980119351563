import { prop, uniqBy } from 'ramda';

import { ChatAction, ChatState } from './types';
import { sortMessages } from './utils';

export const chatState: ChatState = {
  currentChannel: null,
  fetchingLastMessages: false,
  members: {},
  messages: {},
  meta: {},
  onlineMembers: [],
};

export const chatReducer = (
  state: ChatState = chatState,
  action: ChatAction,
) => {
  switch (action.type) {
    case 'RESET_CHAT':
      return chatState;
    case 'SAVE_MESSAGE':
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.channel]: sortMessages(
            uniqBy(prop('timetoken'), [
              // action.payload,
              // TODO: temporary fix while developing remove next {...} and uncomment prev line
              {
                ...action.payload,
                publisher:
                  // @ts-ignore
                  action.payload.publisher || action.payload.publisherId,
              },
              ...(state.messages[action.payload.channel] || []),
            ]),
          ),
        },
      };
    case 'SET_CHANNEL_MEMBERS':
      return {
        ...state,
        members: {
          ...state.members,
          [action.payload.channel]: action.payload.members,
        },
      };
    case 'SET_CHANNEL_META':
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.id]: action.payload,
        },
      };
    case 'SET_CURRENT_CHANNEL':
      return {
        ...state,
        currentChannel: action.payload.channel,
      };
    case 'SET_ONLINE_MEMBERS':
      return {
        ...state,
        onlineMembers: action.payload.onlineMembers,
      };
    case 'START_FETCHING_LAST_MESSAGES':
      return {
        ...state,
        fetchingLastMessages: true,
      };
    case 'STOP_FETCHING_LAST_MESSAGES':
      return {
        ...state,
        fetchingLastMessages: false,
      };
    default:
      return state;
  }
};
