import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  OptionData,
  OptionGroupData,
  OptionsType,
} from 'rc-select/lib/interface';
import React, { FC } from 'react';

import { categories, ICategory } from '../../enums/categories';

const { Option } = Select;

interface Props {
  defaultValue?: string;
  onChange: (
    value: SelectValue,
    option: OptionsType | OptionData | OptionGroupData,
  ) => void;
  placeholder?: string;
}

export const SelectCategory: FC<Props> = ({
  onChange,
  placeholder = 'Category',
  defaultValue,
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      size="large"
      onChange={onChange}
      placeholder={placeholder}
    >
      {categories.map(({ code, name }: ICategory) => (
        <Option key={code} value={code}>
          {name}
        </Option>
      ))}
    </Select>
  );
};
