import { ServerError, ServerParseError } from '@apollo/client/core';
import { GraphQLError } from 'graphql';
import { path, pathOr } from 'ramda';

import { signOut } from '../../modules/users/services';
import { showMessage } from '../../shared/services/show-message';
import { defaultPath } from '../../shared/utils/common';
import { errorMessages } from '../i18n/errors';

interface HandleErrorsProps {
  graphQLErrors: readonly GraphQLError[] | undefined;
  networkError: Error | ServerError | ServerParseError | undefined;
}

const getErrorMessageFromType = (type: string) => errorMessages[type];

const interpolateTemplateString = (
  template: string,
  params: Record<string, string>,
) =>
  Object.entries(params).reduce(
    (acc, [key, value]) =>
      acc.replace(new RegExp(`\\\${${key}}`, 'g'), String(value)),
    template,
  );

export const handleErrors = ({
  graphQLErrors,
  networkError,
}: HandleErrorsProps) => {
  const graphqlError = pathOr({}, [0], graphQLErrors);
  const errorType = path<string>(['extensions', 'code'], graphqlError);
  const errorMessageFromType = getErrorMessageFromType(errorType as string);

  if (errorMessageFromType) {
    const payload = pathOr({}, ['extensions', 'payload'], graphqlError);
    const errorMessageText = interpolateTemplateString(
      errorMessageFromType,
      payload,
    );
    showMessage({
      text: errorMessageText,
      title: 'Request failed',
      type: 'error',
    });
    return;
  }

  const statusCode = path<number>(['message', 'statusCode'], graphqlError);
  const error = path<string>(['message', 'error'], graphqlError);

  if (statusCode && error) {
    if (statusCode === 401) {
      signOut().then(() => {
        document.location.href = defaultPath;
        showMessage({
          text: `Cannot see this page. Please log in`,
          title: 'Not logged in',
          type: 'error',
        });
      });

      return;
    }
    showMessage({
      text: `${statusCode}. ${error}`,
      title: 'Network error',
      type: 'error',
    });

    return;
  }

  const graphQlErrMsg = path<string>(['message'], graphqlError);
  const networkErrMsg = networkError?.message;

  showMessage({
    text: graphQlErrMsg || networkErrMsg || 'Something went wrong...',
    title: graphQlErrMsg ? 'GraphQL error' : 'Network error',
    type: 'error',
  });
};
