import gql from 'graphql-tag';

import { courseEditDetailsFragment, courseFragment } from './fragments';

export const coursesListQuery = gql`
  query coursesList($input: GetCoursesListGqlInput) {
    coursesList(input: $input) {
      cursor {
        after
      }
      data {
        ...course
      }
      totalCount
    }
  }
  ${courseFragment}
`;

export const courseDetailsQuery = gql`
  query courseDetails($input: GetCourseGqlInput!) {
    course(input: $input) {
      ...course
      id
      completedByCount
      additionalMaterials
      classDurationMinutes
      isUserEnrolledInActiveSection
      classDurationString
      courseDurationWeeks
      imageUrls
      supplies
      price
      currency
      canPostReview
      tzid
      upcomingSections {
        id
        startDate
        enrolledCount
        scheduleByDay {
          id
          day
          timeHours
          timeMinutes
        }
      }
      vimeoVideoId
      instructor {
        legalName
        id
        reviewsCount
        rating
        user {
          id
          avatarUrl
        }
      }
      reviews {
        content
        id
        rate
        createdAt
        author {
          id
          avatarUrl
          firstName
          lastName
        }
      }
    }
  }
  ${courseFragment}
`;

export const courseEditDetailsQuery = gql`
  query courseEditDetails($input: GetCourseGqlInput!) {
    course(input: $input) {
      ...courseEditDetailsFragment
    }
  }
  ${courseEditDetailsFragment}
`;
