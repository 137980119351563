import i18n from '../../infrastructure/i18n';

export const formatPrice = ({
  price,
  currency,
}: {
  currency: string;
  price: number;
}) => {
  const parts = new Intl.NumberFormat(i18n.language, {
    currency,
    currencyDisplay: 'symbol',
    style: 'currency',
  }).formatToParts(price / 100);

  const integer = parts.find((p) => p.type === 'integer')?.value ?? '0';
  const fraction = parts.find((p) => p.type === 'fraction')?.value ?? '00';
  const literal = parts.find((p) => p.type === 'literal')?.value ?? ' ';
  const currencySymbol = parts.find((p) => p.type === 'currency')?.value ?? '';
  const decimalSeparator =
    parts.find((p) => p.type === 'decimal')?.value ?? '.';

  return {
    body: `${currencySymbol}${literal}${integer}${decimalSeparator}`,
    fraction,
  };
};
