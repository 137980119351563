import { Col, Rate, Row } from 'antd';
import React, { FC } from 'react';

import { colors } from '../colors';
import { Text, Title } from '../typography';
type RateProps = {
  className?: string;
  rating: number;
  reviews: number;
};

export const RateRead: FC<RateProps> = ({ rating, reviews, className }) => {
  return (
    <Row className={className} gutter={8} align="middle">
      <Col>
        <Rate
          style={{
            color: colors.yellow,
            display: 'block',
            fontSize: 12,
            lineHeight: 1,
          }}
          allowHalf
          disabled
          defaultValue={0}
          value={rating}
        />
      </Col>
      <Col>
        <Title.XS className="inline">{rating.toFixed(1)}</Title.XS>
      </Col>
      <Col>
        <Text.XS className="text-gray-main">({reviews})</Text.XS>
      </Col>
    </Row>
  );
};
