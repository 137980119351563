import { useLazyQuery } from '@apollo/react-hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { recentlyViewedCoursesQuery } from '../../../../modules/users/queries';
import { isUserAuthenticated } from '../../../../modules/users/selectors';
import { RecentlyViewedCoursesQuery } from '../../../types/generated';
import { CourseCard } from '../../card/course';
import { CarouselCustom } from '../../carousel';
import { Slide } from '../../carousel/slide';
import { Title } from '../../typography';

export const RecentlyViewedCourses = () => {
  const isAuthenticated = useSelector(isUserAuthenticated);

  const [
    getRecentlyViewedCourse,
    { data },
  ] = useLazyQuery<RecentlyViewedCoursesQuery>(recentlyViewedCoursesQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isAuthenticated) {
      getRecentlyViewedCourse();
    }
  }, [getRecentlyViewedCourse, isAuthenticated]);

  const { t } = useTranslation();

  return isAuthenticated ? (
    <>
      <Title.LG className="mb-16">{t('recently_viewed')}</Title.LG>
      <CarouselCustom slidesToShow={3}>
        {(data?.me.recentlyViewedCourses || []).map((course) => (
          <Slide key={course.id}>
            <CourseCard {...course} />
          </Slide>
        ))}
      </CarouselCustom>
    </>
  ) : null;
};
