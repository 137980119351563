export const graphqlEndpoint =
  process.env.RAZZLE_GRAPHQL_ENDPOINT || '/graphql';

export const configs =
  typeof window !== 'undefined'
    ? {
        RAZZLE_CR_PERCENTAGE_FEE: process.env.RAZZLE_CR_PERCENTAGE_FEE
          ? +process.env.RAZZLE_CR_PERCENTAGE_FEE
          : 0,
        // should be referenced by name because of the way webpack builds client
        RAZZLE_PUBNUB_PUBLISH_KEY: process.env.RAZZLE_PUBNUB_PUBLISH_KEY,
        RAZZLE_PUBNUB_SUBSCRIBE_KEY: process.env.RAZZLE_PUBNUB_SUBSCRIBE_KEY,
        RAZZLE_STRIPE_CLIENT_KEY: process.env.RAZZLE_STRIPE_CLIENT_KEY,
        ...window.env,
      }
    : process.env;
