import * as R from 'ramda';

import { ChannelMeta, StoredMessage } from './types';

export const getCurrentChannel = R.pathOr<string | null>(null, [
  'chat',
  'currentChannel',
]);

export const getIsFetchingLastMessages = R.pathOr(false, [
  'chat',
  'fetchingLastMessages',
]);

export const getLastMessage = (channel: string) =>
  R.path<StoredMessage>(['chat', 'messages', channel, 0]);

export const getLastMessages = R.pipe(
  R.pathOr({}, ['chat', 'messages']),
  R.values,
  R.map(R.head),
);

export const getMembers = (channel: string) =>
  R.pathOr<string[]>([], ['chat', 'members', channel]);

export const getMessages = (channel: string) =>
  R.pathOr<StoredMessage[]>([], ['chat', 'messages', channel]);

export const getMeta = (channel: string) =>
  R.pathOr<ChannelMeta>({ id: channel }, ['chat', 'meta', channel]);

export const getOnlineMembers = R.pathOr<string[]>(
  [],
  ['chat', 'onlineMembers'],
);

export const getIsOnline = (member: string) =>
  R.pipe(getOnlineMembers, R.includes(member));
