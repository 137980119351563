import './index.less';

import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import completed from '../../assets/img/completed.svg';

type CompletedByType = {
  className?: string;
};

export const CompletedBy: FC<CompletedByType> = ({ children, className }) => {
  return (
    <div className={classNames('completed-by', className)}>
      <Row className="completed-by-row" align="middle">
        <Col>
          <div className="completed-by-img">
            <img src={completed} alt="completed img" />
          </div>
        </Col>
        <Col>{children}</Col>
      </Row>
    </div>
  );
};
