import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { FC } from 'react';

const { Option } = Select;

export interface ISortItem {
  code: string;
  name: string;
}
interface Props {
  defaultValue: string;
  onChange: (value: string) => void;
  options: ISortItem[];
}

export const SelectSort: FC<Props> = ({ onChange, options, defaultValue }) => {
  return (
    <Select
      style={{ minWidth: '256px' }}
      size="large"
      onChange={(e) => onChange(e.toString())}
      defaultValue={defaultValue}
      suffixIcon={<CaretDownOutlined />}
      className="text-left"
    >
      {options.map(({ code, name }) => (
        <Option key={code} value={code}>
          {name}
        </Option>
      ))}
    </Select>
  );
};
