import { EntityNotFoundError } from 'typeorm';

import {
  AllSectionPlacesTakenError,
  CannotEnrollToOwnCourseError,
  CountryIsNotSupportedForPaymentsError,
  CourseAlreadyStartedError,
  EmailAlreadyExistsError,
  EmailNotValidError,
  FailedToCreatePaymentCustomerError,
  FailedToInitiatePaymentError,
  FailedToInitiatePaymentInProcessingError,
  FileUploadInvalidError,
  InstructorAlreadyApprovedError,
  InstructorDoesNotExistError,
  InstructorNotApproved,
  InstructorNotFoundError,
  InstructorPaymentIsNotSetUpError,
  MeetingUrlNotGeneratedError,
  NotAllowedError,
  NotAllowedToCreateReviewError,
  NotValidCredentials,
  PasswordsDoNotMatchError,
  PaymentAlreadyConfirmedError,
  SectionAlreadyStartedError,
  SectionFailedToStartError,
  SectionStartsTooSoonError,
  UnexpectedError,
  UserAlreadyEnrolledToThisCourseError,
  UserNotParticipantError,
} from '../../../../shared/src/errors';

export const errorMessages = {
  [NotValidCredentials.constructor.name]: 'Credentials not valid',
  [EmailNotValidError.constructor.name]: 'Email is not valid',
  [SectionStartsTooSoonError.constructor.name]:
    'Section that starts on ${startDate} starts too soon. Please set it to be at least ${hours} hours from now',
  [InstructorNotFoundError.constructor.name]: 'Instructor for user not found',
  [InstructorNotApproved.constructor.name]:
    'Instructor for user is not yet approved',
  [InstructorPaymentIsNotSetUpError.constructor.name]:
    'Please set up payment method in Instructor Hub',
  [UserNotParticipantError.constructor.name]:
    'User is not participating in this section',
  [CourseAlreadyStartedError.constructor.name]: 'Course has already started',
  [MeetingUrlNotGeneratedError.constructor.name]:
    'Meeting url is not generated yet',
  [PaymentAlreadyConfirmedError.constructor.name]:
    'Payment with this intent is already confirmed',
  [CountryIsNotSupportedForPaymentsError.constructor.name]:
    'We cannot accept payments in ${countryCode} yet',
  [UserAlreadyEnrolledToThisCourseError.constructor.name]:
    'User has been already enrolled to this course',
  [CannotEnrollToOwnCourseError.constructor.name]:
    'Cannot enroll to own course',
  [AllSectionPlacesTakenError.constructor.name]: 'All places in section taken',
  [SectionFailedToStartError.constructor.name]:
    'Sorry, section failed to start because of low demand',
  [SectionAlreadyStartedError.constructor.name]: 'Section already started',
  [FailedToInitiatePaymentInProcessingError.constructor.name]:
    'Failed to initiate payment, please try again',
  [FailedToInitiatePaymentError.constructor.name]:
    'Failed to initiate payment, please try again',
  [FailedToCreatePaymentCustomerError.constructor.name]:
    'Failed to create payment customer for user, please try again',
  [NotAllowedToCreateReviewError.constructor.name]:
    'Cannot create review for ${reviewCategoryName}',
  [InstructorAlreadyApprovedError.constructor.name]:
    'Instructor already approved',
  [InstructorDoesNotExistError.constructor.name]:
    'Instructor profile is not created yet',
  [EmailAlreadyExistsError.constructor.name]: 'The account already exists',
  [PasswordsDoNotMatchError.constructor.name]: 'Passwords do not match',
  [EntityNotFoundError.constructor.name]: '${entityName} is not found',
  [FileUploadInvalidError.constructor.name]: 'Failed to upload: ${message}',
  [NotAllowedError.constructor.name]: 'Not allowed to access ${resourceName}',
  [UnexpectedError.constructor.name]:
    'An unexpected error occurred, please try again',
};
