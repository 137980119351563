import { Avatar, Col, Row } from 'antd';
import React, { FC } from 'react';

import { RateLink } from '../../rate/link';
import { Title } from '../../typography';

type ReviewInfoType = {
  img: string;
  linkTo?: string;
  rate: number;
  reviewsCount: number;
  title: string;
};

export const ReviewInfo: FC<ReviewInfoType> = ({
  img,
  title,
  rate,
  reviewsCount,
  linkTo = '',
}) => {
  return (
    <Row style={{ maxWidth: '275px' }} justify="end" className="mb-10">
      <Col flex="0 0 86px">
        <Avatar size={74} shape="square" src={img} />
      </Col>
      <Col flex="1">
        <Title.MD className="mb-4">{title}</Title.MD>
        <RateLink linkTo={linkTo} rate={rate} reviews={reviewsCount} />
      </Col>
    </Row>
  );
};
