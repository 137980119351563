import { useQuery } from '@apollo/react-hooks';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { documentQuery } from '../../../modules/document/queries';
import { setUserCookies } from '../../../modules/users/actions';
import { getUserCookiesAgreement } from '../../../modules/users/selectors';
import { DocumentQuery, DocumentQueryVariables } from '../../types/generated';
import {
  getDocumentLanguage,
  getTranslator,
  isClient,
} from '../../utils/common';
import { Text } from '../typography';
import styles from './index.module.less';

export const CookiesAgreement = () => {
  const t = getTranslator();
  const dispatch = useDispatch();
  const isCookiesAgreed = useSelector(getUserCookiesAgreement);
  const lang = getDocumentLanguage();
  const slug = `cookies-agreement-${lang}`;

  const { data } = useQuery<DocumentQuery, DocumentQueryVariables>(
    documentQuery,
    {
      skip: isCookiesAgreed,
      variables: { input: { slug } },
    },
  );

  const handleUserCookiesAgreement = () => {
    dispatch(setUserCookies());
  };

  if (!isClient() || !data || isCookiesAgreed) {
    return null;
  }

  return (
    <div className={styles['cookie-agreement']}>
      <div className={styles['cookie-agreement-bg']}></div>
      <div className="container">
        <Row align="middle" gutter={24}>
          <Col xs={24} lg={20} className="my-12">
            <Text.MD className="block text-white">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(data.document.text),
                }}
              />
            </Text.MD>
          </Col>
          <Col xs={24} lg={4}>
            <Button
              className="px-40"
              type="primary"
              onClick={handleUserCookiesAgreement}
            >
              <Text.MD>{t('cookie_agree')}</Text.MD>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
