import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import styles from './index.module.less';

export const Loader = () => {
  return (
    <div className={styles['cr-loader-wrap']}>
      <Spin
        indicator={<LoadingOutlined className={styles['cr-loader']} spin />}
      />
    </div>
  );
};
