import './index.less';

import { Image } from 'antd';
import classNames from 'classnames';
import React from 'react';

export type ImagePreviewItem = {
  id: string;
  src: string;
};

type ImageListWithPreviewType = {
  className: string;
  img: ImagePreviewItem[];
};

export const ImageListWithPreview = ({
  img,
  className,
}: ImageListWithPreviewType) => {
  return (
    <div className={classNames('image-list', className)}>
      <Image.PreviewGroup>
        {img.map(({ id, src }) => (
          <Image key={id} width={150} height={150} src={src} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};
