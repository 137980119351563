import {
  BookOutlined,
  HistoryOutlined,
  HourglassOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import loadable from '@loadable/component';
import { Avatar, Button, Col, Row, Typography } from 'antd';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { courseDetailsQuery } from '../../../modules/courses/queries';
import { GoBack } from '../../../shared/components/back';
import { FavoriteCourseButton } from '../../../shared/components/card/course/favorite-course-button';
import {
  Collapse,
  CollapseItemType,
} from '../../../shared/components/collapse';
import { CompletedBy } from '../../../shared/components/completed-by';
import { CourseTime } from '../../../shared/components/course-time';
import { RecentlyViewedCourses } from '../../../shared/components/course/recently-viewed';
import { CourseDetailsFeaturedMedia } from '../../../shared/components/featured-media/course-details';
import {
  ImageListWithPreview,
  ImagePreviewItem,
} from '../../../shared/components/image-list-with-preview';
import { Layout } from '../../../shared/components/layout';
import { SimpleLoader } from '../../../shared/components/loader';
import { ReviewModal } from '../../../shared/components/modal/review';
import { ShareModal } from '../../../shared/components/modal/share';
import { RateLink } from '../../../shared/components/rate/link';
import { Reviews } from '../../../shared/components/reviews';
import { ReviewType } from '../../../shared/components/reviews/review';
import { ReviewInfo } from '../../../shared/components/reviews/review-info';
import { Text, Title } from '../../../shared/components/typography';
import {
  CourseDetailsQuery,
  CourseDetailsQueryVariables,
} from '../../../shared/types/generated';

const { Paragraph } = Typography;

const EnrollCourseCard = loadable(() => import('./components/enroll-card'), {
  fallback: <SimpleLoader size="large" />,
  ssr: false,
});

export const CourseDetailsScreen = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data } = useQuery<CourseDetailsQuery, CourseDetailsQueryVariables>(
    courseDetailsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: { slug } },
    },
  );

  const [sharingOpened, setSharingOpened] = useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const { t } = useTranslation();

  if (!data) {
    return (
      <Layout>
        <SimpleLoader size="large" />
      </Layout>
    );
  }

  const { course } = data;
  const courseDescription = course.description;
  const coursePrice = course.price || 0;
  const courseRating = course.rating || 0;
  const reviewsCount = course.reviewsCount || 0;
  const courseSummary = course.summary;
  const courseTitle = course.title || '';
  const courseUrl = window?.location?.href;
  const instructorName = course.instructor.legalName;
  const avatarUrl = course.instructor.user.avatarUrl;
  const instructorId = course.instructor.id;
  const instructorRating = course.instructor.rating;
  const instructorReviewsCount = course.instructor.reviewsCount;
  const reviews = course.reviews;
  const coverPhotoUrl = course.coverPhotoUrl || '';
  const shareCourseTitle = t('share_course');
  const courseType = capitalCase(course.type);
  const vimeoVideoId = course.vimeoVideoId;
  const canPostReview = course.canPostReview;
  const isUserEnrolledInActiveSection = course.isUserEnrolledInActiveSection;

  const collapseData: CollapseItemType[] = [
    ...(course.additionalMaterials
      ? [
          {
            id: 'additionalMaterials',
            text: course.additionalMaterials,
            title: t('additional_materials'),
          },
        ]
      : []),
    ...(course.supplies
      ? [
          {
            id: 'supplies',
            text: course.supplies || '',
            title: t('supplies'),
          },
        ]
      : []),
  ];

  const images: ImagePreviewItem[] = (course.imageUrls || []).map((url) => ({
    id: url,
    src: url,
  }));

  const handleReviewModalOk = () => {
    setIsReviewModalVisible(false);
  };

  const handleReviewModalCancel = () => {
    setIsReviewModalVisible(false);
  };

  const openReviewModal = () => setIsReviewModalVisible(true);

  const processShare = () => {
    const shareData = {
      text: courseTitle,
      title: shareCourseTitle,
      url: courseUrl,
    };

    if (typeof navigator.share === 'function') {
      navigator.share(shareData);
      return;
    }

    setSharingOpened(true);
  };

  const completedByCount = course.completedByCount;

  return (
    <Layout>
      <Helmet>
        <title>{courseTitle}</title>
        <meta
          name="description"
          content={courseDescription.slice(0, courseDescription.search(/\n/))}
        />
      </Helmet>
      <div className="container">
        <GoBack />

        <Row justify="space-between" align="middle">
          <Col className="mb-16">
            {course.bestSelling ? (
              <span className="text-primary">
                <BookOutlined className="mr-8" />
                {t('bestselling')}
              </span>
            ) : null}
          </Col>
          <Col className="mb-16">
            <FavoriteCourseButton courseId={course.id} />
            <Button
              onClick={processShare}
              type="primary"
              size="small"
              icon={<ShareAltOutlined />}
            >
              {t('share')}
            </Button>
          </Col>
        </Row>

        <Title className="mb-8">{courseTitle}</Title>
        <Row className="mb-16">
          <Col className="mr-40 mb-8">
            <RateLink
              linkTo="#reviews"
              rate={courseRating}
              reviews={reviewsCount}
            />
          </Col>
          <Col className="mr-8">
            <Row>
              <Col>
                <Link to={`/instructor/${instructorId}`}>
                  <Avatar
                    className="mr-8"
                    icon={<UserOutlined />}
                    shape="circle"
                    size={24}
                    src={avatarUrl}
                  />
                  <Title.SM className="inline mr-8">{instructorName}</Title.SM>
                </Link>
              </Col>
              <Col>
                <RateLink
                  linkTo={`/instructor/${instructorId}#reviews`}
                  rate={instructorRating}
                  reviews={instructorReviewsCount}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={{ lg: 60, xs: 0 }}>
          <Col xs={24} lg={16}>
            <div className="mb-32">
              <CourseDetailsFeaturedMedia
                vimeoVideoId={vimeoVideoId}
                coverPhotoUrl={coverPhotoUrl}
              />
            </div>

            <Text.MD className="block mb-24 new-line-text">
              {courseSummary}
            </Text.MD>

            <Title.MD className="mb-16">{t('basic_information')}:</Title.MD>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <HourglassOutlined className="mr-12" />
                <Text.SM>
                  {t('duration')} {course.classDurationString} /{' '}
                  {course.courseDurationWeeks} {t('weeks')}
                </Text.SM>
              </Col>
              <Col xs={24} sm={12}>
                <HistoryOutlined className="mr-12" />
                <Text.SM>{courseType}</Text.SM>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <CourseTime
                  data={{ scheduleByDay: course.nextSection?.scheduleByDay }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <UsergroupAddOutlined className="mr-12" />
                <Text.SM>
                  {course.minStudents} - {course.maxStudents}{' '}
                  {t('students_in_class')}
                </Text.SM>
              </Col>
            </Row>

            {completedByCount > 0 && (
              <div className="mb-32">
                <CompletedBy>
                  {t('completed_by')}{' '}
                  <span className="fw-500">
                    {completedByCount}{' '}
                    {t(completedByCount !== 1 ? 'learners' : 'learner')}
                  </span>
                </CompletedBy>
              </div>
            )}

            <Title.MD className="mb-16">{t('course_images')}:</Title.MD>
            <ImageListWithPreview className="mb-32" img={images} />

            <Title.LG className="mb-4">{t('class_description')}</Title.LG>

            <Paragraph
              className="new-line-text"
              ellipsis={{
                expandable: true,
                rows: 6,
                symbol: t('show_more'),
              }}
            >
              {courseDescription}
            </Paragraph>

            <Collapse
              data={collapseData}
              className="mb-40"
              pClassName="new-line-text"
            />
          </Col>

          <Col xs={24} lg={8} className="mb-40">
            <EnrollCourseCard
              courseId={course.id}
              instructorId={course.instructor.id}
              coursePrice={coursePrice}
              courseRating={courseRating}
              courseTitle={courseTitle}
              courseImage={coverPhotoUrl}
              openReviewModal={openReviewModal}
              reviewsCount={reviewsCount}
              courseDurationWeeks={course.courseDurationWeeks}
              classDurationMinutes={course.classDurationMinutes}
              upcomingSections={course.upcomingSections}
              canPostReview={canPostReview}
              isUserEnrolledInActiveSection={isUserEnrolledInActiveSection}
              maxStudents={course.maxStudents}
              currency={course.currency}
              tzid={course.tzid}
            />
          </Col>
        </Row>

        <Row gutter={{ lg: 60, xs: 0 }}>
          <Col xs={24} lg={16}>
            <div className="mb-40">
              <Reviews
                canPostReview={canPostReview}
                data={reviews as ReviewType[]}
                openReviewModal={openReviewModal}
              />
            </div>

            <RecentlyViewedCourses />
          </Col>
        </Row>
      </div>
      <ReviewModal
        type="course"
        subject={
          <ReviewInfo
            img={coverPhotoUrl}
            title={courseTitle}
            rate={courseRating}
            reviewsCount={reviewsCount}
            linkTo={`/course/${course.id}#reviews`}
          />
        }
        onCancel={handleReviewModalCancel}
        onOk={handleReviewModalOk}
        visible={isReviewModalVisible}
      />
      <ShareModal
        onClose={() => setSharingOpened(false)}
        shareLink={courseUrl}
        title={shareCourseTitle}
        visible={sharingOpened}
        coverImage={coverPhotoUrl}
      />
    </Layout>
  );
};
