import gql from 'graphql-tag';

export const courseFragment = gql`
  fragment course on CourseGql {
    bestSelling
    coverPhotoUrl
    description
    id
    slug
    maxStudents
    maxStudentsAge
    minStudents
    minStudentsAge
    instructor {
      id
      legalName
    }
    popular
    price
    currency
    rating
    reviewsCount
    summary
    title
    type
    nextSection {
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
  }
`;

export const courseCardFragment = gql`
  fragment courseCard on CourseGql {
    title
    id
    type
    price
    currency
    rating
    slug
    reviewsCount
    coverPhotoUrl
    isOngoing
    canUpdate
    instructor {
      id
      legalName
    }
    maxStudents
    minStudents
    nextSection {
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
  }
`;

export const courseEditDetailsFragment = gql`
  fragment courseEditDetailsFragment on CourseGql {
    ...course
    id
    additionalMaterials
    classDurationMinutes
    category
    canUpdate
    classDurationString
    courseDurationWeeks
    imageUrls
    supplies
    price
    currency
    welcomeMessage
    upcomingSections {
      endDate
      id
      startDate
      scheduleByDay {
        id
        day
        timeHours
        timeMinutes
      }
    }
    vimeoVideoId
    instructor {
      legalName
      id
      reviewsCount
      rating
      user {
        id
        avatarUrl
      }
    }
  }
  ${courseFragment}
`;

export const purchaseCourseFragment = gql`
  fragment purchaseCourse on CourseGql {
    id
    slug
    coverPhotoUrl
    rating
    reviewsCount
    title
    price
    currency
  }
`;

export const purchaseSectionFragment = gql`
  fragment purchaseSection on CourseSectionGql {
    id
    startDate
    endDate
    course {
      ...purchaseCourse
    }
  }
  ${purchaseCourseFragment}
`;
