import { useTranslation } from 'react-i18next';

import { Language } from '../../../../shared/src/enums';

export const maybeCall = (fn?: any, ...args: any[]) =>
  typeof fn === 'function' ? fn(...args) : fn;

export const inUnits = (num: number, unit: string, suffix = 's') =>
  `${num} ${unit}${num === 1 ? '' : suffix}`;

export const simulateRequest = (props: any) => {
  setTimeout(() => {
    maybeCall(props.onSuccess, 'ok');
  }, 0);
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const convertDollarsToCents = (value: number) => {
  return value * 100;
};

export const convertCentsToDollars = (value: number) => {
  return value / 100;
};

export const FILE_SIZE_IMG = 1 * 1024 * 1024; // 1MB
export const SUPPORTED_FORMATS_IMG = ['image/jpeg', 'image/png'];

export const FILE_SIZE_VIDEO = 50 * 1024 * 1024; // 50MB
export const SUPPORTED_FORMATS_VIDEO = ['video/mp4'];

export const getTzid = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isClient = () =>
  typeof window !== 'undefined' && window.setTimeout;

export const getTranslator = () => {
  const { t } = useTranslation();

  return t;
};

export const defaultPath = '/';

/**
 *
 * Needs to be treated carefully.
 *
 * It is important to define supportedLngs in i18n init.
 */

export const getDocumentLanguage = () => {
  const { i18n } = useTranslation();
  // i18n.language is set to the current detected or set language.
  // If you need the primary used language depending on your configuration (supportedLngs, load)
  // you will prefer using i18next.languages[0].
  const languageSlug = (
    i18n.languages[0] ||
    (i18n.options.fallbackLng as string) ||
    Language.en
  ).substr(0, 2);

  return languageSlug;
};
